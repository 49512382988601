﻿.block-box-grid {
  background-color: color("blue");
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  padding: rem(120px) rem(20px);

  @include bp-down(md) {
    padding: rem(80px) rem(20px);
  }

  @include bp-down(sm) {
    padding: rem(10px);
  }

  @include bp-down(xs) {
    padding: rem(10px) rem(20px);
  }

  &__item {
    background-color: rgba($white, 0.15);
    width: calc(50% - #{rem(40px)});
    margin: 0 rem(20px) rem(40px);
    text-decoration: none;

    @include bp-down(sm) {
      width: calc(50% - #{rem(20px)});
      margin: rem(10px);
    }

    @include bp-down(xs) {
      width: 100%;
      margin: rem(10px) 0;
    }

    @include bp-up(lg) {
      width: calc(33.333% - #{rem(40px)});
    }

    @include bp-up(xl) {
      width: calc(25% - #{rem(40px)});
    }

    &__image {
      width: 100%;
      height: auto;
      display: block;
    }

    &__header--sm {
      font-size: rem(20px);
      line-height: 1.3;
      text-transform: none;
    }

    &__content {
      padding: rem(20px);
      color: $white;

      @include bp-up(xxl) {
        padding: rem(40px);
      }

      h2,
      h3,
      h4 {
        hyphens: auto;
        word-wrap: break-word;
        overflow-wrap: break-word;
        margin: 0 0 rem(10px);
      }

      p {
        margin: 0;
        font-family: $font-family-serif;

        @include bp-up(xl) {
          font-size: rem(18px);
          line-height: rem(26px);
        }
      }
    }
  }

  &--5-col {
    .block-box-grid__item {
      @include bp-up(xxl) {
        margin: 0 rem(10px) rem(20px);
        width: calc(20% - #{rem(20px)});
      }
    }

    .block-box-grid__item__content {
      padding: rem(20px);
    }

    .block-box-grid__item__content p {
      font-size: rem(16px);
      line-height: rem(24px);
    }

    .block-box-grid__item__content h3 {
      font-size: rem(20px);
      line-height: rem(24px);
      margin: 0 0 rem(5px);
    }
  }

  a.block-box-grid__item {
    transition: all 0.18s ease-in-out;

    @include on-hover() {
      opacity: 0.75;
    }
  }
}
