.snus-history {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: rem(112px);
  height: calc(100vh - #{rem(112px)});
  overflow: hidden;

  @include bp-down(md) {
    height: calc(100vh - #{rem(75px)});
    top: rem(75px);
  }

  @include bp-down(sm) {
    overflow: visible;
    height: auto;
    min-height: calc(100vh - #{rem(75px)});
  }

  @include bp-down(xs) {
    top: rem(50px);
    min-height: calc(100vh - #{rem(50px)});
  }

  &.sh-loaded {
    display: block;
  }

  &__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: $z-index-sh-backdrop;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.22s;
    transition-delay: 0.4s;

    &.sh-hidden {
      background-color: rgba(0, 0, 0, 0);
    }

    &.sh-transitionend {
      z-index: auto;
    }
  }

  &__navigation-container {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 20;
    height: 100%;
    width: rem(250px);

    @include bp-down(sm) {
      width: 100%;
      display: none;
      // Fixes z-index overlap
      transform: translate3d(0, 0, 0);

      &.sh-transition-in {
        display: block;
        animation: fade-in 0.22s;
      }

      &.sh-transition-out {
        display: block;
        animation: fade-out 0.5s;
      }
    }
  }

  &__navigation {
    height: 100%;
    position: relative;
    z-index: 1;
    padding-left: rem(40px);

    &__bar {
      display: block;
      position: absolute;
      top: 0;
      left: rem(40px);
      width: 1px;
      height: 100%;
      background-color: $white;
      opacity: 0.1;
      transition: height 300ms ease-out;
      z-index: 1;

      &--active {
        height: 0;
        background-color: color("secondary");
        opacity: 1;
      }
    }

    &__list {
      @include list-unstyled();
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative;
      z-index: 1;
      height: 100%;
      margin-left: rem(20px);

      &__item {
        color: $white;
        font-size: rem(14px);
        line-height: rem(20px);
        text-transform: uppercase;
        cursor: pointer;
        display: block;
        opacity: 0.5;
        margin: -#{rem(5px)} 0 0 0;
        transition: 0.22s ease-in;

        &--current {
          font-weight: $font-weight-bold;
          opacity: 1;
          text-decoration: none !important;
        }

        @include on-hover() {
          text-decoration: underline;
        }
      }
    }

    &__fade {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      transition: opacity 0.22s ease-in;
      background-image: linear-gradient(90deg, rgba(#000, 0.5) 0%, transparent 100%);

      @include bp-down(sm) {
        display: none;
      }
    }

    @include on-hover() {
      .snus-history__navigation__fade {
        opacity: 1;
      }

      .snus-history__navigation__list__item {
        opacity: 1;
      }
    }
  }

  &__item-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;

    @include bp-down(sm) {
      height: auto;
      min-height: 100%;
    }
  }

  &__item {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    @include bp-down(sm) {
      height: auto;
      min-height: 100%;
    }

    &:last-child {
      .next {
        display: none;
      }
    }

    &--current {
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;

      @include bp-down(sm) {
        flex-direction: column;
      }

      animation: fade-in-flex 0.5s cubic-bezier(0.7, 0.01, 0.3, 1) forwards;
    }

    &.sh-fade-out {
      display: flex;
      animation: fade-out-flex 0.5s cubic-bezier(0.7, 0.01, 0.3, 1) forwards;
    }

    &__image-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: transform 0.5s cubic-bezier(0.7, 0.01, 0.3, 1);
    }

    &.sh-transition-in {
      .snus-history__item__content {
        transform: translate3d(100%, 0, 0);

        @include bp-down(sm) {
          transform: translate3d(0, calc(75vh - #{rem(75px)}), 0);
        }

        @include bp-down(xs) {
          transform: translate3d(0, calc(75vh - #{rem(50px)}), 0);
        }
      }

      .snus-history__item__image-wrapper {
        transform: translate3d(-25%, 0, 0);

        @include bp-down(sm) {
          transform: translate3d(0, -25%, 0);
        }
      }
    }

    &__image {
      @include bg-cover;
      width: 100%;
      height: 100%;
    }

    &__content {
      padding: rem(40px);
      display: flex;
      justify-content: center;
      flex-direction: column;
      background: linear-gradient(135deg, color("gray-bg"), color("gray-bg-alt"));
      width: 50%;
      z-index: $z-index-sh-item-content;
      transform: translate3d(200%, 0, 0);
      transition: transform 0.5s cubic-bezier(0.7, 0.01, 0.3, 1);

      @include bp-down(sm) {
        display: block;
        width: 100%;
        transform: translate3d(0, 100vh, 0);
      }

      @include bp-down(xs) {
        padding: rem(20px);
      }

      &.is-overflowing {
        justify-content: flex-start;
        overflow: auto;
        overflow-y: scroll;
      }

      &__title {
        @extend .h1;
        margin-top: 0;
      }

      &__text {
        p {
          font-family: $font-family-serif;
          font-size: rem(18px);
          line-height: rem(26px);

          &:first-child {
            @extend .preamble;
          }
        }
      }

      &__actions {
        display: none;
        position: relative;
        bottom: -rem(20px);
        padding-bottom: rem(20px);
        overflow: hidden;

        @include bp-down(sm) {
          display: block;
        }

        .previous,
        .next {
          @extend .btn;
          @extend .btn--silent;
          width: calc(50% - 4px);
        }

        .previous {
          float: left;
        }

        .next {
          float: right;
        }
      }
    }
  }
}

body.page-snushistorypage {
  .site-footer {
    display: none;
  }

  .usp {
    display: none;
  }

  .site-content {
    background-color: #f1f2f4;
  }

  .hero {
    height: calc(100vh - #{rem(112px)});
    transform: translateY(0);
    transition: transform 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);
    z-index: $z-index-sh-hero;

    &.sh-hidden {
      transform: translateY(-100%);
    }

    @include bp-up(xxxl) {
      height: rem(720px);
      height: calc(100vh - #{rem(112px)});
    }

    @include bp-down(md) {
      height: calc(100vh - #{rem(75px)});
    }

    @include bp-down(sm) {
      min-height: calc(100vh - #{rem(75px)});
      height: auto;
    }

    @include bp-down(xs) {
      min-height: calc(100vh - #{rem(50px)});
    }
  }

  .hero__action--scroll {
    @include bp-up(xxxl) {
      display: block !important;
    }

    @include bp-down(sm) {
      display: none !important;
    }
  }

  .hero__action--buttons {
    display: none;

    @include bp-down(sm) {
      display: block !important;
    }
  }
}
