.news-item {
  position: relative;

  &__box {
    position: absolute;
    top: rem(40px);
    right: rem(40px);
    padding: rem(20px);
    background: $white;
    border: 1px solid color("gray-border");
    box-shadow: $box-shadow-sm;
    width: rem(320px);

    &__header {
      text-transform: uppercase;
      color: color("secondary");
      margin-top: 0;
      line-height: 1.5;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      font-size: $font-size-sm;
      margin-bottom: rem(4px);
    }

    &__label {
      font-weight: $font-weight-medium;
      color: color("blue-meta");
    }

    &__value {
      font-weight: $font-weight-bold;
    }

    button {
      margin-top: rem(12px);

      .icon--arrow-stats {
        font-size: rem(12px);
        margin-left: 4px;
      }
    }

    @include bp-down(lg) {
      position: static;
      width: 100%;
      max-width: rem(560px);
      margin: 0 auto rem(80px);
    }
  }

  &__entry {
    @extend .block-main-content;
    margin: 0 auto;
    padding: rem(80px) rem(20px);

    > :first-child {
      margin-top: 0;
    }

    @include bp-down(xs) {
      padding: rem(40px) rem(20px);
    }
  }
}
