.brand-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: rem(990px);
  margin: 0 auto rem(40px);
  padding: rem(32px) rem(40px);
  background: color("gray-bg");

  &:last-of-type {
    margin-bottom: 0;
  }

  @include bp-down(sm) {
    align-items: flex-start;
  }

  @include bp-down(xs) {
    flex-direction: column; 
    margin-bottom: rem(24px);
  }

  &__content {
    width: calc(60% - #{rem(24px)});
  }

  &__image {
    width: calc(40% - #{rem(24px)});

    @include bp-down(xs) {
      order: -1;
      margin-bottom: rem(24px);
    }
  }

  &__content,
  &__image {
    @include bp-down(xs) {
      width: 100%;
    }
  }

  &__title {
    margin: 0 0 rem(8px);
  }

  &__intro {
    font-family: $font-family-sans-serif;
    font-size: $font-size-lg;
    margin-bottom: rem(32px);

    @include bp-down(xs) {
      margin-bottom: rem(24px);
      font-size: rem(14px);
      line-height: rem(20px);
    }
  }

  &__usps {
    position: relative;
    display: inline-block;
    min-width: 75%;
    padding: rem(16px) rem(24px);
    border: 1px solid $black;

    @include bp-down(xs) {
      width: 100%;
    }
  }

  &__usp-item {
    margin: rem(6px) 0;
    font-weight: $font-weight-medium;
  }

  &__usp-icon {
    @extend .icon;
    @extend .icon--checkmark-sm;
    margin-right: rem(3px);
    position: relative;
    top: rem(1px);
  }

  &__usp-title {
    @extend .byline;
    font-size: rem(12px);
    line-height: rem(18px);
  }

  &:nth-child(odd) {
    .brand-product__image {
      order: -1;
    }
  }
}
