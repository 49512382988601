.usp {
  @include list-unstyled();
  padding: rem(4px) 0;
  background-color: $white;
  text-align: center;
  border-bottom: 1px solid color("gray-border");

  @include bp-up(sm) {
    padding: rem(12px) 0;
  }

  &__item {
    display: inline-block;
    margin: 0 rem(6px);

    span,
    a {
      display: block;
      font-weight: $font-weight-medium;
      color: color("secondary");

      @include bp-up(xxxs) {
        font-size: rem(11px);
        line-height: rem(17px);
      }

      @include bp-up(xxs) {
        font-size: rem(12px);
        line-height: rem(18px);
      }

      @include bp-up(sm) {
        font-size: rem(13px);
        line-height: rem(20px);
      }
    }

    a {
      text-decoration: none;

      &:hover {
        color: darken(color("secondary"), 10%);
      }
    }

    &__icon {
      @extend .icon;
      @extend .icon--checkmark-sm;
      margin-right: rem(3px);
      position: relative;
      top: rem(1px);
    }

    @include bp-down(xs) {
      &:last-child {
        display: none;
      }
    }
  }

  &--alt {
    background-color: color("gray-bg");

    a,
    span {
      color: color("blue-meta");
    }

    a {
      &:hover {
        color: darken(color("blue-meta"), 15%);
      }
    }
  }

  &--light {
    a,
    span {
      color: color("gray-meta");
    }

    a {
      &:hover {
        color: darken(color("gray-meta"), 15%);
      }
    }
  }
}
