@mixin img-scale() {
  image-rendering: auto;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

@mixin bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-origin: fixed;
}
