.block-regular-content {
  @extend .block-main-content;
  max-width: rem(1000px);
  padding: rem(120px) rem(40px);
  margin: 0 auto;

  @include bp-down(sm) {
    padding: rem(80px) rem(40px);
    max-width: 100%;
  }

  @include bp-down(xs) {
    padding: rem(40px) rem(20px);
  }

  .page-brandstandardpage & {
    padding-bottom: 3rem;
  }

  h1,
  .h1 {
    text-transform: uppercase;
    font-size: rem(72px);
    line-height: rem(70px);
    text-align: center;
    margin: 0 0 rem(20px);

    @include bp-down(sm) {
      font-size: rem(52px);
      line-height: rem(50px);
      text-align: left;
    }

    @include bp-down(xs) {
      font-size: rem(42px);
      line-height: rem(44px);
    }

    @include bp-down(xxxs) {
      font-size: rem(36px);
      line-height: rem(36px);
    }

    @include bp-up(lg) {
      margin-left: -#{rem(80px)};
      margin-right: -#{rem(80px)};
    }
  }

  &__column-wrapper {
    display: flex;
    flex-wrap: nowrap;
    margin: rem(40px) -#{rem(20px)};
    width: calc(100% + #{rem(40px)});

    @include bp-down(xs) {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  &__column {
    margin: 0 rem(20px) rem(20px);
    flex: 1;

    @include bp-down(xs) {
      padding: 0;
    }

    img {
      display: block;
      margin: 0 auto;
    }

    &--centered {
      text-align: center;
    }

    &--left {
      text-align: left;
    }

    h2,
    h3 {
      color: color("secondary");
      margin-top: 0;

      @include bp-down(xs) {
        margin-bottom: rem(5px);
      }
    }

    p,
    p:first-of-type {
      font-family: $font-family-serif;
      font-size: rem(18px);
      line-height: rem(26px);
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
