.collapse {
  padding: rem(20px) 0;
  border-bottom: 1px solid #ddd;

  &--border-top {
    border-bottom: 0;
    border-top: 1px solid #ddd;
  }

  &__anchor {
    position: relative;
    cursor: pointer;
    margin: 0;
    color: $gold;
    font-size: rem(23px);
    font-weight: $font-weight-medium;
    line-height: rem(32px);
    text-transform: none;
    letter-spacing: normal;
    padding-right: 30px;

    @include bp-down(xs) {
      font-size: rem(18px);
      line-height: rem(26px);
    }

    &::after {
      font-family: "swm-icons";
      content: "\e930";
      position: absolute;
      top: calc(50% - 13px);
      right: 0;
      transform: rotate(270deg);
      transition: transform 0.22s linear;
    }
  }

  &__content {
    display: none;
    padding-top: rem(10px);
  }

  &--active {
    .collapse__anchor::after {
      transform: rotate(90deg);
    }
  }

  &--sm {
    padding: rem(16px) 0;

    .collapse__anchor {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      color: $link-color;

      &::after {
        font-size: rem(24px);
      }
    }
  }

  &--xs {
    padding: rem(8px) 0;

    .collapse__content {
      font-size: $font-size-xs;
      padding-top: 0;
    }

    .collapse__anchor {
      font-size: $font-size-xs;
      color: $link-color;

      &::after {
        font-size: rem(18px);
        top: calc(50% - #{rem(9px)});
      }
    }
  }
}


.collapse-container {
  max-width: rem(620px);
  margin: 0 auto;
  padding: rem(32px) 0 rem(80px);

  @include bp-down(sm) {
    padding: rem(24px) 0 rem(40px);
  }
}

.block-main-content {
  .collapse-container {
    width: 100%;
  }
}
