.block-regular-content {
  &__column {
    p,
    p:first-of-type {
      font-family: $font-family-sans-serif;
    }
  }
}

.page-brandstandardpage {

  .block-regular-content > p:first-of-type {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
}
