body.modal-open {
  overflow: hidden;
}

body.modal-blur .site-wrapper {
  filter: blur(10px);
}

.modal-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: $z-index-modal-backdrop;

  &--in-front {
    z-index: $z-index-modal-backdrop-front;
  }

  &--darken {
    background: rgba(0, 0, 0, 0.5);
  }

  &--blue {
    background: rgba(4, 45, 62, 0.85);
  }
}

.modal {
  z-index: $z-index-modal;
  position: fixed;
  left: 50%;
  top: 45%;
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%) translateX(-50%);
  box-shadow: 0 rem(20px) rem(40px) rgba(#000, 0.2);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &--in-front {
    z-index: $z-index-modal-front;
  }

  &--flex {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: none;
    box-shadow: none;

    @include bp-down(xs) {
      align-items: flex-start;
    }
  }

  &--age-agreement {
    @include bp-down(xs) {
      position: absolute; // fix jumpy focus behavior in ios
    }
  }

  > img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: rem(700px);
  }

  &__flex-container {
    width: rem(560px);
    box-shadow: 0 rem(20px) rem(40px) rgba(0, 0, 0, 0.2);

    @include bp-down(xs) {
      width: 100%;
    }

    @include bp-up(xxxl) {
      width: rem(620px);
    }
  }

  &__header {
    @extend .byline;
    padding: rem(10px) 0 rem(8px);
    background-color: color("secondary");
    color: $white;
    text-align: center;
  }

  &__close {
    @extend .icon;
    @extend .icon--close;
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    font-size: rem(34px);
    cursor: pointer;
  }

  &__content {
    background-color: $white;
    padding: rem(40px);

    @include bp-down(md) {
      padding-left: rem(20px);
      padding-right: rem(20px);
    }

    @include bp-down(xs) {
      text-align: center;
    }

    h2,
    h3 {
      margin-top: 0;
    }

    p:first-of-type {
      @extend .preamble--sm;
    }

    button {
      white-space: normal;

      @include bp-down(xs) {
        display: block;
        margin: rem(25px) auto !important;
      }
    }
  }
}

.modal-backdrop.image-modal,
.modal.image-modal {
  cursor: zoom-out;
}
