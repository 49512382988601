.brand-header-dropdown {
  display: none;
  position: fixed;
  z-index: $z-index-header-dropdown;
  top: 0;
  width: 10%;
  right: 40px;
  background-color: #F3DECD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow-y: auto;

  > .container {
    position: relative;
  }

  &--is-visible {
    display: block;
    animation: header-dropdown-slide-down 0.3s;

    ~ .header-backdrop {
      display: block;
      animation: fade-in 0.22s;
    }
  }

  &__heading {
    @extend .dropdown-header;
  }

  &__close {
    @extend .icon;
    @extend .icon--close;
    color: lighten(color("gray-meta"), 20%);
    position: absolute;
    top: rem(8px);
    right: rem(5px);
    font-size: rem(30px);
    cursor: pointer;
    z-index: 10;
  }

  &__nav {
    @include list-unstyled();

    li {
      margin: rem(8.5px) 0;
    }

    a {
      display: block;
      font-weight: $font-weight-medium;
      font-size: rem(14px);
      line-height: rem(16px);
      text-decoration: none;
    }
  }

  &__section {
    display: none;

    &--is-visible {
      display: flex;
    }

    &--sites {

      &__region {
        max-width: rem(880px);
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &:after, &:before {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(136, 183, 213, 0);
          border-bottom-color: #88b7d5;
          border-width: 30px;
          margin-left: -30px;
        }

        &:before {
          border-color: rgba(194, 225, 245, 0);
          border-bottom-color: #c2e1f5;
          border-width: 36px;
          margin-left: -36px;
        }
      }

      &__site {
        display: block;
        float: left;
        padding: rem(10px) rem(10px) rem(7px);
        text-decoration: none;
        font-size: 19px;
        font-weight: 400;
        line-height: 28.5px;
        color: black;

        &:not(:last-child) {
          margin-right: rem(10px);
        }
      }
    }

    &--buy {
      background-color: $white;

      &.header-dropdown__section--is-visible {
        display: flex;
        flex-wrap: nowrap;
      }

      &__col {
        padding: rem(20px) rem(40px) rem(40px);

        &:first-child {
          width: rem(220px);
          padding-left: 0;
        }

        &:last-child {
          width: calc(100% - #{rem(220px)});
          padding-right: 0;
        }
      }

      .brand-list__item {
        @include bp-up(lg) {
          width: 20%;
        }

        @include bp-up(xl) {
          width: 16.66666%;
        }

        @include bp-up(xxl) {
          width: 12.5%;
        }
      }
    }

    &--brands {
      background: $white;

      &.header-dropdown__section--is-visible {
        display: flex;
        flex-wrap: nowrap;
      }

      &__col {
        padding: rem(20px) rem(14px) rem(40px);
        flex-basis: rem(220px);

        &:last-child {
          padding-right: 0;
        }

        &:first-child {
          flex-basis: calc(100% - #{rem(180px)} - #{rem(180px)} - #{rem(20px)});
          padding-left: 0;

          .brand-list__item {
            width: 33.333%;

            @include bp-up(lg) {
              width: 25%;
            }

            @include bp-up(xl) {
              width: 20%;
            }
          }
        }
      }

      .brand-list__item {
        width: 100%;
        max-width: rem(220px);
      }
    }

    &--login {
      &__wrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        width: rem(880px);
        margin: 0 auto;

        @include bp-down(sm) {
          flex-direction: column;
          width: 100%;
        }
      }

      &__form,
      &__register {
        flex: 1 50%;
        padding: rem(40px);

        .btn {
          margin: rem(15px) 0;
        }

        .form-group {
          position: relative;
        }

        .meta p:last-child {
          margin-bottom: 0;
        }

        @include bp-down(sm) {
          padding-right: rem(20px);
          padding-left: rem(20px);
        }
      }

      &__form {
        border-right: 1px solid color("gray-border");
      }

      &__register__error,
      &__error {
        padding: rem(12px) rem(10px);
        background: color("red");
        color: $white;
        text-align: center;
        margin-top: rem(15px);
      }

      &__heading {
        font-weight: $font-weight-bold;
        font-size: rem(26px);
        margin-bottom: rem(20px);
      }

      &__preamble {
        color: color("gray-meta");
        margin-bottom: rem(15px);
      }

      ul {
        @extend .list;
        @extend .list--bullets;
        margin-top: rem(15px);

        li {
          color: color("gray-meta");
          font-size: rem(14px);
          font-weight: $font-weight-normal;
        }
      }
    }

    &--debug {
      padding: rem(40px);
    }
  }
}
