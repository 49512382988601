// stylelint-disable selector-list-comma-newline-after

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: $headings-margin-top;
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  color: $headings-color;

  @include bp-down(xs) {
    word-break: break-word;
  }
}

h1, .h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;

  @include bp-down(md) {
    font-size: rem(42px);
    line-height: rem(40px);
  }

  @include bp-down(xs) {
    font-size: rem(36px);
    line-height: rem(38px);
  }

  @include bp-down(xxxs) {
    line-height: rem(36px);
  }
}

h2, .h2 {
  font-size: $h2-font-size;
  line-height: $h2-line-height;

  @include bp-down(xs) {
    font-size: rem(26px);
    line-height: rem(30px);
  }
}

h3, .h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
  text-transform: uppercase;

  @include bp-down(xs) {
    font-size: rem(23px);
    line-height: rem(26px);
  }
}

h4, .h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}

.h2--sm {
  @extend .h3;
  text-transform: none;
  margin: 0 0 rem(16px);

  @include bp-down(xs) {
    font-size: rem(20px);
    line-height: rem(26px);
    margin-bottom: rem(10px);
  }
}
