.product-stamp {
  width: 105px;
  height: 105px;
  position: absolute;
  z-index: 40;
  top: rem(20px);
  right: rem(20px);
  background-size: 105px 105px;
  background-repeat: no-repeat;

  &__tooltip {
    position: absolute;
    right: rem(20px);
    top: rem(70px);
    z-index: 40;
    visibility: hidden;
    display: none;
    background: gray("900");
    border-radius: 4px;
    color: $white;
    text-transform: none;
    font-style: normal;
    font-size: rem(14px);
    line-height: rem(18px);
    width: rem(300px);
    padding: rem(5px) rem(10px);

    @include bp-down(xs) {
      width: rem(170px);
      top: rem(50px);
      right: 0;
    }

    &::before {
      position: absolute;
      top: -#{rem(15px)};
      right: rem(20px);
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      border-bottom-color: gray("900");
      border-width: 8px;
    }
  }

  &--sm {
    width: 90px;
    height: 90px;
    background-size: 90px 90px;

    .product-stamp__tooltip {
      width: rem(230px);
    }
  }

  @include bp-down(sm) {
    width: 75px;
    height: 75px;
    background-size: 75px 75px;
  }

  &:hover {
    .product-stamp__tooltip {
      display: block;
      visibility: visible;
    }
  }
}
