.site-header {
  background: $blue-gradient;
  // padding: 0 rem(40px);
  position: relative;
  z-index: $z-index-header;

  &__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 0 0 auto;
  }

  @include bp-down(md) {
    display: none;
  }

  &__logo {
    width: rem(140px);
    height: rem(24px);
    margin-top: rem(-10px);
    background-image: url("#{$wdk-image-path}/swedishmatch_logo--sm.png?w=142&burstcache=1");
    background-size: rem(140px) rem(24px);

    @include bp-up(xl) {
      width: rem(182px);
      height: rem(30px);
      background-image: url("#{$wdk-image-path}/swedishmatch_logo.png?w=182&burstcache=1");
      background-size: rem(182px) rem(31px);
    }

    @include media-hidpi() {
      background-image: url("#{$wdk-image-path}/swedishmatch_logo@2x.png?w=364&burstcache=1");
    }
  }

  &__logo-sp {
    display: block;
    width: rem(220px);
    height: rem(21px);
  }

  &__nav {
    @include list-unstyled();
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
      position: relative;
      overflow: hidden;

      a {
        display: block;
        color: $white;
        font-weight: $font-weight-medium;
        font-size: rem(15px);
        text-decoration: none;
        padding: rem(22px) rem(10px);
        position: relative;
        z-index: 1;
        transition: color 0.18s ease-in;

        @include bp-up(xl) {
          font-size: rem(18px);
          padding: rem(24px) rem(13px);
        }

        @include bp-up(xxl) {
          padding: rem(24px) rem(18.5px);
        }
      }

      &--has-children {
        a::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          right: auto;
          transform: translateX(-50%) translateY(20px);
          height: 0;
          width: 0;
          border: 8px solid transparent;
          border-bottom-color: color("gray-bg");
          opacity: 0;
          transition: transform 0.22s ease-in, opacity 0.3s ease-in;
        }

        a::after {
          content: "\f107";
          font-family: "Font Awesome 5 Pro";
          font-weight: 400;
          font-size: rem(20px);
          color: color("secondary");
          display: inline-block;
          margin: 0 -#{rem(6.5px)} 0 rem(6.5px);
          pointer-events: none;
          position: relative;
          top: rem(3px);
          line-height: rem(15px);
        }
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        background: radial-gradient(at bottom, rgba($white, 0.15) 10%, rgba($white, 0) 60%, rgba($white, 0) 100%);
        top: 0;
        right: -#{rem(20px)};
        bottom: -#{rem(10px)};
        left: -#{rem(20px)};
        opacity: 0;
        transition: opacity 0.55s ease-in 0s, transform 0.55s ease-in;
        transform: translateY(rem(40px));
      }

      &:hover,
      &.is-hovering {
        a {
          color: $white !important;

          &::before {
            opacity: 1;
            transform: translateY(0px);
          }
        }

        &::after {
          opacity: 1;
          transform: translateY(0);
          transition: all 0.15s ease-in;
        }
      }
    }

    &:hover,
    &.is-hovering {
      a {
        color: lighten(color("blue-third-alt"), 10%);
      }
    }
  }

  &__nav-icons {
    @include list-unstyled();
    flex: 1;
    display: flex;
    justify-content: flex-end;

    &__item {
      text-align: center;
      position: relative;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: rem(16px);

        @include bp-up(lg) {
          margin-right: rem(10px);
        }

        @include bp-up(xxl) {
          margin-right: rem(30px);
        }
      }

      a {
        display: block;
        text-decoration: none;
      }

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &--notifications,
      &--cart {
        &__count {
          position: absolute;
          width: 100%;
          top: rem(10px);
          left: 0;
          font-size: 12px;
          font-family: $font-family-base;
          font-weight: $font-weight-medium;
          font-style: normal;
          color: color("primary");

          @include bp-only(lg) {
            top: rem(8px);
          }

          &--sm,
          &--xs {
            top: rem(12px);

            @include bp-only(lg) {
              top: rem(10px);
            }
          }

          &--sm {
            font-size: rem(10px);
          }

          &--xs {
            font-size: rem(8px);
          }
        }
      }

      &__icon {
        color: $white;
        font-size: rem(20px);
        width: rem(30px);
        height: rem(30px);
        display: block;
        margin-top: rem(5px);
        transition: all 0.2s ease;
        position: relative;
        z-index: 1;
        opacity: 0.5;

        @include bp-up(xl) {
          font-size: rem(24px);
          margin-top: rem(3px);
        }

        &--active {
          color: color("secondary");
          opacity: 1;

          span {
            color: $white;
          }
        }
      }

      &--notifications {
        &__count {
          top: rem(7px);

          @include bp-only(lg) {
            top: rem(5px);
          }
        }
      }

      &__label {
        font-weight: $font-weight-bold;
        font-size: rem(12px);
        color: $white;
        position: absolute;
        right: -#{rem(20px)};
        bottom: -#{rem(12.5px)};
        left: -#{rem(20px)};
        transform: translateY(rem(3.5px));
        opacity: 0;
        transition: all 0.15s ease 0.085s;
      }

      &:hover,
      &.is-active {
        .site-header__nav-icons__item__icon {
          transform: translateY(-#{rem(7.5px)});
          opacity: 1;
        }

        .site-header__nav-icons__item__label {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  &__meta {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(color("blue-third"), 0.2);

    a {
      color: color("blue-third-alt");
      font-size: rem(13px);
      line-height: rem(24px);
      font-weight: $font-weight-medium;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: lighten(color("blue-third-alt"), 10%);
      }
    }

    &__nav {
      @include list-unstyled();

      &__item {
        display: inline-block;
        margin: 0 rem(25px) 0 0;

        @include bp-up(lg) {
          margin-right: rem(15px);
        }

        a {
          display: block;
          padding: rem(5px) 0;
        }

        i {
          margin-right: rem(8px);
        }

        &--lang {
          .flag-icon {
            margin-left: rem(2px);
          }

          a:hover,
          a:active,
          a:focus {
            text-decoration: none !important;

            > span:first-child {
              text-decoration: underline;
            }
          }
        }
      }

      &--right {
        text-align: right;

        li {
          margin: 0 0 0 rem(30px);
        }

        li:first-child a {
          color: $white;
        }
      }
    }
  }

  &__debug-toggle {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: #f7542b;
    color: $white;
    font-weight: $font-weight-bold;
    font-size: rem(14px);
    line-height: rem(14px);
    padding: rem(14px) rem(24px);
    cursor: pointer;
  }

  &--inverted {
    background: $white;
    border-bottom: 1px solid color("gray-border");

    .site-header__logo {
      width: rem(144px);
      height: rem(23px);
      background-image: url("#{$wdk-image-path}/swedishmatch_logo_corporate--sm.png?w=144&burstcache=1");
      background-size: rem(144px) rem(23px);

      @include bp-up(xl) {
        width: rem(182px);
        height: rem(29px);
        background-image: url("#{$wdk-image-path}/swedishmatch_logo_corporate.png?w=182&burstcache=1");
        background-size: rem(182px) rem(29px);
      }

      @include media-hidpi() {
        background-image: url("#{$wdk-image-path}/swedishmatch_logo_corporate@2x.png?w=364&burstcache=1");
      }
    }

    .site-header__nav {
      &:hover,
      &.is-hovering {
        a {
          color: lighten(gray("900"), 33%);
        }
      }
    }

    .site-header__nav__item a {
      color: gray("900");

      &:hover,
      &.is-hovering {
        color: $black !important;
      }
    }

    .site-header__meta a,
    .site-header__meta__nav--right li:first-child a {
      color: gray("700");
    }

    .site-header__meta {
      border-bottom: 1px solid gray("200");
    }
  }

  &--no-actions {
    .site-header__nav {
      margin-right: rem(140px);

      @include bp-up(xl) {
        margin-right: rem(182px);
      }
    }
  }
}

body.has-fixed-header {
  .site-header {
    position: fixed;
    top: 0;
    width: 100%;

    &__meta {
      display: none;
    }
  }
}
