.brand-product-info {
  top: -3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem;

  @include bp-down(md) {
    margin: 0;
    width: 100px;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    background-color: white;
    border: 10px solid rgba(243, 222, 206, 0.4);
    margin-bottom: 1rem;

    @include bp-down(md) {
      width: 100px;
      height: 100px;
    }
  }

  &__text {
    font-weight: 700;
    font-size: 21px;
    line-height: 130%;
    color: #F3DECD;
    max-width: 13rem;
    text-align: center;

    @include bp-down(md) {
      max-width: 100px;
    }
  }
}
