.epi-edit-mode {
  .video-hero {
    height: 20rem !important;

    @include bp-up(sm) {
      height: 40rem !important;
    }
  }
}

.video-hero {


  @include bp-down(md) {
    height: auto;

    .video-hero__container {
      position: relative;
    }
  }

  .video-hero__video {
    width: 100%;

    @include bp-down(md) {
      min-height: auto;
      position: inherit;
      top: auto;
      left: auto;
      transform: none;
    }
  }
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  z-index: 1;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container::before {
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: -1%;
  left: -1%;
  width: 102%;
  height: 102%;
  pointer-events: none;
}
