.runners__grid__item__link {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.runners__grid__item__byline {
  position: relative;
  z-index: 10;
  font-size: rem(14px);
  line-height: rem(22px);
  color: $white;
  opacity: 0.85;
}

.runners__grid__item:nth-child(n+7) {
  height: 10.9375rem;
}
