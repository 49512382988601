.calendar-date {
  color: $white;
  background: color("success");
  text-align: center;
  padding: rem(12px);
  line-height: 1;

  &__day {
    font-size: rem(22px);
    font-weight: $font-weight-bold;
    margin-bottom: rem(4px);
  }

  &__month {
    font-size: $font-size-xs;
    text-transform: uppercase;
  }
}
