.product-attributes {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &__item {
    width: 20%;
    text-align: center;
    text-decoration: none;

    @include bp-down(xs) {
      width: 100%;
      margin-bottom: rem(15px);
      padding-bottom: rem(10px);
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid color("gray-border");

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    &__label {
      color: color("blue-meta");
    }

    &__icon {
      @extend .icon;
      width: rem(50px);
      height: rem(40px);
      display: block;
      position: relative;
      margin: rem(10px) auto;
      font-size: rem(32px);

      @include bp-down(xs) {
        display: none;
      }

      &--logo {
        width: rem(80px);

        img,
        svg {
          max-height: 100%;
        }
      }

      &.icon--snus-strength-1,
      &.icon--snus-strength-2,
      &.icon--snus-strength-3,
      &.icon--snus-strength-4,
      &.icon--snus-strength-5 {
        font-size: rem(18px);
        left: -#{rem(15px)};
        top: rem(7px);
      }

      &.icon--snus-strength-5 {
        left: -#{rem(22px)};
      }

      &.icon--snus-type {
        font-size: rem(30px);
        left: -#{rem(8.5px)};
        top: rem(3px);
      }

      &.icon--snus-format-original {
        font-size: rem(32px);
        left: -#{rem(5px)};
        top: rem(5px);
      }

      &.icon--snus-format-mini {
        font-size: rem(24px);
        left: -#{rem(2px)};
        top: rem(5px);
      }

      &.icon--snus-format-slim {
        font-size: rem(30px);
        left: -#{rem(14px)};
        top: rem(2px);
      }

      &.icon--snus-format-long {
        font-size: rem(22px);
        left: -#{rem(20px)};
        top: rem(5px);
      }

      &.icon--snus-taste {
        left: -#{rem(2px)};
        top: rem(2px);
      }
    }

    &__value {
      @include bp-down(xs) {
        text-align: right;
      }
    }

    @include on-hover() {
      background: radial-gradient($white, rgba($white, 0));
    }
  }

  &--facts {
    .product-attributes__item__value {
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      width: 100%;
    }

    .product-attributes__item__icon::before {
      position: relative;
      top: rem(4px);
    }
  }

  &--levels {
    .product-attributes__item {
      width: 25%;
    }

    .product-attributes__item__icon {
      width: rem(50px);
      height: rem(50px);
    }

    .product-attributes__item__label {
      @extend .h4;
      color: color("secondary");
    }
  }

  &--substances {
    justify-content: flex-start;

    .product-attributes__item {
      width: 25%;
      margin-bottom: rem(15px);

      @include bp-down(xs) {
        margin: 0 0 rem(15px);
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .product-attributes__item__value {
      font-size: rem(24px);
      line-height: rem(24px);
      letter-spacing: -#{rem(1px)};
      display: block;
      margin: rem(10px) auto 0;
      font-weight: $font-weight-bold;
      color: color("secondary");

      @include bp-down(xs) {
        margin: 0;
        font-size: rem(14px);
        line-height: rem(20px);
        letter-spacing: 0;
      }
    }
  }
}
