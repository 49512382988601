.runners {
  padding: rem(80px) 0;

  @include bp-down(sm) {
    padding: rem(40px) 0;
  }

  .page-header {
    padding: 0 0 rem(40px);
  }

  .page-header__title {
    max-width: 100%;
  }

  &__grid {
    margin-left: - rem(20px);
    margin-right: - rem(20px);
    overflow: hidden;

    @include bp-down(sm) {
      margin-left: -2rem;
      margin-right: -2rem;
    }

    &__item {
      position: relative;
      overflow: hidden;
      float: left;
      width: calc(100% / 3 - 2rem);
      height: 17.01rem;
      margin: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $black;
      text-decoration: none;

      @include bp-down(sm) {
        width: calc(50% - #{rem(2px)});
        height: rem(260px);
        margin: rem(1px);
      }

      @include bp-up(md) {
        height: rem(382px);
      }

      @include bp-up(xl) {
        height: rem(530px);
      }

      &:nth-child(1) {
        width: calc(100% / 3 * 2 - 2rem);

        @include bp-down(sm) {
          width: calc(100% - #{rem(2px)});
        }
      }

      &:nth-child(5),
      &:nth-child(6) {
        height: 23rem;

        @include bp-down(sm) {
          width: calc(50% - #{rem(2px)});
          height: rem(260px);
          margin: rem(1px);
        }

        @include bp-up(md) {
          height: rem(175px);
        }

        @include bp-up(xl) {
          height: rem(249px);
        }
      }

      &:nth-child(6) {
        @include bp-down(sm) {
          width: calc(100% - #{rem(2px)});
        }
      }

      &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        transition: transform 1.25s ease-out, opacity 0.22s ease;
      }

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 6rem 2.5rem 2.5rem;
        content: "";
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, transparent 70%);
        z-index: 10;
      }

      &__button {
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        z-index: 20;
        border: none;
        background: transparent;
        margin-bottom: 1.5rem;

        &:hover {
          background: transparent !important;
          color: $white !important;
        }

        @include bp-down(md) {
          margin-bottom: 0;
          white-space: normal;
          max-width: calc(100% - 2rem);
          line-height: rem(24px);
          border: none;
          background: transparent;

          &:hover {
            background: transparent !important;
            color: $white !important;
          }
        }

        @include bp-down(xs) {
          font-size: rem(12px);
          line-height: rem(20px);
          max-width: 100%;
        }
      }

      &__link {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-color: $black;
        text-decoration: none;

        @include bp-down(xs) {
          //align-items: flex-end;
        }
      }

      &__stamp {
        position: absolute;
        z-index: 40;
        top: 1rem;
        right: 1.5rem;
        opacity: 0;
        transform: rotate(-10deg);
        transition: all 0.22s ease;

        @include bp-down(md) {
          top: 0;
          right: 0;
        }

        @include bp-down(sm) {
          opacity: 1;
          transform: scale(0.75);
        }

        .product-stamp {
          @include bp-down(md) {
            width: 85px;
            height: 85px;
            background-size: 85px;
          }
        }
      }

      &:hover {
        .runners__grid__item__image {
          transform: scale3d(1.05, 1.05, 1) rotate(0.02deg);
          opacity: 0.85;
        }

        .runners__grid__item__stamp {
          opacity: 1;
          transform: rotate(0);
        }
      }
    }
  }

  &__footer {
    padding: rem(40px) 0 0;
    text-align: center;
    max-width: rem(890px);
    margin: 0 auto;

    &__title {
      @extend .h1;
      text-transform: none;
      margin-top: 0;

      @include bp-down(xs) {
        font-size: 2.6rem;
        line-height: 3rem;
      }
    }

    &__text {
      @extend .preamble;
      margin-bottom: 0;
    }
  }
}
