.notifications {
  display: none;
  z-index: $z-index-header-dropdown;
  position: fixed;
  top: 0;
  right: 0;
  margin-top: rem(10px);
  width: rem(400px);
  max-height: calc(100vh - 100px);
  overflow-y: auto;

  @include bp-up(xxxl) {
    right: rem(25px);
  }

  @include bp-down(md) {
    display: block;
    max-height: none;
    height: 100%;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);
  }

  @include bp-down(xs) {
    background: color("gray-bg");
    padding: rem(16px);
    margin-top: 0;
    width: 100%;
  }

  &.active {
    display: block;
    animation: slide-left 0.6s;
    transform: translateX(0);
    margin-right: rem(10px);

    ~ .header-backdrop {
      display: block;
      animation: fade-in 0.22s;
    }

    @include bp-down(xs) {
      margin-right: 0;
    }
  }

  &__item {
    position: relative;
    font-size: $font-size-sm;
    margin-bottom: rem(10px);
    border-radius: 4px;
    border-top: 2px solid color("danger");
    background: $white;
    box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.12);

    + .notifications__info {
      display: none;
    }

    &--read {
      border-top: 0;
    }
  }

  &__content {
    padding: rem(16px);

    [href] {
      text-decoration: underline;
      font-weight: $font-weight-bold;
      color: color("primary");
    }
  }

  &__header {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    margin-bottom: rem(2px);
  }

  &__close {
    @extend .icon;
    @extend .icon--close;
    position: absolute;
    top: rem(13px);
    right: rem(10px);
    font-size: rem(24px);
    cursor: pointer;
  }

  &__image {
    @include bg-cover();
    height: rem(200px);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    ~ .notifications__close {
      color: $white;
    }
  }

  &__info {
    text-align: center;
    font-weight: $font-weight-medium;
    padding: rem(20px) 0;
    background: $white;
    border-radius: 4px;
    border-top: 2px solid color("success");
  }
}
