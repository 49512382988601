// stylelint-disable

@font-face {
    font-family: 'FFDINWebPro';
    font-weight: 700;
    src: url('#{$wdk-font-path}/din/321727_0_0.eot');
    src: url('#{$wdk-font-path}/din/321727_0_0.eot?#iefix') format('embedded-opentype'),url('#{$wdk-font-path}/din/321727_0_0.woff2') format('woff2'),url('#{$wdk-font-path}/din/321727_0_0.woff') format('woff'),url('#{$wdk-font-path}/din/321727_0_0.ttf') format('truetype'),url('#{$wdk-font-path}/din/321727_0_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'FFDINWebPro';
    font-weight: 300;
    src: url('#{$wdk-font-path}/din/321727_1_0.eot');
    src: url('#{$wdk-font-path}/din/321727_1_0.eot?#iefix') format('embedded-opentype'),url('#{$wdk-font-path}/din/321727_1_0.woff2') format('woff2'),url('#{$wdk-font-path}/din/321727_1_0.woff') format('woff'),url('#{$wdk-font-path}/din/321727_1_0.ttf') format('truetype'),url('#{$wdk-font-path}/din/321727_1_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'FFDINWebPro';
    font-weight: 500;
    src: url('#{$wdk-font-path}/din/321727_2_0.eot');
    src: url('#{$wdk-font-path}/din/321727_2_0.eot?#iefix') format('embedded-opentype'),url('#{$wdk-font-path}/din/321727_2_0.woff2') format('woff2'),url('#{$wdk-font-path}/din/321727_2_0.woff') format('woff'),url('#{$wdk-font-path}/din/321727_2_0.ttf') format('truetype'),url('#{$wdk-font-path}/din/321727_2_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'FFDINWebPro';
    font-weight: 400;
    src: url('#{$wdk-font-path}/din/321727_3_0.eot');
    src: url('#{$wdk-font-path}/din/321727_3_0.eot?#iefix') format('embedded-opentype'),url('#{$wdk-font-path}/din/321727_3_0.woff2') format('woff2'),url('#{$wdk-font-path}/din/321727_3_0.woff') format('woff'),url('#{$wdk-font-path}/din/321727_3_0.ttf') format('truetype'),url('#{$wdk-font-path}/din/321727_3_0.svg#wf') format('svg');
}
