.brand-product-list-page {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  &__filter {
    padding: 2rem 0;
    margin: 0 auto;

    &-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      overflow: hidden;

      @include bp-down(md) {
        display: flex;
        width: 367px;
        height: 65px;
        align-items: center;
        overflow-x: scroll;
      }

      &-active {
        background: #1F5681;
        color: white !important;
      }

      li {
        float: left;

        a {
          display: inline-block;
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          align-items: center;
          padding: 12px 40px;
          border-radius: 48px;
          margin-right: 20px;
          color: #1F5681;
          border: 1px solid rgba(31, 86, 129, 0.2);

          &:hover {
            background: rgba(213, 100, 60, 0.2);
            border: none;
          }

          &:active {
            background: #1F5681;
            color: white;
          }
        }
      }

      &-tordenskjold {
        li {
          a {
            color: #009EE1 !important;
            border: 1px solid rgba(0, 158, 225, 0.2) !important;
          }
        }
      }
    }
  }

  &__products {
    width: 1440px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 5rem;
    margin: 0 auto;

    @include bp-down(md) {
      padding: 0;
      width: 100%;
      align-items: flex-start;
    }

    a {

      @include bp-down(md) {
        width: 50%;
      }
    }
  }

  &__product {
    background-color: #F3DECD;
    width: 394px;
    height: 394px;
    padding: 2rem;
    margin: 1rem;
    text-align: center;
    display: flex;
    align-items: center;

    @include bp-down(md) {
      padding: 0;
      width: unset;
      height: 275px;
    }

    img {
      max-height: 350px;
      width: auto;
      margin: 0 auto;
    }
  }

  &__text {
    display: none;
    text-align: center;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    color: #1B1B1B;

    h3 {
      font-weight: 700;
      font-size: 23px;
      line-height: 110%;
      text-transform: uppercase;
      color: #1F5681;
      margin-top: 1rem;
      margin-bottom: 0;
    }

    @include bp-down(md) {
      display: block;
    }
  }

  &__text-hover {
    opacity: 0;
    position: absolute;
    width: 394px;
    height: 394px;
    top: 15px;
    left: 15px;
    text-align: center;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    color: #FFFFFF;
    background-color: rgba(0,0,0,0.4);
    z-index: 1;
    padding: 1rem;

    @include bp-down(md) {
      display: none;
    }

    &:hover {
      opacity: 1;
    }

    h3 {
      font-weight: 700;
      font-size: 50px;
      line-height: 25px;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-top: 30%;
      line-height: 1;
    }
  }
}
