﻿.block-colored-boxes {
  @include make-container();
  display: flex;
  justify-content: space-between;
  padding-top: rem(120px);
  padding-bottom: rem(120px);

  @include bp-down(sm) {
    padding-top: rem(80px);
    padding-bottom: rem(80px);
  }

  @include bp-down(xs) {
    display: block;
    padding: rem(40px) rem(20px);
  }

  &__item {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    width: calc(33% - #{rem(16px)});

    @include bp-down(xs) {
      display: block;
      width: 100%;
      margin-bottom: rem(20px);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__content,
    &__image-wrapper {
      flex: 1 100%;

      @include bp-down(md) {
        height: rem(160px);
      }

      @include bp-up(lg) {
        height: rem(180px);
      }

      @include bp-up(xl) {
        height: rem(240px);
      }
    }

    &__content {
      padding: 0 rem(20px);
      color: $white;
      text-align: center;
      order: 1;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;

      &__title {
        margin: 0 0 rem(7.5px);

        @include bp-down(md) {
          font-size: rem(20px);
          line-height: rem(26px);
          margin-bottom: rem(5px);
        }
      }

      &__text {
        margin: 0;
        font-family: $font-family-serif;
        font-size: rem(18px);
        line-height: rem(26px);

        @include bp-down(md) {
          font-size: rem(16px);
          line-height: rem(22px);
        }
      }
    }


    &__image-wrapper {
      position: relative;
      overflow: hidden;
      order: 2;
    }

    &__image {
      transition: transform 1.25s ease-out;
      background-size: cover;
      background-position: center center;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.5;
        transition: opacity 0.33s ease;
      }
    }

    &:nth-child(1) {
      background-color: color("blue-third");

      .block-colored-boxes__item__image::before {
        background-color: color("blue-third");
      }
    }

    &:nth-child(2) {
      background-color: color("green");

      .block-colored-boxes__item__image::before {
        background-color: color("green");
      }

      .block-colored-boxes__item__content {
        order: 2;
      }

      .block-colored-boxes__item__image-wrapper {
        order: 1;
      }
    }

    &:nth-child(3) {
      background-color: color("secondary");

      .block-colored-boxes__item__image::before {
        background-color: color("secondary");
      }
    }

    @include on-hover() {
      .block-colored-boxes__item__image {
        transform: scale3d(1.05, 1.05, 1);

        &::before {
          opacity: 0;
        }
      }
    }
  }
}
