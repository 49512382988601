.brand-list {
  @include list-unstyled();
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  &--centered {
    justify-content: center;
  }

  &__item {
    width: rem(150px);
    background: color("gray-bg");
    border: 1px solid color("gray-border");
    margin: 0 -1px -1px 0;

    a {
      height: 100%;
      text-decoration: none;
      display: flex;
      align-content: space-around;
      justify-content: center;
      align-items: center;
    }

    img,
    svg {
      width: 100%;
      max-width: rem(140px);
      height: rem(90px);
      display: block;
      margin: 0 auto rem(25px);
      padding: rem(25px);
    }

    span {
      position: absolute;
      right: 0;
      bottom: rem(12px);
      left: 0;
      display: block;
      font-weight: $font-weight-medium;
      font-size: rem(14px);
      line-height: rem(16px);
      text-align: center;
      overflow: hidden;
    }

    &:hover {
      background-color: white;
    }
  }
}
