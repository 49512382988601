﻿.block-text-image {
  padding: rem(120px) rem(40px);
  @include clearfix();

  @include bp-down(sm) {
    padding: rem(80px) rem(40px);
  }

  @include bp-down(xs) {
    padding: rem(40px) rem(20px);
  }

  &__item {
    margin: 0 auto rem(80px);
    @include clearfix();

    @include bp-up(lg) {
      //width: rem(920px);
    }

    @include bp-down(sm) {
      margin: 0 auto rem(40px);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__image {
      box-shadow: 0 rem(20px) rem(40px) rgba(gray("900"), 0.2);
      float: left;
      margin: 0 rem(40px) rem(20px) 0;

      @include bp-up(md) {
        width: 50%;
        margin: 0;
      }

      @include bp-down(sm) {
        width: 35%;
        margin: 0 rem(20px) rem(8.5px) 0;
      }
    }

    &__text {
      @include bp-up(md) {
        float: left;
        width: 50%;
        padding: 0 0 0 rem(56px);
      }

      &__byline {
        @extend .byline;
        margin-bottom: rem(8px);
        color: color("blue-meta");

        @include bp-down(xs) {
          font-size: rem(14px);
          line-height: rem(20px);
        }
      }

      &__title {
        @extend .h2;
        margin: 0 0 rem(20px);

        @include bp-down(sm) {
          font-size: rem(26px);
          line-height: rem(30px);
          margin-bottom: rem(10px);
        }
      }

      &__content {
        @extend .preamble;
        margin-bottom: 0;

        @include bp-down(sm) {
          font-size: rem(16px);
          line-height: rem(22px);
        }
      }

      &__button {
        @extend .btn;
        @extend .btn--primary;
        @extend .btn--lg;
        margin-top: rem(20px);
      }
    }

    @include bp-up(md) {
      &:nth-child(odd) {
        .block-text-image__item__image {
          float: right;
          width: 45%;
        }

        .block-text-image__item__text {
          width: 55%;
          float: right;
          padding: 0 rem(56px) 0 0;
        }
      }
    }
  }

  &--slim {
    padding: rem(80px) 0;

    @include bp-down(sm) {
      padding: rem(60px) 0;
    }

    @include bp-down(xs) {
      padding: rem(40px) 0;
    }

    .block-text-image__item__image {
      box-shadow: none;
      flex: 0 1 40%;

      &:nth-child(odd) {
        float: left;
      }

      @include bp-up(md) {
        width: 45%;
        flex-basis: 45%;

        &:nth-child(odd) {
          width: 45%;
        }
      }
    }

    .block-text-image__item__text {
      padding: 0 0 0 rem(32px);

      @include bp-up(sm) {
        padding: 0 0 0 rem(56px);
      }

      @include bp-down(xs) {
        padding: 0;
      }
    }

    .block-text-image__item__text__title {
      text-transform: uppercase;
      margin-bottom: rem(8px);
    }

    .block-text-image__item__text__content {
      font-family: $font-family-serif;
      font-size: rem(18px);
      line-height: rem(26px);

      @include bp-down(xs) {
        font-size: rem(16px);
        line-height: rem(24px);
      }
    }

    .block-text-image__item {
      @include bp-up(sm) {
        display: flex;
        align-items: center;

        &:nth-child(odd) {
          .block-text-image__item__image {
            float: left;
          }
          .block-text-image__item__text {
            float: right;
            padding: 0 0 0 rem(32px);

            @include bp-up(sm) {
              padding: 0 0 0 rem(56px);
            }

            @include bp-down(xs) {
              padding: 0;
            }
          }
        }
      }

      @include bp-down(xs) {
        .block-text-image__item__image {
          float: none;
          width: 100%;
        }
        .block-text-image__item__text {
          padding: 0 rem(20px);
        }
      }
    }
  }
}
