figure {
  margin: 0 0 rem(40px);

  @include bp-down(xs) {
    margin: 0 0 rem(20px);
  }

  img {
    display: block;
    margin: 0 auto;
    height: auto;
  }

  figcaption {
    font-size: rem(14px);
    line-height: rem(20px);
    color: color("blue-meta");
    margin: rem(5px) 0;

    @include bp-down(xs) {
      font-size: rem(11px);
      line-height: rem(16px);
    }
  }
}
