.content-promo {
  margin: rem(220px) 0 rem(40px);
  background: color("primary");
  color: $white;

  &--green-forest {
    background: color("green-forest");
  }

  &--blue-gray {
    background: color("blue-gray");
  }

  @include bp-down(sm) {
    margin: rem(80px) 0 0;
  }

  &__container {
    max-width: rem(990px);
    margin: 0 auto;
    transform: translateY(rem(-120px));

    @include bp-down(sm) {
      transform: translateY(rem(-60px));
    }
  }

  &__content {
    display: flex;
    position: relative;

    @include bp-down(sm) {
      display: block;
      padding: 0 rem(20px) rem(72px);
    }
  }

  &__col:first-child {
    flex: 1 0 45%;
  }

  &__col:last-child {
    font-family: $font-family-serif;
    font-size: rem(18px);
    line-height: rem(26px);
  }

  &__image {
    margin-bottom: rem(40px);

    @include bp-down(sm) {
      margin-bottom: rem(24px);
    }

    figcaption {
      margin-top: rem(12px);
      color: color("blue-meta-alt");
      @include bp-down(sm) {
        padding: 0 rem(20px);
      }
    }
  }

  &__title {
    @extend .h1;
    margin: 0 0 rem(24px);
    padding-right: rem(32px);
    text-transform: none;

    @include bp-down(sm) {
      margin-bottom: rem(8px);
      padding: 0;
    }
  }

  &__btn {
    @include bp-down(sm) {
      position: absolute;
      bottom: 0;
    }
  }
}
