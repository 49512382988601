﻿@keyframes blockStoryUp {
  0% {
    transform: translateY(75px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.block-story {
  padding: rem(80px) 0;

  @include bp-up(xxl) {
    max-width: map-get($container-max-widths, xxl);
    margin: 0 auto;
  }

  @include bp-down(sm) {
    padding: rem(40px) 0;
  }

  @include bp-down(xs) {
    padding: 0;
  }

  &__item {
    position: relative;
    padding: rem(40px);
    margin-bottom: rem(60px);
    display: flex;
    align-items: center;


    @include bp-only(md) {
      align-items: flex-start;
    }


    @include bp-down(sm) {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      margin-bottom: rem(40px);
    }

    @include bp-down(xs) {
      padding: 0;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;

      @include bp-down(xs) {
        padding-bottom: rem(20px);
      }
    }
  }

  &__content {
    width: 50%;
    opacity: 0;
    position: relative;

    @include bp-down(sm) {
      width: 100%;
      align-items: flex-start;
      padding: rem(40px) rem(40px) 0;
    }

    @include bp-down(xs) {
      padding: rem(20px) rem(40px) 0;
    }
  }

  &__image-lg {
    width: calc(50% - #{rem(60px)});
    margin-left: auto;
    opacity: 0;

    @include bp-down(sm) {
      order: -1;
      width: 100%;
    }

    img {
      @include img-scale();
      display: block;
    }
  }

  &__image-sm {
    width: calc(100% - #{rem(60px)});
    margin-top: rem(60px);
    opacity: 0;

    @include bp-down(sm) {
      width: 66%;
      margin: rem(50px) auto 0;
    }

    @include bp-down(xs) {
      width: 100%;
      margin: rem(40px) auto 0;
    }
  }

  &__text {
    width: calc(100% - #{rem(100px)});
    font-family: $font-family-serif;
    font-size: rem(18px);
    line-height: rem(26px);
    margin: 0 auto;


    @include bp-down(sm) {
      width: 100%;
    }

    @include bp-down(xs) {
      font-size: rem(14px);
      line-height: rem(20px);
    }

    img {
      display: block;
      margin: rem(30px) auto 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &__nbr {
    color: color("secondary");
    font-weight: $font-weight-bold;
    font-size: rem(42px);
    width: rem(50px);
    position: absolute;
    top: rem(14px);
    left: 0;

    @include bp-down(sm) {
      position: absolute;
      left: 0;
      font-size: rem(32px);
      width: auto;
      top: rem(50px);
    }

    @include bp-down(xs) {
      top: rem(25px);
      left: 0;
      width: rem(40px);
      text-align: center;
      font-size: rem(26px);
    }
  }

  &__caption {
    display: block;
    margin-top: rem(10px);
    font-size: rem(14px);
    line-height: rem(20px);
    color: color("blue-meta");

    @include bp-down(xs) {
      font-size: rem(12px);
      line-height: rem(18px);
      margin: rem(10px);
    }
  }

  &__item--alt {
    .block-story__image-lg {
      margin-right: auto;
      margin-left: 0;

      @include bp-up(md) {
        order: -1;
        z-index: 10;
      }
    }

    .block-story__image-sm {
      display: none;

      @include bp-up(md) {
        left: rem(80px);
        right: rem(40px);
        bottom: rem(50px);
        position: absolute;
        margin: 0;
        width: calc(100% - #{rem(80px)} - #{rem(40px)});
      }
    }

    .block-story__content {
      @include bp-up(md) {
        position: relative;
        z-index: 10;
        width: 50%;
      }

      @include bp-only(md) {
        padding-right: rem(40px);
      }
    }
  }

  &__item--inviewport {
    .block-story__image-lg {
      animation-name: blockStoryUp;
      animation-duration: 0.85s;
      animation-timing-function: cubic-bezier(0, 0.92, 0.6, 1);
      animation-fill-mode: forwards;
    }

    .block-story__image-sm {
      animation-delay: 0.4s;
      animation-name: blockStoryUp;
      animation-duration: 0.85s;
      animation-timing-function: cubic-bezier(0, 0.92, 0.6, 1);
      animation-fill-mode: forwards;
    }

    .block-story__content {
      animation-delay: 0.2s;
      animation-name: blockStoryUp;
      animation-duration: 0.85s;
      animation-timing-function: cubic-bezier(0, 0.92, 0.6, 1);
      animation-fill-mode: forwards;
    }
  }

  &--edit-mode {

    .block-story__image-lg,
    .block-story__image-sm,
    .block-story__content {
      animation: none;
      opacity: 1;
    }
  }
}
