.brand-product-list-block {

  &__header {
    text-align: center;
    text-transform: uppercase;
    color: #1F5681;

    h2 {
      font-weight: 700;
      font-size: 61px;
      line-height: 110%;
      margin: 0 0 2rem 0;

      @include bp-down(md) {
        font-size: 48px;
      }
    }
  }

  &__products {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 5rem;

    @include bp-down(md) {
      display: none;
      padding: 0;
    }

    &--mobile {
      display: none;

      @include bp-down(md) {
        display: block;
        margin: 0 2rem;
      }

      .swiper-pagination-bullet {
        background-color: #F3DECD !important;
      }

      .brand-image-slider__item {
        background-color: #F3DECD;
        height: unset;
        display: flex;
        align-items: center;
      }

      .brand-image-slider__holder {
        width: 100%;
      }
    }
  }

  &__product {
    background-color: #F3DECD;
    width: 394px;
    height: 394px;
    padding: 2rem;
    margin: 1rem;
    text-align: center;

    @include bp-down(md) {
      padding: 0;
      margin: 0;
    }

    img {
      max-height: 350px;
      width: auto;
    }
  }

  &__button {
    padding: 2rem 0 3rem 0;
    text-align: center;

    &-btn {
      display: inline-block;
      font-size: 21px;
      line-height: 120%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      align-items: center;
      padding: 12px 40px;
      border-radius: 48px;
      margin-right: 40px;
      color: #ffffff !important;
      background: #d5643c;

      @include bp-down(md) {
        margin-right: 0;
      }
    }
  }

  &__text-hover {
    opacity: 0;
    position: absolute;
    width: 394px;
    height: 394px;
    top: 15px;
    left: 15px;
    text-align: center;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    color: #FFFFFF;
    background-color: rgba(0,0,0,0.4);
    z-index: 1;

    &:hover {
      opacity: 1;
    }

    h3 {
      font-weight: 700;
      font-size: 50px;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-top: 40%;
      line-height: 1;
    }
  }
}
