// stylelint-disable

@font-face {
  font-family: 'swm-icons';
  src:  url('#{$wdk-font-path}/swm-icons/swm-icons.eot?sgwt26hj');
  src:  url('#{$wdk-font-path}/swm-icons/swm-icons.eot?sgwt26hj#iefix') format('embedded-opentype'),
    url('#{$wdk-font-path}/swm-icons/swm-icons.woff2?sgwt26hj') format('woff2'),
    url('#{$wdk-font-path}/swm-icons/swm-icons.ttf?sgwt26hj') format('truetype'),
    url('#{$wdk-font-path}/swm-icons/swm-icons.woff?sgwt26hj') format('woff'),
    url('#{$wdk-font-path}/swm-icons/swm-icons.svg?sgwt26hj#swm-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
