.shopping-cart {
  display: none;
  z-index: $z-index-header-dropdown;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: rem(400px);
  padding-bottom: rem(20px);
  overflow-y: auto;
  background: #fff;
  box-shadow: -#{rem(30px)} 0 rem(60px) rgba(0, 0, 0, 0.06);

  @include bp-up(lg) {
    transition: none;
  }

  @include bp-down(md) {
    display: block;
    transform: translateX(100%);
    transition: transform 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);

    &:not(.active) {
      box-shadow: none;
    }
  }

  @include bp-down(xs) {
    width: 100%;
  }

  &.active {
    display: block;
    animation: slide-left 0.6s;

    @include bp-down(md) {
      transform: translateX(0);
    }

    ~ .header-backdrop {
      display: block;
      animation: fade-in 0.22s;
    }
  }

  &__top {
    background-color: color("gray-bg");
    border-bottom: 1px solid color("gray-border");
    padding: rem(20px);

    &__summary {
      font-size: rem(18px);
      color: color("gray-meta");
    }

    @include bp-down(md) {
      overflow: hidden;
    }

    @include bp-down(xxxs) {
      padding: rem(15px) rem(20px);
    }
  }

  &__close {
    @extend .icon;
    @extend .icon--close;
    float: right;
    cursor: pointer;
    color: lighten(color("gray-meta"), 20%);
    font-size: rem(30px);

    @include bp-down(md) {
      display: none;
    }
  }

  &__empty-cart {
    @extend .btn;
    @extend .btn--link;
    position: relative;
    margin-left: 17px;

    &::before {
      opacity: 0.8;
      content: '\f1f8';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: rem(14px);
      position: absolute;
      left: -17px;
      top: -2px;
    }

    @include bp-down(md) {
      float: right;
      margin: 8px 0 0 0;
    }
  }

  &__header {
    text-transform: none;
    margin-top: 0;
    margin-bottom: 0;

    @include bp-down(xxxs) {
      font-size: rem(16px);
    }

    @include bp-down(md) {
      float: left;
    }
  }

  &__count {
    background-color: color("secondary");
    color: $white;
    border-radius: 100%;
    font-size: rem(14px);
    width: rem(20px);
    height: rem(20px);
    display: inline-block;
    line-height: rem(22px);
    text-align: center;
    position: relative;
    top: -3px;

    &--lg {
      width: 1.8rem;
      height: 1.8rem;
      line-height: rem(31px);
    }

    @include bp-down(md) {
      top: -12px;
      left: -4px;
      font-size: rem(12px);
      width: rem(18px);
      height: rem(18px);
      line-height: rem(20px);
    }
  }

  &__summary {
    padding: rem(20px);

    &__row {
      overflow: hidden;

      &--vat {
        font-size: rem(14px);
        line-height: rem(20px);
        color: color("gray-meta");
      }

      &--total {
        font-weight: $font-weight-bold;

        .shopping-cart__summary__value {
          color: color("red");
        }
      }
    }

    &__key {
      float: left;
    }

    &__value {
      float: right;
    }
  }

  &__action {
    @extend .btn;
    @extend .btn--primary;
    @extend .btn--lg;
    @extend .btn--block;
    margin: 0 rem(20px) rem(20px);
    width: calc(100% - #{rem(40px)});
  }

  &__secondary-actions {
    @extend .meta;
    position: relative;
    text-align: center;

    &__save,
    &__close {
      @extend .btn;
      @extend .btn--link;
      position: relative;
      top: rem(1px);
    }

    &__save {
      margin-right: rem(6px);
    }

    &__close {
      margin-left: rem(6px);
    }

    &__copy {
      background-color: color("gray-bg");
      display: none;
      padding: rem(20px);
      margin: rem(20px) 0;
      border-top: 1px solid color("gray-border");
      border-bottom: 1px solid color("gray-border");

      &.active {
        display: block;
      }

      input {
        width: 100%;
      }

      &__message {
        @extend .meta;
        margin-top: rem(10px);
      }
    }
  }
}
