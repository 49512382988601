.content-promo {
  &__col:last-child {
    font-family: $font-family-sans-serif;
  }

  @include bp-down(xs) {
    margin: 5rem -50px 0;
    padding: 0 50px;
  }
}
