.brand-promotion {
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 900px;
  width: 100%;

  @include bp-down(md) {
    max-height: 500px;
    overflow: hidden;
  }

  &__content {
    position: absolute;
    padding: 5rem 5rem 5rem 7rem;
    z-index: 1;

    @include bp-down(md) {
      padding: 5rem 2rem 4rem 2rem;
    }

    &-header {
      text-transform: uppercase;

      h1 {
        font-size: 80px;
        font-weight: 700;
        line-height: 110%;

        @include bp-down(md) {
          font-size: 48px;
        }
      }
    }

    &-text {
      font-size: 19px;
      font-weight: 400;
      max-width: 50rem;

      p {
        line-height: 135%;
      }
    }
  }

  &-video {
    width: 100%;
    height: auto;

    @include bp-down(md) {
      width: unset;
      transform: translate(-40%, 15%);
    }
  }

  &-btn {
    display: inline-block;
    font-size: 21px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    align-items: center;
    padding: 12px 40px;
    border-radius: 48px;
    margin-right: 40px;

    @include bp-down(md) {
      margin-right: 0;
    }
  }
}
