.product {
  display: flex;

  &:not(.product--quickbuy) {
    @include make-container($padding: false);
  }

  &__images {
    padding: 0 rem(40px) rem(40px);

    @include bp-down(sm) {
      width: 100%;
    }

    @include bp-down(xs) {
      padding: 0;
    }

    @include bp-down(md) {
      width: 100%;
      padding-bottom: 0;
    }

    @include bp-up(lg) {
      flex: 0 0 40%;
      width: 40%;
      max-width: 40%;
    }

    @include bp-up(xl) {
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
    }

    &__slider {
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      z-index: 1;
      height: 100%;

      &__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        box-sizing: content-box;
        transform: translate3d(0px, 0, 0);
      }

      &__item {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        padding: rem(20px) rem(80px);

        @include bp-up(xl) {
          padding: rem(20px) rem(80px);
        }

        @include bp-up(lg) {
          padding: rem(20px) rem(60px);
        }

        &__image {
          display: block;
          margin: 0 auto;
          max-height: 480px;

          @include bp-down(md) {
            margin-right: auto;
          }
        }
      }

      &__next,
      &__prev {
        position: absolute;
        top: 50%;
        width: rem(40px);
        height: rem(40px);
        font-size: rem(40px);
        color: $link-color;
        margin-top: -22px;
        z-index: 10;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        @include bp-down(xs) {
          width: rem(50px);
          height: rem(50px);
          font-size: rem(30px);
          text-align: center;
        }

        i {
          padding: rem(1px);
        }
      }

      &__next {
        right: rem(10px);

        @include bp-up(xxl) {
          right: rem(20px);
        }
      }

      &__prev {
        left: rem(10px);

        @include bp-up(xxl) {
          left: rem(20px);
        }
      }

      &--disabled {
        color: lighten(color("gray-meta"), 45%);
      }
    }

    &__thumbnails {
      @include list-unstyled();
      overflow: hidden;
      text-align: center;
      margin-top: rem(10px);

      @include bp-down(sm) {
        display: none;
      }

      &__item {
        display: inline-block;
        margin: 0 rem(5px);
        opacity: 0.5;
        transition: opacity 0.18s linear;

        img {
          height: 75px;

          @include bp-up(lg) {
            height: 50px;
          }

          @include bp-up(xl) {
            height: 75px;
          }
        }

        &--current {
          opacity: 1;
        }

        @include on-hover() {
          opacity: 0.75;
        }
      }
    }
  }

  &__content {
    flex: 1 1 auto;
    padding: rem(40px) 0 rem(80px) 0;
    position: relative;
    margin-top: -#{rem(42px)};
    padding-right: rem(40px);

    @include bp-down(md) {
      width: 100%;
      margin-top: 0;
      padding-top: 0;
      padding-left: rem(40px);
    }

    @include bp-down(xs) {
      padding: rem(20px);
    }

    @include bp-up(lg) {
      width: 60%;
    }

    @include bp-up(xl) {
      width: 50%;
      max-width: rem(780px);
    }

    &__title {
      display: block;
      text-decoration: none;
      font-weight: $font-weight-bold;
      color: $body-color;
      font-size: rem(42px);
      line-height: rem(44px);
      margin: rem(12px) 0 0;
      padding-right: rem(50px);

      @include bp-up(xxl) {
        margin-top: rem(20px);
        padding-right: 0;
      }

      @include bp-down(xs) {
        font-size: rem(32px);
        line-height: rem(32px);
        margin-top: 0;
      }

      &--has-stamp {
        padding-right: 100px;

        @include bp-down(sm) {
          padding-right: 80px;
        }
      }
    }


    &__flag {
      @extend .flag;
      margin: 0 rem(8px) rem(8px) 0;

      &--promotion {
        @extend .flag--promotion;
      }
    }

    &__flag-desc {
      @extend .flag;
      background-color: transparent;
      color: color("blue-meta");
      padding-left: 0;

      @include bp-down(xs) {
        font-weight: $font-weight-medium;
        font-size: 11px;
        text-transform: none;
      }
    }

    .product-stamp {
      right: rem(40px);
      top: 0;

      @include bp-down(sm) {
        top: -#{rem(20px)};
      }

      @include bp-down(xs) {
        top: rem(12px);
        right: rem(20px);
      }

      @include bp-up(lg) {
        top: rem(5px);
      }
    }

    &__price {
      display: flex;
      align-items: flex-end;
      width: 50%;

      @include bp-down(xs) {
        width: 100%;
      }

      &__item {
        float: left;

        &:first-child {
          margin-right: rem(20px);
        }

        &__cat {
          @extend .h3;
          font-weight: $font-weight-medium;
          margin: 0 0 0 rem(2.5px);
          color: color("blue-meta");

          span {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: rem(10px);
              right: -#{rem(5px)};
              left: -#{rem(5px)};
              height: rem(15px);
              background-color: color("blue-meta");
              transform: rotate(150deg);
            }
          }
        }

        &__value {
          @extend .h2;
          margin: 0;
          color: color("red");
          float: left;

          @include bp-down(xs) {
            font-size: rem(36px);
            line-height: rem(36px);
          }
        }

        &__meta {
          float: left;
          margin-left: rem(6px);

          &__value {
            display: block;
            font-weight: $font-weight-medium;
            font-size: rem(14px);
            line-height: rem(16px);
            color: $text-muted;

            &:first-child {
              color: color("red");
            }
          }
        }
      }
    }

    &__container {
      width: 100%;
      margin: rem(12px) 0 rem(10px);
      position: relative;

      @include bp-down(xs) {
        margin: rem(18px) 0;
      }
    }

    &__usp {
      width: 50%;
      text-align: right;
      margin-top: -#{rem(8px)};

      @include bp-up(sm) {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      &--push-down {
        @include bp-up(sm) {
          position: relative;
          bottom: -#{rem(57.5px)};
        }
      }

      &--push-down-alt {
        @include bp-up(sm) {
          position: relative;
          bottom: -#{rem(86px)};
        }
      }

      @include bp-down(xs) {
        width: 100%;
        text-align: left;
        margin-top: rem(4px);
      }

      &__item {
        color: color("secondary");
        font-weight: $font-weight-medium;
        font-size: rem(14px);
        line-height: rem(14px);
        padding: rem(4px) 0;
        display: block;

        @include bp-down(xxxs) {
          font-size: rem(12px);
        }

        i {
          position: relative;
          top: rem(1px);
          margin-right: 2px;
        }
      }
    }

    &__description {
      margin: rem(23px) 0 rem(40px);
      transition: opacity 0.18s ease-in;
      font-size: rem(18px);
      line-height: rem(26px);

      &--faded {
        opacity: 0.25;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .tabs {
      transition: opacity 0.18s ease-in;

      &--faded {
        opacity: 0.25;
      }
    }
  }

  &--quickbuy {
    background-color: $white;
    padding: rem(60px) 0;
    display: flex;
    align-items: flex-start;
    width: 100%;

    @include bp-down(md) {
      padding: rem(40px) 0;
    }

    @include bp-down(sm) {
      padding: rem(20px) 0;
    }

    .product__images {
      padding: 0 rem(40px);

      @include bp-down(md) {
        display: none;
      }
    }

    .product__images__slider__item {
      padding: 0;
    }

    .product__images__slider__item__image {
      max-height: 360px;

      @include bp-up(xxl) {
        max-height: 350px;
      }
    }

    .product__images__thumbnails {
      display: none;
    }

    .product__content {
      margin: 0 0 rem(12px);
      padding: 0 rem(40px) 0 0;

      @include bp-down(md) {
        padding: 0 rem(40px);
      }

      @include bp-down(sm) {
        padding: 0 rem(20px);
        margin-bottom: 0;
      }
    }

    .product__content__close {
      @extend .icon;
      @extend .icon--close;
      font-size: rem(36px);
      color: color("red");
      position: absolute;
      z-index: 10;
      top: -#{rem(40px)};
      right: rem(20px);
      background: $white;
      border-radius: 100%;
      padding: rem(5px);
      cursor: pointer;

      @include on-hover() {
        color: darken(color("red"), 15%);
      }

      @include bp-down(md) {
        top: -#{rem(20px)};
      }

      @include bp-down(sm) {
        top: 0;
      }
    }

    .product__content__stamp {
      top: -#{rem(20px)};

      @include bp-down(sm) {
        top: 0;
      }
    }
  }
}
