// stylelint-disable

@font-face {
  font-family: 'TitilliumText';
  font-weight: 800;
  src: url('#{$wdk-font-path}/titillium-text/TitilliumText25L.otf');
}

@font-face {
  font-family: 'TitilliumText';
  font-weight: 700;
  src: url('#{$wdk-font-path}/titillium-text/TitilliumText25L001.otf');
}

@font-face {
  font-family: 'TitilliumText';
  font-weight: 600;
  src: url('#{$wdk-font-path}/titillium-text/TitilliumText25L002.otf');
}

@font-face {
  font-family: 'TitilliumText';
  font-weight: 500;
  src: url('#{$wdk-font-path}/titillium-text/TitilliumText25L003.otf');
}

@font-face {
  font-family: 'TitilliumText';
  font-weight: 400;
  src: url('#{$wdk-font-path}/titillium-text/TitilliumText25L004.otf');
}

@font-face {
  font-family: 'TitilliumText';
  font-weight: 300;
  src: url('#{$wdk-font-path}/titillium-text/TitilliumText25L005.otf');
}
