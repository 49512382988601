.brand-content-block {
  display: flex;
  margin: 2rem 6rem 0 6rem;

  @include bp-down(md) {
    margin: 2rem 1rem 0 1rem;
    flex-direction: column;
  }

  &__header-text {
    display: flex;
    flex-direction: column;
    font-size: 18px;

    p {
      line-height: 150%;
    }
  }

  &__header {

    p {
      font-weight: 700;
      font-size: 36px;
      line-height: 110%;
      text-transform: uppercase;
      color: #D5643C;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
  }

  &__preamble {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #000000;

    p {
      line-height: 150%;
    }
  }

  &__colored {
    padding: 2rem 5rem;

    @include bp-down(md) {
      padding: 2rem 1rem;
    }

    h2 {
      margin-top: 0;
    }

    h3 {
        font-size: 29px;
        font-weight: 700;
        line-height: 130%;
    }
  }

  &__half {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    justify-content: center;

    @include bp-down(md) {
      width: 100%;
      padding: 0;
    }
  }

  &__image-image {
    margin: 0;
    padding: 0;
    margin-right: 2rem;

    @include bp-down(md) {
      margin-right: 0;
    }
  }

  &__squares {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-boxes {
      display: flex;

      @include bp-down(md) {
        flex-wrap: wrap;
      }

      &-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #1F5681;
        color: #F3DECD;
        font-weight: 700;
        font-size: 21px;
        line-height: 28px;
        text-align: center;
        width: 202px;
        height: 188px;

        @include bp-down(md) {
          width: 184px;
          height: 172px;
          font-size: 19px;
        }

        img {
          padding-bottom: 1rem;
        }

        p {
          line-height: 120%;
          max-width: 10rem;
        }
      }
    }
  }

  &__howtos {
    display: flex;
    flex-direction: column;

    &-holder {
      display: flex;

      @include bp-down(md) {
        flex-direction: column;
      }
    }

    &-howto {
      width: 33%;
      margin-right: 2rem;
      max-width: 23rem;

      @include bp-down(md) {
        width: 100%;
        margin-right: 0;
      }

      img {
        background-color: #E3E3E3;
      }
    }
  }

  &__header-text-list {
    display: flex;
    flex-direction: column;

    &__left {
      width: 50%;
      margin-right: 5rem;

      @include bp-down(md) {
        width: 100%;
        margin-right: 0;
      }

      p {
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        max-width: 40rem;
      }

      h3 {

        p {
          font-weight: 700;
          font-size: 47px;
          line-height: 110%;
          text-transform: uppercase;
        }
      }

      ul {
        list-style: none;
        padding-left: 1rem;
      }

      ul li::before {
        content: "\2022";
        color: #D5643C;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }

    &__right {
      align-items: center;

      img {
        max-height: 642px;
        max-width: 546px;
        width: auto;
        height: auto;
      }

      @include bp-down(md) {
        display: none;
      }
    }

    &-btn {
      display: inline-block;
      font-weight: 700;
      font-size: 21px;
      line-height: 120%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      align-items: center;
      padding: 12px 40px;
      border-radius: 48px;
      margin-right: 40px;
      color: #ffffff !important;
      background: #d5643c;
    }

    &--mobile-img {
      display: none;

      @include bp-down(md) {
        display: block;
      }
    }
  }

  &__video {

  }
}
