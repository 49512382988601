.page-header {
  text-align: center;
  padding: rem(40px);

  @include bp-down(xs) {
    padding: rem(40px) rem(20px);
  }

  @include bp-up(xl) {
    padding: rem(60px) rem(40px);
  }

  @include bp-up(xxl) {
    padding: rem(80px) rem(40px);
  }

  &--white {
    background-color: $white;
    border-bottom: 1px solid color("gray-border");
  }

  &--danger {
    color: color("danger");
  }

  &--slim {
    padding: rem(40px) rem(20px);
  }

  &--no-space {
    padding-bottom: 0;
  }

  .breadcrumb {
    padding: 0 0 rem(15px);
  }

  &__title {
    font-size: rem(72px);
    line-height: rem(72px);
    margin: rem(10px) auto 0;
    max-width: rem(820px);
    text-transform: uppercase;

    @include bp-down(sm) {
      font-size: rem(56px);
      line-height: rem(58px);
    }

    @include bp-down(xs) {
      font-size: rem(36px);
      line-height: rem(40px);
    }
  }

  &__byline {
    @extend .byline;
    margin: rem(5px) auto 0;
    max-width: rem(690px);

    span {
      display: inline-block;
      margin: 0 rem(5px);
    }
  }
}
