.flag-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: rem(40px) 0 rem(20px);

  &__border {
    flex-grow: 1;
    height: 1px;
    background: color("gray-border");
    margin-left: rem(32px);
  }

  &--indent {
    .flag {
      margin-left: rem(32px);
    }
  }
}
