.video-hero {
  position: relative;
  height: rem(320px);
  margin-top: rem(20px);

  @include bp-up(sm) {
    height: 60vh;
    max-height: rem(640px);
    margin-top: 0;
  }

  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
