@keyframes mouse-scroll {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }

  80% {
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes mouse-scroll-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  20% {
    transform: translateY(-5px);
  }

  100% {
    opacity: 0;
    transform: translateY(25px);
  }
}

@keyframes mouse-scroll-arrow {
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes snus-guide-spin {
  0% {
    transform: translate3d(0, rem(46px), 0);
  }

  100% {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    display: block;
    opacity: 1;
  }

  99% {
    display: block;
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes fade-in-flex {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: flex;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}

@keyframes fade-out-flex {
  0% {
    display: flex;
    opacity: 1;
  }

  99% {
    display: flex;
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes slide-left {
  0% {
    display: none;
    transform: translateX(100%);
  }

  1% {
    display: block;
    transform: translateX(100%);
  }

  100% {
    display: block;
    transform: translateX(0);
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.7, 0.01, 0.3, 1);
  }
}

@keyframes header-dropdown-slide-down {
  0% {
    display: none;
    transform: translate3d(0, -50%, 0);
  }

  1% {
    display: block;
    transform: translate3d(0, -50%, 0);
  }

  100% {
    display: block;
    transform: translate3d(0, 0, 0);
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.7, 0.01, 0.3, 1);
  }
}

@keyframes pulsating-marker {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
