.brand-blog-list-page {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  &__filter {
    padding: 2rem 0;
    margin: 0 auto;

    &-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      overflow: hidden;

      @include bp-down(md) {
        display: flex;
        width: 367px;
        height: 65px;
        align-items: center;
        overflow-x: scroll;
      }

      &-active {
        background: #1F5681;
        color: white !important;
      }

      li {
        float: left;

        a {
          display: inline-block;
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          align-items: center;
          padding: 12px 40px;
          border-radius: 48px;
          margin-right: 20px;
          color: #1F5681;
          border: 1px solid rgba(31, 86, 129, 0.2);

          &:hover {
            background: rgba(213, 100, 60, 0.2);
            border: none;
          }

          &:active {
            background: #1F5681;
            color: white;
          }
        }
      }

      &-tordenskjold {
        li {
          a {
            color: #009EE1 !important;
            border: 1px solid rgba(0, 158, 225, 0.2) !important;
          }
        }
      }
    }
  }

  &__blogs {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5rem;

    @include bp-down(md) {
      padding: 0 1rem;
      flex-direction: column;
    }

    a {
      width: 50%;

      @include bp-down(md) {
        width: 100%;
      }
    }
  }
}
