.list-unstyled {
  @include list-unstyled();
}

ul {
  margin: 0 0 rem(20px);
}

.list {
  @include list-unstyled();

  li {
    position: relative;
    font-weight: $font-weight-medium;
    line-height: rem(20px);
    padding-left: rem(14px);
    margin: 0.5rem 0;

    @include bp-down(xs) {
      margin: rem(12px) 0;
    }

    a {
      text-decoration: none;
    }

    &::before {
      content: '\f105';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: rem(18px);
      color: $link-color;
      display: block;
      position: absolute;
      left: 0;
      top: -#{rem(1px)};
      pointer-events: none;
      transition: transform 0.21s cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:hover {
      &::before {
        transform: translate3d(rem(3px), 0, 0);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.current {
      &::before {
        color: color("secondary");
      }

      a {
        color: color("secondary");
      }

      &:hover {
        &::before {
          transform: none;
        }
      }
    }
  }

  &--bullets {
    li {
      &::before {
        content: '\f111';
        font-size: rem(11px);
        color: color("secondary");
      }

      &:hover {
        &::before {
          transform: none;
        }
      }
    }
  }

  &--icons {
    li {
      position: relative;
      line-height: rem(28px);
      padding-left: rem(40px);
      margin-bottom: rem(8px);

      &::before {
        content: none;
      }

      i {
        position: absolute;
        left: 0;
        top: rem(4px);
        font-size: rem(20px);
        width: rem(25px);
        text-align: center;
      }
    }
  }
}
