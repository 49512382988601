﻿.block-colorfull-message {
  background-color: color("primary");
  padding: rem(120px) rem(40px);

  @include bp-down(sm) {
    padding: rem(80px) rem(40px);
  }

  @include bp-down(xs) {
    padding: rem(40px) rem(20px);
  }

  &__image {
    width: 100%;
    max-width: rem(640px);
    display: block;
    margin: 0 auto;
    box-shadow: 0 rem(20px) rem(40px) rgba(#000, 0.2);
  }

  &__title,
  &__text {
    margin: 0 rem(40px);
    color: $white;
    position: relative;

    @include bp-down(md) {
      left: rem(40px);
      padding-right: rem(80px);
    }

    @include bp-down(sm) {
      left: 0;
      padding-right: 0;
      margin: 0 rem(20px);
    }

    @include bp-up(lg) {
      width: rem(620px);
      margin: 0 auto;
      left: rem(60px);
      padding-right: rem(120px);
    }
  }

  &__title {
    @extend .h1;
    margin-top: -#{rem(66px)};
    margin-bottom: rem(15px);

    @include bp-down(xs) {
      margin-bottom: rem(5px);
      margin-top: -#{rem(21px)};
    }
  }

  &__text {
    font-weight: $font-weight-light;
    font-size: rem(36px);
    line-height: rem(45px);

    @include bp-down(sm) {
      font-size: rem(23px);
      line-height: rem(32px);
    }

    @include bp-down(xs) {
      font-size: rem(20px);
      line-height: rem(26px);
    }
  }
}
