.responsive-header {
  display: none;
  position: fixed;
  z-index: $z-index-header-responsive;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: rem(50px);
  flex: 0 0 auto;
  background: $blue-gradient;
  text-align: center;

  @include bp-down(md) {
    display: flex;
    align-items: center;
    height: rem(75px);
  }

  @include bp-down(xs) {
    height: rem(50px);
  }

  &--search {
    position: absolute;
    right: 50px;
    margin: 1rem 1rem 0 0;
    top: -4px;

    &.active {
      display: none;
    }
  }

  &__logo {
    display: block;
    width: 100%;

    img {
      position: absolute;
      left: 0px;
      height: 7rem !important;
      top: -30px !important;
    }
  }

  &__logo-tordenskjold {
    display: block;
    width: 100%;

    img {
      position: absolute;
      left: 2rem;
      height: 2rem !important;
    }
  }

  &__logo-sp {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 rem(20px);
    width: rem(220px);
    height: rem(21px);
  }
}

.responsive-header__item,
.responsive-nav-toggle {
  position: relative;
  cursor: pointer;

  &__label {
    font-weight: $font-weight-bold;
    font-size: rem(11px);
    line-height: rem(11px);
    color: $white;
    display: block;
  }
}

.responsive-nav-toggle {
  flex: 0 0 auto;
  width: rem(40px);
  height: rem(50px);
  text-align: center;
  overflow: hidden;

  &--absolute {
    position: absolute;
    right: 0;
    margin: 1rem 1rem 0 0;
  }

  @include bp-down(md) {
    width: rem(70px);
    height: rem(60px);
  }

  @include bp-down(xs) {
    width: rem(40px);
    height: rem(50px);
  }

  &__icon {
    display: block;
    position: relative;
    width: 26px;
    height: 2px;
    background: #F3DECD;
    transition: all 0.3s;

    @include bp-down(md) {
      margin: 17px auto 17px;
    }

    @include bp-down(xs) {
      margin: 17px auto 14px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 26px;
      height: 2px;
      border-radius: 2px;
      background: #F3DECD;
      position: absolute;
      z-index: -1;
      transition: all 0.3s;
    }

    &::before {
      top: -7px;
    }

    &::after {
      top: 7px;
    }
  }

  &.active {
    .responsive-nav-toggle__icon {
      background: none;

      &::before {
        top: 0;
        transform: rotate(-45deg);
      }

      &::after {
        top: 0;
        transform: rotate(45deg);
      }
    }
  }

  &--inverted {
    .responsive-nav-toggle__icon,
    .responsive-nav-toggle__icon::before,
    .responsive-nav-toggle__icon::after {
      background: #F3DECD;
    }

    .responsive-nav-toggle__label {
      color: #F3DECD;
    }
  }
}

.responsive-header__item {
  display: block;
  flex: 0 0 auto;
  margin-right: rem(20px);

  &.active {
    .responsive-header__item__icon-close {
      visibility: visible;
      opacity: 1;
      transition: 0.6s opacity 0.1s ease;
    }

    .responsive-header__item__icon {
      visibility: hidden;
      opacity: 0;
      transition-delay: 0.1s;
    }
  }

  @include bp-down(xs) {
    margin-right: rem(5px);
  }

  &__icon {
    position: relative;
    color: $white;
    font-size: rem(24px);
    transition: 0.6s opacity 0.25s ease;
  }

  &__icon-close {
    @extend .responsive-nav-toggle__icon;
    position: absolute;
    top: -5px;
    left: calc(50% - 16px);
    visibility: hidden;
    opacity: 0;
    transition: 0.6s opacity 0s ease;
    background: none !important;

    &::before {
      top: 0 !important;
      transform: rotate(-45deg) !important;
    }

    &::after {
      top: 0 !important;
      transform: rotate(45deg) !important;
    }
  }

  &__count {
    position: absolute;
    width: 100%;
    top: rem(10px);
    left: 0;
    font-size: 12px;
    font-weight: $font-weight-medium;
    font-style: normal;
    color: color("primary");

    @include bp-only(lg) {
      top: 8px;
    }

    &--sm,
    &--xs {
      font-size: 9px !important;
      top: 12px;

      @include bp-only(lg) {
        top: 10px;
      }
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__icon--active {
    color: color("secondary");

    span {
      color: $white;
    }
  }
}

.responsive-header__item--notifications .responsive-header__item__count {
  top: 6px;
}

.responsive-nav-search {
  display: none;
  z-index: 1500;
  position: fixed;
  top: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #1f5681;
  transform: translateX(-100%);
  transition: transform 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 15rem 2rem 0 2rem;

  @include bp-down(xs) {
    padding-top: rem(50px);
  }

  @include bp-down(md) {
    display: block;
  }

  &.active {
    transform: translateX(0);
  }

  &-box {
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    padding-top: 15rem;

    form {
      text-align: center;
    }
  }

  &-text-box {
    border: none;
    border-bottom: 1px solid white;
    font-weight: 400;
    font-size: 19px;
    line-height: 26px;
    color: #FFFFFF;
    background-color: #1F5681;
    width: 100%;

    &:focus {
      border-bottom: 1px solid white;
    }
  }

  &-btn {
    display: inline-block !important;
    font-weight: 700 !important;
    font-size: 19px !important;
    line-height: 23px !important;
    letter-spacing: 0.02em !important;
    text-transform: uppercase !important;
    align-items: center !important;
    padding: 12px 40px !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 48px !important;
    color: #ffffff !important;
    margin-left: 1rem !important;
    background: transparent !important;
    width: unset;
    height: unset;
    margin-top: 1rem;
  }
}

.responsive-nav {
  display: none;
  z-index: 1500;
  position: fixed;
  top: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: none;
  background: #1F5681;
  transform: translateX(-100%);
  transition: transform 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: rem(75px);

  @include bp-down(xs) {
    padding-top: rem(50px);
  }

  @include bp-down(md) {
    display: block;
  }

  &.active {
    transform: translateX(0);
  }

  a {
    color: $white;
    text-decoration: none;
  }

  &__search {
    &__form {
      position: relative;
    }

    &__icon {
      @extend .icon;
      @extend .icon--search;
      color: color("blue");
      font-size: rem(20px);
      font-weight: normal;
      position: absolute;
      top: rem(13px);
      left: rem(11px);
    }

    input {
      color: color("blue");
      background-color: color("blue-third-alt");
      border: 0;
      width: 100%;
      height: rem(50px);
      padding-left: rem(40px);
      line-height: rem(24px);
      font-size: 16px;
      font-weight: $font-weight-normal;
      font-weight: normal;
    }

    ::-webkit-input-placeholder {
      color: color("blue");
      opacity: 1 !important;
      font-weight: normal;
    }

    :-moz-placeholder {
      color: color("blue");
    }

    ::-moz-placeholder {
      color: color("blue");
    }

    :-ms-input-placeholder {
      color: color("blue");
    }
  }

  &__primary {
    @include list-unstyled();
    margin-bottom: rem(15px);
    text-align: center;
    margin-top: 5rem;

    &__item {
      display: block;
      margin: 0;
      position: relative;

      a {
        display: block;
        padding: 1.2rem rem(11px);
        font-weight: 700;
        font-size: 27px;
        line-height: 120%;

        @include bp-down(xs) {
          padding: rem(15px) rem(11px);
        }
      }

      &__toggle-icon {
        @extend .icon;
        @extend .icon--arrow-right;
        position: absolute;
        z-index: 1;
        color: rgba($white, 0.4);
        cursor: pointer;
        transform: rotate(90deg);
        transition: all 0.15s linear;
        padding: rem(25px);
        top: 0;
        right: 0;

        @include bp-down(xs) {
          padding: rem(15px);
        }
      }

      &.collapsed {
        a {
          border-bottom: 0;
        }

        .responsive-nav__primary__item__toggle-icon {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &__social {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  &__lang-switch {
    @include list-unstyled();
    text-align: center;

    &__item {
      position: relative;
      margin: 0 0 rem(10px);
      padding: rem(2.5px) rem(11px);
      font-weight: 700;
      font-size: 19px;
      line-height: 150%;

      &--current {
        opacity: 1;

        &::after {
          content: "\f107";
          font-family: "Font Awesome 5 Pro";
          font-weight: 400;
          font-size: rem(18px);
          display: block;
          pointer-events: none;
          position: absolute;
          top: -#{rem(1px)};
          right: -#{rem(18px)};

          @include bp-down(sm) {
            display: none;
          }
        }
      }

      .flag-icon {
        margin-right: rem(5px);
      }
    }

    &:hover {
      li {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .sub-menu {
    @include list-unstyled();
    display: none;
    border-bottom: 1px solid rgba($white, 0.1);
    padding-bottom: rem(10px);

    li {
      margin: 0 0 rem(10px);
    }

    a {
      font-size: rem(16px);
      font-weight: normal;
      border: 0;
      color: color("blue-third-alt");
      display: block;
      padding: rem(2.5px) rem(11px) rem(2.5px) 0;
    }
  }

  &__login {
    @include clearfix();
    margin-top: rem(5px) !important;

    .form-group {
      padding: 0 rem(11px);
      margin-bottom: rem(15px) !important;
    }

    .form-group__label {
      color: color("blue-third-alt");
    }

    .form-group__field {
      border: 0;
      color: color("blue");
      background-color: color("blue-third-alt");
      font-size: 16px;
    }

    .form-group--button {
      margin: rem(15px) 0 !important;
    }

    .form-group--error {
      @extend .form-group__label;
      background-color: color("danger");
      color: $white;
      font-size: rem(14px);
      padding: rem(10px);
      width: calc(100% - #{rem(22px)});
      margin: 0 rem(11px) rem(10px) !important;
    }

    a.form-group__forgot-password {
      @extend .form-group__label;
      font-size: rem(14px);
      padding: rem(2.5px) 0;
      text-align: right;
      width: 100%;
      color: color("blue-third-alt") !important;
      margin: -1px rem(10px) 0 0;
    }

    .form-group--inline {
      width: 50%;
      float: left;
    }

    input[type="checkbox"]:checked {
      background-color: $white;
      border-color: $white;

      &::after {
        color: color("blue");
        top: 0;
      }
    }
  }
}

// Hamburger interaction & animation:
body.responsive-notifications-open,
body.responsive-nav-open,
body.responsive-cart-open {
  body {
    overflow: hidden;
  }
}
