.brand-site-footer {
  position: relative;
  background: $blue-gradient;
  padding-top: rem(40px);
  padding-bottom: rem(40px);

  @include bp-down(md) {
    padding-top: 2rem;
    overflow-x: clip;
  }

  &__content {
    display: flex;
    flex-direction: column;
    font-weight: $font-weight-medium;
    font-size: rem(13px);

    &__top {
      display: flex;

      @include bp-down(md) {
        flex-direction: column;
      }

      &__left {
        width: 25%;

        @include bp-down(md) {
          width: 100%;
        }
      }

      &__middle {
        width: 50%;

        @include bp-down(md) {
          width: 100%;
        }

        &-buttons {
          display: flex;

          @include bp-down(md) {
            flex-direction: column;
          }

          &-social {
            display: flex;
            justify-content: center;

            @include bp-down(md) {
              margin-top: 1rem;
            }
          }
        }

        &-distributor {
          margin-top: 2rem;
          font-size: 13px !important;

          p {
            font-size: 13px !important;
          }

          &-text {

            p {
              font-size: 13px !important;
              margin-bottom: 0 !important;
            }
          }

          &-contact {
            display: flex;

            @include bp-down(md) {
              flex-direction: column;
              align-items: center;
            }

            p {
              font-size: 13px !important;
              margin-bottom: 0 !important;
            }
          }

          &-logo {
            height: 65px;
            margin-left: 4rem;
            align-self: flex-end;

            @include bp-down(md) {
              margin-left: 0;
              align-self: auto;
            }
          }
        }

        p {
          font-size: 19px;
          line-height: 135%;
          font-weight: 400;
          margin-bottom: 39px;

          @include bp-down(md) {
            font-size: 19px;
            text-align: center;
          }
        }

        h3 {
          font-size: 47px;
          line-height: 110%;
          font-weight: 700;
          margin-bottom: 16px;

          @include bp-down(md) {
            text-align: center;
            font-size: 38px;
          }
        }
      }

      &__right {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include bp-down(md) {
          width: 100%;
          margin-top: 2rem;
          margin-bottom: 1rem;
          text-align: center;
        }
      }
    }

    &__bottom {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 0 0 0;
      border-top: 1px solid rgba(243,222,205,.2);
      margin-top: 2rem;

      @include bp-down(md) {
        padding: 5rem 0 0 0;
      }

      &-text {
        font-weight: 400;
        font-size: 13px;
        line-height: 130%;
      }

      &-cookie {
        @include bp-down(md) {
          position: absolute;
          top: 30px;
          left: 50%;
          white-space: nowrap;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  a {
    color: white;
    text-decoration: none;
  }

  &__nav {
    @include list-unstyled();
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__item {
      position: relative;
      overflow: hidden;

      a {

        h4 {
          display: block;
          color: $white;
          font-weight: 700;
          font-size: 36px;
          text-decoration: none;
          transition: color 0.18s ease-in;
          line-height: 110%;
          text-transform: uppercase;
          margin-top: 1.7rem;

          @include bp-down(md) {
            font-size: 28px;
          }
        }
      }
    }
  }

  &__social {
    display: block;
    padding: rem(5px) 0;
  }

  &__logo {
    margin-top: -7rem;

    @include bp-down(md) {
      position: absolute;
      top: 55px;
      right: -38px;
      max-width: 17rem;
    }
  }

  &__contact-us {
    display: flex;
    align-items: center;
    padding: 12px 40px;
    background: #D5643C;
    border-radius: 48px;
    text-transform: uppercase;
    margin-right: 40px;

    @include bp-down(md) {
      margin-right: 0;
      align-self: center;
    }
  }

  &__become-reseller {
    padding: 12px 40px;
    border: 1px solid #FFFFFF;
    border-radius: 48px;
    text-transform: uppercase;
    margin-left: 50px;
    white-space: nowrap;
    font-size: 17px;

    @include bp-down(md) {
      margin-left: 0;
    }
  }
}

.footer-btn {
  font-size: 21px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.footer-icon {
  width: 48px !important;
  line-height: 48px !important;
  margin-left: 8px !important;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;

  &::before {
    content: "\00a0";
  }
}
