// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xxxs: 0,
  xxs: 321,
  xs: 437,
  sm: 543px,
  md: 821px,
  lg: 992px,
  xl: 1170px,
  xxl: 1360px,
  xxxl: 1980px
) !default;

// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  xxl: 1360px
) !default;

// Set the number of columns and specify the width of the gutters.

$container-padding: 2.5rem !default;
