.tag {
  display: inline-block;
  padding: rem(8px) rem(10px) rem(6px);
  text-align: center;
  border: 1px solid color("blue-meta-alt");
  font-weight: $font-weight-medium;
  background-color: color("gray-bg");
  margin-bottom: rem(15px);
  font-size: rem(14px);

  &:not(:last-child) {
    margin-right: rem(15px);
  }

  &.tag--active {
    background-color: color("blue");
    color: $white;
    border-color: color("blue");
  }
}

.tag--dark {
  color: $white;
  background-color: transparent;
  border-color: color("gray-border-light");

  &.tag--active {
    background-color: $white;
    color: color("secondary");
    border-color: $white;
  }
}

.tag--gold {
  background-color: color("secondary");
  color: $white;
  border-color: color("secondary");
}

.tag--input {
  input[type="checkbox"],
  input[type="radio"] {
    margin-bottom: -4px;
    margin-right: rem(5px);

    &:checked {
      background-color: #fff;

      &::after {
        color: color("blue");
      }
    }
  }
}

.tag--block {
  display: block;
  text-align: center;
  margin-right: 0;
}

.tag--disabled {
  opacity: 0.5;
}
