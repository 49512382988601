// stylelint-disable

@font-face {
  font-family: 'AkzidenzGrotesk';
  font-weight: 700;
  src: url('#{$wdk-font-path}/akzidenz-grotesk/AkzidenzGrotesk-BoldCond.otf');
}

@font-face {
  font-family: 'AkzidenzGrotesk';
  font-weight: 800;
  src: url('#{$wdk-font-path}/akzidenz-grotesk/AkzidenzGrotesk-ExtraBoldCondAlt');
}
