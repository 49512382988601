$z-index-sh-item-content:         25;
$z-index-header-backdrop:         70;
$z-index-sh-backdrop:             70;
$z-index-cookie-disclaimer:       75;
$z-index-alert-banner:            80;
$z-index-sh-hero:                 80;
$z-index-header-dropdown:         90;
$z-index-tooltip:                 100;
$z-index-header:                  1000;
$z-index-header-responsive:       2000;
$z-index-modal:                   11000;
$z-index-modal-front:             11001;
$z-index-modal-backdrop:          10000;
$z-index-modal-backdrop-front:    10001;

