.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
  top: -2px;
}

.flag-icon::before {
  content: "\00a0";
}

.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-sv {
  background-image: url(#{$wdk-image-path}/flags/4x3/sv.svg);
}

.flag-icon-sv.flag-icon-squared {
  background-image: url(#{$wdk-image-path}/flags/1x1/sv.svg);
}

.flag-icon-ch {
  background-image: url(#{$wdk-image-path}/flags/4x3/ch.svg);
}

.flag-icon-ch.flag-icon-squared {
  background-image: url(#{$wdk-image-path}/flags/1x1/ch.svg);
}

.flag-icon-en {
  background-image: url(#{$wdk-image-path}/flags/4x3/en.svg);
}
