.find-distributors {
  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: rem(32px);

    @include bp-down(md) {
      padding-top: rem(32px);
    }

    @include bp-down(sm) {
      flex-direction: column;
      margin-bottom: 0;
    }

    @include bp-down(xs) {
      padding: rem(20px) 0;
    }
  }

  &__header-col {
    &:last-child {
      flex-shrink: 0;
      width: rem(400px);
      flex-basis: rem(400px);
      margin-left: rem(80px);

      @include bp-down(lg) {
        width: rem(300px);
        flex-basis: rem(300px);
      }

      @include bp-down(sm) {
        width: 100%;
        flex-basis: 100%;
        margin-left: 0;
      }

      .form-group {
        margin: 0;
      }
    }
  }

  &__header-title {
    margin: 0;
    font-size: $h2-font-size;
    line-height: $h2-line-height;

    @include bp-down(sm) {
      margin: 0 0 space(3);
    }

    @include bp-down(xs) {
      margin: 0 0 space(2);
      font-size: $h3-font-size;
      line-height: $h3-line-height;
    }
  }

  &__header-desc {
    margin: space(2) 0 space(1);
    height: rem(28px);
    overflow: hidden;
    font-size: rem(18px);
    line-height: rem(26px);

    @include bp-down(sm) {
      display: none;
    }

    &.active {
      height: auto;

      &:after {
        display: none;
      }
    }
  }

  &__header-show-desc,
  &__header-hide-desc {
    @extend .btn;
    @extend .btn--link;
    position: relative;
    display: none;

    &.active {
      display: inline-block;
    }

    @include bp-down(sm) {
      display: none !important;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: rem(-20px) 0;

    @include bp-down(xs) {
      margin: rem(-30px) 0;
    }
  }

  &__item {
    width: calc(33% - #{rem(20px)});
    flex-basis: calc(33% - #{rem(20px)});
    margin: rem(20px) 0;
    padding: rem(24px);
    background-color: $white;

    @include bp-down(sm) {
      width: calc(50% - #{rem(20px)});
      flex-basis: calc(50% - #{rem(20px)});
    }

    @include bp-down(xs) {
      width: 100%;
      flex-basis: 100%;
      margin: rem(10px) 0;
    }
  }

  &__item-byline {
    @extend .byline;
    font-size: rem(13px);
    line-height: rem(18px);
    color: color("secondary");

    span {
      &:not(:last-child) {
        &:after {
          content: "/";
          margin-left: rem(4px);
        }
      }
    }
  }

  &__item-title {
    @extend .byline;
    margin: space(1) 0 space(2);
  }

  &__item-meta {
    font-size: rem(14px);
    line-height: rem(22px);
    font-weight: $font-weight-medium;
  }

  &__item-contact {
    display: block;
    margin-top: space(1);

    i {
      width: rem(24px);
      margin-right: space(1);
      text-align: center;
    }
  }
}
