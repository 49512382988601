// stylelint-disable

@font-face {
  font-family: 'BrandonGrotesque';
  font-weight: 700;
  src: url('#{$wdk-font-path}/brandon-grotesque/Brandon_bld.otf');
}

@font-face {
  font-family: 'BrandonGrotesque';
  font-weight: 800;
  src: url('#{$wdk-font-path}/brandon-grotesque/Brandon_blk.otf');
}
