.product-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: rem(25px);

  @include bp-down(md) {
    padding: rem(20px);
    margin-bottom: 0;
  }

  @include bp-down(sm) {
    flex-direction: column;
  }

  @include bp-down(xs) {
    padding: rem(20px) rem(20px) 0;
    text-align: center;
  }

  .product-filter {
    margin-right: rem(32px);
    flex: 0 0 auto;

    @include bp-down(sm) {
      margin-right: 0;
    }
  }

  &__definition {
    margin-right: rem(32px);
    width: rem(576px);

    @include bp-down(md) {
      margin-right: 0;
    }
  }

  &__title {
    margin: 0 0 -#{rem(5px)};
    font-size: rem(42px);
    line-height: rem(40px);

    @include bp-only(lg) {
      font-size: rem(36px);
      line-height: rem(38px);
    }

    @include bp-down(sm) {
      margin: 0;
    }

    @include bp-down(xs) {
      text-align: center;
    }
  }

  &__desc {
    margin: rem(10px) 0 rem(7.5px);
    font-size: rem(18px);
    line-height: rem(26px);
    height: rem(22px);
    overflow: hidden;

    @include bp-only(lg) {
      height: 0;
    }

    @include bp-down(sm) {
      position: relative;
      font-size: rem(16px);
      line-height: rem(21px);
      margin: rem(5px) 0 rem(15px);
      height: rem(40px);

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: rem(30px);
        background: linear-gradient(to top, rgba($white, 1), rgba($white, 0));
      }
    }

    @include bp-down(xs) {
      display: none;
    }

    &.active {
      height: auto;

      &::after {
        display: none;
      }
    }
  }

  &__show-desc,
  &__hide-desc {
    @extend .btn;
    @extend .btn--link;
    position: relative;
    display: none;

    &.active {
      display: inline-block;
    }

    @include bp-down(sm) {
      margin: rem(5px) 0 rem(15px);
    }

    @include bp-down(xs) {
      display: none !important;
    }

    &::after {
      content: '';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: rem(18px);
      display: block;
      position: absolute;
      top: -#{rem(1px)};
      right: -#{rem(18.5px)};
      pointer-events: none;
    }
  }

  &__show-desc::after {
    content: '\f107';
  }

  &__hide-desc::after {
    content: '\f106';
  }

  &__toggle-filters {
    @extend .btn;
    @extend .btn--primary;
    @extend .btn--block;
    display: none;

    @include bp-down(sm) {
      &.active {
        display: block;
      }
    }

    @include bp-down(xs) {
      margin: rem(15px) -#{rem(20px)} 0 -#{rem(20px)};
      width: calc(100% + #{rem(40px)});
    }
  }

  &__clear-filters {
    @extend .btn;
    @extend .btn--link;
    display: none;
    margin-top: rem(15px);

    &.active {
      @include bp-down(sm) {
        display: inline-block;
      }
    }

    @include bp-down(xs) {
      margin: rem(20px) 0;
    }
  }

  &__sort {
    flex: 0 0 auto;
    margin-top: 0;

    .form-select {
      margin-top: rem(5px);
    }

    @include bp-down(sm) {
      width: 100%;
      display: none;
      margin: rem(20px) 0 !important;

      &.active {
        display: block;
      }
    }
  }

  &__grid {
    clear: both;
    margin-top: rem(20px);
  }
}
