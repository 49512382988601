.review {
  background: color("primary");
  padding: rem(40px);
  color: $white;
  font-family: $font-family-base;
  font-size: $font-size-base;

  &__title {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    margin-bottom: rem(16px);
  }

  li {
    padding-left: rem(20px);
    margin-bottom: rem(16px);
  }

  &__header {
    font-weight: $font-weight-bold;
    margin-bottom: rem(4px);
  }

  &__content {
    font-style: italic;
    font-weight: $font-weight-light;
  }
}
