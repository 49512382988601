.faq {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;

  .breadcrumb {
    padding-left: 0;

    @include bp-down(sm) {
      display: none;
    }
  }

  &__side {
    background-color: color("gray-bg");
    width: rem(360px);
    padding: 0 rem(40px) rem(40px);
    position: relative;

    @include bp-down(md) {
      padding: 0 rem(20px) rem(20px);
      width: rem(320px);
    }

    @include bp-down(sm) {
      padding-top: rem(20px);
      width: rem(240px);
    }

    @include bp-down(xs) {
      width: 100%;
    }

    &__title {
      @extend .h3;
      margin: 0;
      color: color("secondary");

      @include bp-down(sm) {
        font-size: rem(16px);
      }
    }

    &__nav {
      &.list {
        padding-top: rem(8.5px);
        margin-left: rem(18.5px);
      }

      &__item--active {

        &::before,
        a {
          color: color("secondary") !important;
        }

        &::before {
          transform: translate3d(rem(3px), 0, 0);
        }
      }

      &--fixed {
        top: 112px;
        position: fixed;

        @include bp-down(md) {
          top: rem(90px);
        }

        @include bp-down(xs) {
          position: static;
        }
      }

      &--bottom {
        position: absolute;
        bottom: rem(30px);
        top: auto;

        @include bp-down(xs) {
          position: static;
        }
      }

      @include bp-down(sm) {
        margin-left: 0;
        padding-top: 0;
        font-size: rem(14px);
      }

      @include bp-down(xs) {
        display: none;

        &--collapsed {
          display: block;
        }
      }
    }

    &__toggle-nav {
      @extend .btn;
      @extend .btn--primary;
      @extend .btn--sm;
      display: none;
      margin-top: rem(5px);

      @include bp-down(xs) {
        display: block;
      }
    }
  }

  &__main {
    width: calc(100% - #{rem(360px)});
    background-color: $white;
    padding: rem(75px) rem(40px);

    @include bp-down(md) {
      width: calc(100% - #{rem(320px)});
    }

    @include bp-down(sm) {
      width: calc(100% - #{rem(240px)});
      padding: rem(40px) rem(20px);
    }

    @include bp-down(xs) {
      width: 100%;
    }

    &__section {
      padding: rem(60px) 0;
      position: relative;
      border-top: 1px solid color("gray-border");

      @include bp-down(sm) {
        padding: rem(40px) 0;
      }

      &__title {
        @extend .flag;
        margin: 0;
        position: absolute;
        top: -#{rem(12.5px)};
        left: 0;

        &::after {
          content: '';
          display: block;
          width: rem(25px);
          height: rem(25px);
          background: $white;
          position: absolute;
          top: -#{rem(1px)};
          right: -#{rem(26px)};
        }
      }

      &__item {
        display: flex;
        margin-bottom: rem(40px);

        @include bp-down(md) {
          flex-direction: column;
          margin-bottom: rem(20px);
        }

        &:last-child {
          margin-bottom: 0;
        }

        &__question {
          @extend .h3;
          font-weight: $font-weight-medium;
          text-transform: none;
          margin: 0;
          padding-right: rem(40px);
          width: 40%;

          @include bp-down(md) {
            width: 100%;
            padding: 0;
            margin-bottom: rem(10px);
          }
        }

        &__answer {
          @extend .preamble--sm;
          width: 60%;
          margin: rem(3.5px) 0 rem(20px);

          @include bp-down(md) {
            width: 100%;
          }
        }
      }
    }
  }
}
