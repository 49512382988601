﻿.block-content-page {
  display: flex;
  flex-flow: row wrap;
  padding: rem(40px) rem(40px) rem(20px);
  position: relative;

  @include bp-down(sm) {
    padding: rem(40px) rem(20px) rem(20px);
  }

  @include bp-up(xl) {
    padding: rem(60px) rem(40px) rem(20px);
  }

  @include bp-up(xxl) {
    padding: rem(80px) rem(40px) rem(20px);
  }

  &__side-wrapper {
    position: absolute;
    right: rem(40px);
    left: rem(40px);

    @include bp-down(sm) {
      position: static;
      order: 2;
      width: rem(540px);
      margin: 0 auto rem(40px);
    }
  }
}
