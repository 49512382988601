@keyframes fun-facts-animation {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(80px);
  }

  1% {
    display: block;
    opacity: 0;
    transform: translateY(80px);
  }

  100% {
    display: block;
    opacity: 1;
    transform: translateY(0px);
  }
}

.fun-facts {
  padding: rem(40px) 0;
  background: color("primary");
  color: $white;



  @include bp-up(sm) {
    padding: rem(80px) 0;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 60vh;
    opacity: 0;
    transform: translateY(0px);

    &--inviewport {
      animation: fun-facts-animation 0.85s ease-out forwards;
    }

    @include bp-down(xs) {
      height: 40vh;
    }
  }

  &__content {
    flex-basis: 50%;
    width: 50%;

    @include bp-down(xs) {
      flex-basis: 60%;
      width: 60%;
    }
  }

  &__gfx {
    flex-basis: 0 1 50%;
    width: 50%;
    margin-left: rem(40px);
    text-align: center;

    @include bp-down(xs) {
      flex-basis: 40%;
      width: 40%;
    }

    object {
      max-height: 350px;
    }
  }

  &__tagline {
    color: color("blue-meta-alt");

    @include bp-down(xs) {
      font-size: rem(14px);
      line-height: rem(22px);
    }
  }

  &__headline {
    margin: rem(24px) 0 rem(12px);

    @include bp-down(xs) {
      margin: rem(4px) 0 rem(8px);
      font-size: rem(24px);
      line-height: rem(30px);
    }
  }

  &__intro {
    @include bp-down(xs) {
      font-size: rem(14px);
      line-height: rem(22px);
    }
  }
}

.epi-edit-mode {
  .fun-facts__item {
    height: 500px;
  }
}
