.alert-banner {
  position: fixed;
  z-index: $z-index-alert-banner;
  top: 0;
  left: 0;
  right: 0;
  background: color("danger");
  padding: rem(13px) rem(20px) rem(13px);
  font-weight: $font-weight-medium;
  font-size: rem(13px);
  line-height: rem(13px);
  text-align: center;
  color: $white;

  a {
    color: $white;
    text-decoration: underline;
  }

  @include bp-down(md) {
    top: rem(75px);
  }

  @include bp-down(xs) {
    font-size: rem(12px);
    top: rem(50px);
  }

  &--hidden {
    display: none;
  }

  &--info {
    background-color: color("secondary");
  }

  &--success {
    background-color: color("success");
  }

  &--slim {
    padding-top: rem(4px);
    padding-bottom: rem(2px);
  }

  &__close {
    @extend .icon;
    @extend .icon--close;
    position: absolute;
    font-size: rem(18px);
    color: rgba($white, 0.5);
    top: rem(8.5px);
    right: rem(10px);
    font-weight: 600;
    cursor: pointer;

    @include on-hover() {
      color: $white;
    }
  }
}

.epi-editContainer {
  .alert-banner {
    top: inherit !important;
  }
}
