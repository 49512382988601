.vcard {
  &__row {
    display: flex;
    align-items: center;
  }

  &__portrait {
    border-radius: 50%;
    margin-right: rem(8px);
  }

  &__name {
    font-weight: $font-weight-bold;
  }

  &__position {
    color: $text-muted;
  }

  &__description {
    margin-top: rem(10px);
  }
}
