// stylelint-disable

@font-face {
  font-family: 'OpenSans';
  font-weight: 800;
  src: url('#{$wdk-font-path}/open-sans/OpenSans-ExtraBold.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 700;
  src: url('#{$wdk-font-path}/open-sans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 600;
  src: url('#{$wdk-font-path}/open-sans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 500;
  src: url('#{$wdk-font-path}/open-sans/OpenSans-Medium.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 400;
  src: url('#{$wdk-font-path}/open-sans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 300;
  src: url('#{$wdk-font-path}/open-sans/OpenSans-Light.ttf');
}
