a {
  position: relative;
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &.inverted {
    color: $white;

    &:hover {
      color: lighten($link-color, 20%);
    }
  }
}
