.block-content-page {
  padding: rem(80px) 0;

  @include bp-down(xs) {
    padding: rem(40px) 0;
  }

  .block-main-content {
    @include bp-down(xs) {
      padding: 0 1rem;
    }
  }
}

.block-content-page .container {
  position: relative;
  display: flex;

  @include bp-down(xs) {
    flex-direction: column;
  }

  .block-main-content {
    max-width: 100%;
    margin: 0;
    padding-right: rem(140px);

    @include bp-down(sm) {
      padding-right: rem(40px);
    }

    @include bp-down(xs) {
      padding-right: 0;
    }
  }

  .block-content-page__side-wrapper {
    position: static;
    order: 3;
    max-width: 100%;

    @include bp-down(xs) {
      padding-top: rem(40px);
      display: flex;
    }
  }

  .block-side-widgets {
    position: static;
    width: rem(280px);

    @include bp-down(xs) {
      width: 100%;
    }
  }
}
