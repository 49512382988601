.pagination-container {
  max-width: rem(1000px);
  margin: 0 auto rem(80px);

  @include bp-down(md) {
    margin: 0 auto rem(40px);
  }
}

.pagination {
  @extend .list-unstyled;
  text-align: center;

  li {
    @extend .byline;
    display: inline-block;
    color: color("blue");
    background-color: $white;
    border: 1px solid color("blue-meta-alt");
    border-width: 1px 1px 1px 0;
    transition: background-color 0.18s ease-in;

    &:first-child {
      border-left-width: 1px;
    }

    &.active {
      border-color: color("blue") !important;
      background-color: color("blue") !important;

      span,
      a {
        color: $white;
      }
    }

    @include on-hover() {
      background-color: color("blue-meta-alt");
      transition: none;

      span,
      a {
        color: $white;
      }
    }
  }

  span,
  a {
    display: block;
    padding: rem(13px) rem(26px);
    text-decoration: none;
  }
}
