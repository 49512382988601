$product-thumbnail-size: 58px;
$product-thumbnail-size-sm: 40px;

.product-thumbnail {
  position: relative;
  height: $product-thumbnail-size;

  img {
    display: block;
    position: relative;
    z-index: 10;
    height: 100%;
    width: 100%;
    max-width: $product-thumbnail-size;
  }

  &--can-shadow {
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../../../dist/images/can-shadow.png') bottom center no-repeat;
      background-size: 100%;
    }
  }

  &--sm {
    height: $product-thumbnail-size-sm;

    img {
      max-width: $product-thumbnail-size-sm;
    }
  }
}
