blockquote {
  position: relative;
  margin: $blockquote-margin;
  font-style: italic;
  color: $text-muted;
  font-family: $font-family-base;

  &::before {
    content: "\"";
    position: absolute;
    top: -#{rem(16px)};
    left: -#{rem(32px)};
    color: color("secondary");
    font-size: rem(30px);
  }
}
