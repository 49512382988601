.blog-grid {
  @include bp-up(md) {
    display: flex;

    &__main {
      flex: 1 1 auto;

      .pagination-container {
        margin-top: rem(80px);
      }
    }

    &__sidebar {
      @include fill-bg-right();
      flex: 0 0 rem(320px);
    }
  }

  @include bp-up(lg) {
    &__main {
      margin: 0 rem(40px);
    }
  }

  @include bp-up(xl) {
    &__main {
      margin: 0 rem(110px);
    }
  }
}
