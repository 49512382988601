.hero {
  @include bg-cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $white;
  text-align: center;
  position: relative;

  @include bp-down(md) {
    height: rem(420px);
    height: calc(100vh - #{rem(87px)});
  }

  @include bp-only(sm) {
    height: auto;
    min-height: rem(480px);
  }

  @include bp-down(xs) {
    height: auto;
    min-height: calc(100vh - #{rem(145px)});
    padding: rem(40px) 0;
  }

  @include bp-up(lg) {
    height: rem(520px);
    height: calc(100vh - #{rem(149px)});
  }

  @include bp-up(xl) {
    height: rem(620px);
    height: calc(100vh - #{rem(149px)});
  }

  @include bp-up(xxl) {
    height: rem(720px);
    height: calc(100vh - #{rem(149px)});
  }

  .breadcrumb {
    @extend .breadcrumb;
    padding: 0;

    &__item {
      color: $white;

      @include on-hover() {
        color: $white;
      }
    }
  }

  &__title {
    @extend .h1;
    padding: rem(20px) rem(20px) 0;
    margin: 0 0 rem(10px);

    &--lg {
      text-transform: uppercase;
      font-size: rem(72px);
      line-height: rem(70px);

      @include bp-down(sm) {
        font-size: rem(52px);
        line-height: rem(50px);
      }

      @include bp-down(xs) {
        font-size: rem(42px);
        line-height: rem(44px);
      }

      @include bp-down(xxxs) {
        font-size: rem(36px);
        line-height: rem(36px);
      }
    }
  }

  &__preamble {
    @extend .preamble;
    max-width: rem(720px);
    padding: 0 rem(20px);
    margin-bottom: rem(50px);

    @include bp-down(xs) {
      margin-bottom: rem(20px);
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__byline {
    @extend .byline;
    max-width: rem(720px);
    padding: 0 rem(20px);
    margin-bottom: rem(50px);

    @include bp-down(xs) {
      margin-bottom: rem(30px);
    }
  }

  &__button {
    @extend .btn;
    @extend .btn--lg;
    @extend .btn--inverted-alt;
  }

  &__video {
    display: none;
    position: relative;
    z-index: 3;
    background-color: gray("900");
    box-shadow: 0 24px 48px rgba(#000, 0.6);

    @include bp-down(sm) {
      width: 320px;
      height: 180px;
    }

    &--visible {
      display: block;
    }

    iframe {
      display: block;

      @include bp-down(sm) {
        width: 320px !important;
        height: 180px !important;
      }
    }
  }

  &__action {
    @extend .byline;
    position: absolute;
    width: rem(200px);
    height: rem(120px);
    z-index: 1;
    bottom: rem(50px);
    left: 50%;
    padding-top: rem(75px);
    margin-left: -#{rem(100px)};
    text-align: center;
    opacity: 0;
    animation: mouse-scroll 0.5s forwards;
    animation-delay: 1.2s;
    animation-timing-function: ease-out;

    @include bp-up(xxl) {
      bottom: rem(60px);
    }

    @include bp-down(xs) {
      bottom: 0;
      padding-top: rem(65px);
    }

    &--scroll {
      @include bp-only(sm) {
        display: none !important;
      }

      &::before {
        font-family: "swm-icons";
        content: "\e91a";
        color: $white;
        font-size: rem(40px);
        position: absolute;
        top: rem(15px);
        left: 50%;
        margin-left: -#{rem(12.5px)};
      }

      &::after {
        position: absolute;
        top: rem(20px);
        left: 50%;
        margin-left: -#{rem(5.7px)};
        content: "\f107";
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
        font-size: 16px;
        color: $white;
        opacity: 0;
        animation: mouse-scroll-arrow 1.5s infinite;
        animation-delay: 1.3s;
        animation-timing-function: ease-in;
      }

      &.out {
        animation: mouse-scroll-out 1.5s forwards;
      }
    }

    &--cancel,
    &--play {
      cursor: pointer;

      &::after {
        @extend .icon;
        color: $white;
        font-size: rem(80px);
        position: absolute;
        top: -#{rem(20px)};
        left: 50%;
        margin-left: -#{rem(40px)};
        border-radius: 100%;
        transition: background-color 0.22s linear;
      }

      @include on-hover() {
        &::after {
          background-color: rgba(gray("900"), 0.5);
        }
      }
    }

    &--play {
      &::after {
        font-family: "swm-icons";
        content: "\e910";
      }
    }

    &--cancel {
      display: none;

      &::after {
        font-family: "swm-icons";
        content: "\e92d";
      }
    }

    &--buttons {
      position: static;
      width: auto;
      margin: -#{rem(10px)} 0 0;
      padding: 0;

      @include bp-down(xs) {
        margin: 0;
        height: auto;
      }

      .hero__button {
        margin: rem(10px);
      }
    }
  }

  &__action-byline {
    @extend .hero__byline;
    margin: rem(10px) 0 rem(20px);
  }

  &__title,
  &__preamble,
  &__byline,
  &__button,
  .breadcrumb,
  &__logos {
    position: relative;
    z-index: 1;
  }

  &__overlay {
    background-color: rgba(gray("900"), 0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transition: all 0.33s ease;

    &--in-front {
      background-color: rgba(gray("900"), 0.85);
      z-index: 2;
    }
  }

  &__logos {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: rem(40px) 0;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, transparent 70%);

    @include bp-down(sm) {
      display: none;
    }

    @include bp-down(md) {
      padding: rem(40px) 0 rem(20px);
    }

    .brand-logos {
      background-color: transparent;
      border: 0;
      padding: 0 rem(100px);
      opacity: 0;
      animation: mouse-scroll 0.5s forwards;
      animation-delay: 1.2s;
      animation-timing-function: ease-out;
    }

    svg * {
      fill: $white !important;
    }

    .brand-logos__next,
    .brand-logos__prev {
      background-color: transparent;
      opacity: 0.5;
      transition: opacity 0.18s ease-in;

      .icon--arrow-left::before {
        content: '\e905';
        color: $white;
      }

      .icon--arrow-right::before {
        content: '\e906';
        color: $white;
      }

      @include on-hover() {
        opacity: 1;
      }
    }

    .brand-logos__disabled-nav {
      opacity: 0.1;

      @include on-hover() {
        opacity: 0.1;
      }
    }
  }

  &__background-video {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  &--video-playing {
    .hero__title,
    .hero__byline,
    .hero__action {
      z-index: 3;
    }

    .hero__overlay {
      @extend .hero__overlay--in-front;
    }

    .hero__video {
      @extend .hero__video--visible;
    }

    .hero__action--play {
      display: none;
    }

    .hero__action--cancel {
      display: block;
    }
  }

  &--sm {
    .hero__action--scroll {
      display: none !important;
    }

    @include bp-down(md) {
      height: rem(420px);
    }

    @include bp-down(xs) {
      height: rem(280px);
    }

    @include bp-up(lg) {
      height: rem(480px);
    }

    @include bp-up(xl) {
      height: rem(420px);
    }

    @include bp-up(xxl) {
      height: rem(520px);
    }

    @include bp-up(xxxl) {
      height: rem(620px);
    }
  }

  &--startpage {
    min-height: rem(620px);

    @include bp-down(xs) {
      min-height: 0;
    }

    @include bp-only(sm) {
      min-height: rem(480px);
    }

    .hero__preamble {
      margin-bottom: 0;
    }

    .hero__action--buttons {
      margin: rem(40px) 0 0;

      @include bp-down(xs) {
        margin: rem(10px) 0 rem(60px);
      }
    }
  }

  &--slider {
    overflow: hidden;

    @include bp-down(xs) {
      justify-content: flex-start;
      padding-top: rem(20px);
    }

    .hero__byline {
      margin-bottom: 0;
    }

    &__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      align-items: center;
      box-sizing: content-box;
      transform: translate3d(0px, 0, 0);
    }

    &__item {
      @include bg-cover;
      flex-shrink: 0;
      width: 100%;
      height: 100%;
    }

    &__next,
    &__prev {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      width: rem(60px);
      height: rem(60px);
      font-size: rem(52px);
      color: $white;
      margin-top: -#{rem(30px)};
      z-index: 10;
      cursor: pointer;

      @include bp-down(xs) {
        font-size: rem(40px);
      }

      i {
        padding: rem(1px);
      }

      @include on-hover() {
        opacity: 0.5;
      }
    }

    &__next {
      right: rem(40px);

      @include bp-down(xs) {
        right: rem(10px);
      }
    }

    &__prev {
      left: rem(40px);

      @include bp-down(xs) {
        left: rem(10px);
      }
    }

    &__disabled-nav {
      color: lighten(color("gray-meta"), 45%);

      @include on-hover() {
        cursor: default;
        color: lighten(color("gray-meta"), 45%) !important;
      }
    }
  }

  &--brands {
    .hero__button {
      @extend .btn--primary;
      box-shadow: 0 15px 20px rgba(#000, 0.4);

      @include bp-down(xxs) {
        display: block;
        width: 100%;
        margin: 0 0 rem(20px);
      }
    }
  }
}
