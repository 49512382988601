.blog-item {
  position: relative;
  margin-bottom: rem(80px);

  &__image {
    @include bg-cover();
    height: rem(480px);
    margin-top: rem(24px);
  }

  &__entry {
    padding: rem(32px);
  }

  &__meta {
    color: $text-muted;
    text-transform: uppercase;
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    letter-spacing: 1px;
  }

  &__header {
    margin: rem(24px) 0;
    color: $body-color;
  }

  &__content {
    font-family: $font-family-serif;
    font-size: $font-size-xl;

    video {
      width: 100%;
    }

    > p:first-child {
      &::first-letter {
        font-family: $font-family-base;
        font-size: rem(72px);
        font-weight: $font-weight-bold;
        line-height: rem(44px);
        position: relative;
        padding-top: 16px;
        margin-right: 4px;
        float: left;
        color: color("secondary");
      }
    }
  }

  .review {
    margin: rem(32px) rem(-32px);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
