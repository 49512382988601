[class^="icon"],
[class*=" icon"],
.icon {
  font-family: 'swm-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon--arrow-stats::before {
  content: "\f101";
}

.icon--calculator::before {
  content: "\e938";
}

.icon--gear::before {
  content: "\e939";
}

.icon--info::before {
  content: "\e93a";
}

.icon--notification::before {
  content: "\e93b";
}

.icon--order::before {
  content: "\e93c";
}

.icon--support::before {
  content: "\e93d";
}

.icon--t-shirt::before {
  content: "\e93e";
}

.icon--subscription::before {
  content: "\e935";
}

.icon--settings::before {
  content: "\e934";
}

.icon--snus-strength-5::before {
  content: "\e90a";
}

.icon--snus-strength-1::before {
  content: "\e900";
}

.icon--snus-strength-2::before {
  content: "\e901";
}

.icon--snus-strength-3::before {
  content: "\e902";
}

.icon--snus-strength-4::before {
  content: "\e903";
}

.icon--snus-format-long::before {
  content: "\e904";
}

.icon--snus-format-slim::before {
  content: "\e905";
}

.icon--snus-taste::before {
  content: "\e906";
}

.icon--snus-format-original::before {
  content: "\e907";
}

.icon--snus-format-mini::before {
  content: "\e936";
}

.icon--snus-type::before {
  content: "\e908";
}

.icon--snus-brand::before {
  content: "\e909";
}

.icon--cc-mastercard::before {
  content: "\f1f1";
}

.icon--cc-visa::before {
  content: "\f1f0";
}

.icon--angle-down::before {
  content: "\f107";
}

.icon--angle-up::before {
  content: "\f106";
}

.icon--18-en::before {
  content: "\e90b";
}

.icon--18-de::before {
  content: "\e90c";
}

.icon--flag-de .path1::before {
  content: "\e90d";
  color: rgb(255, 230, 0);
}

.icon--flag-de .path2::before {
  content: "\e90e";
  margin-left: -1.34375em;
  color: rgb(0, 0, 0);
}

.icon--flag-de .path3::before {
  content: "\e90f";
  margin-left: -1.34375em;
  color: rgb(255, 0, 0);
}

.icon--snus-format::before {
  content: "\e921";
}

.icon--snus-taste2::before {
  content: "\e922";
}

.icon--snus-type2::before {
  content: "\e923";
}

.icon--download::before {
  content: "\e920";
}

.icon--creditcard::before {
  content: "\e91e";
}

.icon--giftcard::before {
  content: "\e91f";
}

.icon--delivery::before {
  content: "\e91d";
}

.icon--qa::before {
  content: "\e91c";
}

.icon--academy-production::before {
  content: "\e91b";
}

.icon--mouse::before {
  content: "\e91a";
}

.icon--flag-en .path1::before {
  content: "\e911";
  color: rgb(0, 0, 102);
}

.icon--flag-en .path2::before {
  content: "\e912";
  margin-left: -1.34375em;
  color: rgb(255, 255, 255);
}

.icon--flag-en .path3::before {
  content: "\e913";
  margin-left: -1.34375em;
  color: rgb(255, 255, 255);
}

.icon--flag-en .path4::before {
  content: "\e914";
  margin-left: -1.34375em;
  color: rgb(204, 0, 0);
}

.icon--flag-sv .path1::before {
  content: "\e915";
  color: rgb(0, 64, 115);
}

.icon--flag-sv .path2::before {
  content: "\e916";
  margin-left: -1.34375em;
  color: rgb(0, 63, 115);
}

.icon--flag-sv .path3::before {
  content: "\e917";
  margin-left: -1.34375em;
  color: rgb(255, 204, 0);
}

.icon--flag-sv .path4::before {
  content: "\e918";
  margin-left: -1.34375em;
  color: rgb(255, 204, 0);
}

.icon--flag-sv .path5::before {
  content: "\e919";
  margin-left: -1.34375em;
  color: rgb(255, 204, 0);
}

.icon--flag-sv .path6::before {
  content: "\e924";
  margin-left: -1.34375em;
  color: rgb(0, 63, 115);
}

.icon--18-sv::before {
  content: "\e925";
}

.icon--search::before {
  content: "\e926";
}

.icon--user::before {
  content: "\e927";
}

.icon--basket::before {
  content: "\e928";
}

.icon--snus-can::before {
  content: "\e929";
}

.icon--arrow-filled-left::before {
  content: "\e92a";
}

.icon--arrow-filled-right::before {
  content: "\e92b";
}

.icon--chat::before {
  content: "\e92c";
}

.icon--close::before {
  content: "\e92d";
}

.icon--mail::before {
  content: "\e92e";
}

.icon--phone::before {
  content: "\e92f";
}

.icon--icon-play::before {
  content: "\e910";
}

.icon--arrow-left::before {
  content: "\e930";
}

.icon--arrow-right::before {
  content: "\e931";
}

.icon--checkmark-sm::before {
  content: "\e932";
}

.icon--checkmark::before {
  content: "\e933";
}

.icon--enlarge::before {
  content: "\e989";
}

.icon--picture::before {
  content: "\e937";
}
