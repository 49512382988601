// stylelint-disable

@font-face {
  font-family: 'FoundersGrotesk';
  font-weight: 800;
  src: url('#{$wdk-font-path}/founders-grotesk/FoundersGrotesk-Bold.otf');
}

@font-face {
  font-family: 'FoundersGrotesk';
  font-weight: 700;
  src: url('#{$wdk-font-path}/founders-grotesk/FoundersGrotesk-Bold.otf');
}

@font-face {
  font-family: 'FoundersGrotesk';
  font-weight: 600;
  src: url('#{$wdk-font-path}/founders-grotesk/FoundersGrotesk-SemiBold.otf');
}

@font-face {
  font-family: 'FoundersGrotesk';
  font-weight: 500;
  src: url('#{$wdk-font-path}/founders-grotesk/FoundersGrotesk-Medium.otf');
}

@font-face {
  font-family: 'FoundersGrotesk';
  font-weight: 400;
  src: url('#{$wdk-font-path}/founders-grotesk/FoundersGrotesk-Regular.otf');
}

@font-face {
  font-family: 'FoundersGrotesk';
  font-weight: 300;
  src: url('#{$wdk-font-path}/founders-grotesk/FoundersGrotesk-Light.otf');
}

