.page-not-found {
  @include bg-cover();
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  background-image: url('#{$wdk-image-path}/bg-404.jpg');

  @include bp-down(md) {
    height: 42rem;
    height: calc(100vh - #{rem(87px)});
  }

  @include bp-down(sm) {
    height: auto;
    flex-direction: column;
    text-align: center;
    padding: rem(40px) rem(40px) rem(80px);
  }

  @include bp-down(xs) {
    padding: rem(20px) rem(20px) rem(60px);
  }

  @include bp-up(lg) {
    height: rem(520px);
    height: calc(100vh - #{rem(149px)});
  }

  @include bp-up(xl) {
    height: rem(620px);
    height: calc(100vh - #{rem(149px)});
  }

  @include bp-up(xxl) {
    height: rem(720px);
    height: calc(100vh - #{rem(149px)});
  }

  &__image,
  &__content {
    width: 40%;

    @include bp-down(sm) {
      width: 100%;
    }
  }

  &__content {
    h1 {
      margin: 0 0 rem(20px);
    }

    p {
      @extend .preamble;
    }

    a {
      margin-top: rem(25px);
    }
  }

  &__image {
    height: auto;
    padding: 0 rem(80px) 0 0;

    @include bp-down(md) {
      padding-right: rem(40px);
    }

    @include bp-down(sm) {
      padding: 0;
      width: 80%;
    }
  }
}
