
.brand-list-sm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: rem(80px) 0 0;

  @include bp-down(xs) {
    padding: rem(40px) 0;
  }

  &__item {
    display: flex;
    flex: 0 1 auto;
    width: calc(50% - #{rem(20px)});
    margin-bottom: rem(80px);
  }

  &__aside {
    background-size: contain;
    background-repeat: no-repeat;
    flex: 0 0 auto;
    width: rem(140px);
    height: rem(140px);
    margin-right: rem(24px);
  }

  &__main {
    flex: 1 1 auto;
  }

  &__byline {
    @extend .byline;
    margin-bottom: rem(4px);
    font-size: rem(12px);
    line-height: rem(18px);
    color: color("blue-meta");
  }

  &__title {
    margin: 0 rem(60px) rem(8px) 0;
    word-break: break-word;

    a {
      color: color("secondary");
      text-decoration: none;
    }

    @include bp-only(md) {
      font-size: rem(23px);
    }
  }

  &__preamble {
    font-family: $font-family-sans-serif;
    font-size: $font-size-lg;
    margin-bottom: rem(8px);
    color: $gray-900;

    @include bp-down(xs) {
      font-size: rem(14px);
      line-height: rem(20px);
    }
  }

  &__link {
    a {
      text-decoration: underline;
      text-transform: uppercase;
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
    }

    i {
      position: relative;
      top: 2px;
      margin-left: rem(4px);
      font-size: rem(16px);
      color: $link-color;
    }
  }

  @include bp-down(md) {
    //display: block;
    &__item {
      width: 100%;
      margin-bottom: rem(32px);
    }
  }

  @include bp-down(xs) {
    &__item {
      margin-bottom: rem(24px);
    }

    &__aside {
      width: rem(80px);
      margin-right: rem(16px);
      //margin-bottom: rem(20px);
    }
  }
}
