.brand-product-page {

  &__info {
    display: flex;
    justify-content: center;
    padding: 3rem 0 4rem 0;

    @include bp-down(md) {
      flex-direction: column;
      padding-top: 6rem;
    }

    &-text {
      font-size: 19px;
      line-height: 25.65px;
      max-width: 30rem;
      margin-left: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include bp-down(md) {
        padding: 0 1rem 2rem 1rem;
        margin-left: 0;
      }

      &-pack-size {
        opacity: 0.75;
        padding: 0 0 0.5rem 0;
      }

      &-description {
      }

      h3 {
        font-weight: 700;
        font-size: 47px;
        line-height: 110%;
        color: #1F5681;
      }
    }

    img {
      width: 460px;
    }

    .brand-image-slider__holder {
      display: none;

      @include bp-down(md) {
        display: block;
      }
    }

    .fotorama {
      @include bp-down(md) {
        display: none;
      }
    }

    .fotorama__wrap {
      display: flex;
      flex-direction: row;
    }

    .fotorama__nav-wrap {
      display: flex;
      width: 65px;
      align-self: flex-end;
    }

    .fotorama__nav {
      white-space: normal;
    }
  }

  &__info-blocks {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 32rem;
  }

  &__articles {
    position: relative;
    top: -15rem;
    margin-bottom: -30rem;

    &-holder {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      top: -17rem;
      position: relative;

      &-article {
        max-width: 25rem;
        margin: 2rem;

        &-image {
          width: 389px;
          height: 249px;
          border-radius: 2px;
        }

        &-header {

          h4 {
            top: 0 !important;
            margin: 1rem 0 0 1rem !important;

            p {
              text-align: left;
              font-style: normal;
              font-weight: 700;
              font-size: 36px;
              line-height: 110%;
              text-transform: uppercase;
            }
          }
        }

        &-text {
          font-weight: 400;
          font-size: 19px;
          line-height: 150%;
          color: #1B1B1B;
          margin: 0.5rem 0 0 1rem
        }
      }
    }

    h2 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 61px;
      line-height: 110%;
      text-align: center;
      text-transform: uppercase;
      color: white;
      top: -17rem;
      position: relative;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 250px;
    right: 0;
    z-index: 1;
  }
}
