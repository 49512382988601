.brand-introduction {

  &__content {
    display: flex;
    padding: 5rem;

    @include bp-down(md) {
      padding: 4rem 2rem 4rem 2rem;
      flex-direction: column;
    }

    &-header {
      width: 100%;
      max-width: 32rem;
      text-transform: uppercase;

      h2 {
        font-size: 61px;
        font-weight: 700;
        line-height: 110%;
        margin: 0;

        @include bp-down(md) {
          font-size: 48px;
        }
      }
    }

    &-text {
      font-size: 19px;
      font-weight: 400;

      p {
        line-height: 135%;
      }
    }
  }

  &-btn {
    display: inline-block;
    font-size: 21px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    align-items: center;
    padding: 12px 40px;
    border-radius: 48px;
    margin-right: 40px;

    @include bp-down(md) {
      margin-right: 0;
    }
  }
}
