﻿.block-side-widgets {
  width: rem(280px);
  order: 1;

  @include bp-only(lg) {
    order: 2;
  }

  @include bp-up(xxl) {
    position: absolute;
    top: 0;
    right: 0;
  }

  @include bp-down(sm) {
    width: 100%;
  }
}
