p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
  line-height: $line-height-base;

  &:last-child {
    margin-bottom: 0;
  }
}

.preamble {
  font-family: $font-family-base;
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
  line-height: $lead-line-height;

  @include bp-down(xs) {
    font-size: $font-size-lg;
  }

  &--sm {
    font-size: $font-size-lg;
  }
}

.byline {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  line-height: rem(22px);
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;

  @include bp-down(xs) {
    font-size: $font-size-lg;
    letter-spacing: 0;
    text-transform: none;
    font-weight: $font-weight-medium;
  }
}

.serif-lead {
  font-family: $font-family-serif;
  font-style: italic;
  font-weight: normal;
  font-size: $font-size-lg;
}

.meta {
  font-weight: $font-weight-medium;
  font-size: $font-size-sm;
  color: color("blue-meta");
  letter-spacing: 0.5px;

  &--alt {
    color: color("blue-meta-alt");
  }
}
