﻿.block-side-nav {
  width: rem(300px);
  order: 1;
  margin-bottom: rem(40px);

  @include bp-up(xxl) {
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include bp-down(sm) {
    width: 100%;
    background-color: $white;
    padding: rem(25px);
  }

  &__title,
  &__title a {
    margin: 0 0 rem(20px);
    color: color("secondary");
    text-decoration: none;
  }

  &__list {
    @extend .list;

    &--sub {
      font-size: 85%;
      margin: rem(8px) 0 rem(14px);

      li {
        margin: rem(4px) 0;
        padding-left: rem(20px);

        &::before {
          left: rem(10px);
          font-size: 90%;
        }
      }

      > li a {
        color: $link-color !important;
      }

      li.current > a {
        color: color("secondary") !important;
      }

      ul {
        margin: 0 0 rem(10px) rem(15px);

        li {
          padding-left: rem(11px);

          &::before {
            top: -#{rem(2px)};
            left: rem(1px);
          }
        }
      }
    }
  }
}
