$table-border: 1px solid color("gray-border");

.table {
  width: 100%;
  margin-bottom: rem(20px);
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;

  @include bp-down(xs) {
    display: inline-block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  th {
    text-align: left;
    font-weight: $font-weight-medium;
  }

  thead th {
    color: color("secondary");
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    padding-top: rem(20px);
    border-top: $table-border;
  }

  th,
  td {
    padding: rem(12px) rem(18px);
    border-bottom: $table-border;
    background: $white;

    &:first-child {
      border-left: $table-border;
    }

    &:last-child {
      border-right: $table-border;
    }
  }

  tfoot {
    tr {
      th {
        text-align: right;
      }

      &:not(:last-child) {
        th,
        td {
          border-bottom: 0;
          padding-bottom: 0;
        }

        + tr {
          th,
          td {
            padding-top: 0;
          }
        }
      }
    }
  }

  &--slim {
    thead th {
      border-top: 0;
      color: color("blue-meta");
      font-size: rem(11px);
      text-transform: uppercase;
      padding-top: 0;
      padding-bottom: rem(4px);
    }

    th,
    td {
      padding: rem(10px) 0;

      &:first-child {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
        text-align: right;
      }
    }

    td {
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }
}
