.product-filter {
  margin-top: rem(8px);
  border: 1px solid color("gray-border");
  display: flex;
  flex-flow: row wrap;
  position: relative;
  z-index: 45;

  @include bp-down(md) {
    margin: rem(20px) 0 0;
  }

  @include bp-down(sm) {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    margin: 0 0 rem(20px);
    display: none;

    &.active {
      display: block;
    }
  }

  &__dropdown {
    min-width: rem(300px);
    display: none;
    position: absolute;
    z-index: 45;
    top: rem(78px);
    right: -#{rem(1px)};
    left: -#{rem(1px)};
    padding: rem(20px);
    background-color: $white;
    border: 1px solid color("gray-border");
    box-shadow: 0 10px 20px rgba(gray("900"), 0.1);

    @include bp-down(sm) {
      min-width: initial;
    }

    &__title {
      @extend .h4;
      margin: 0;
      color: $body-color;
      padding-top: rem(2px);

      &--alt {
        margin: rem(18px) 0 -#{rem(5px)};
      }
    }

    &__actions {
      text-align: right;

      span {
        @extend .btn;
        @extend .btn--link;

        &:last-child {
          margin-left: rem(15px);
        }
      }
    }

    &__items {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: rem(10px);

      .form-group {
        margin: rem(5px) 0 0;
        padding-right: rem(10px);
        margin-bottom: rem(3px);
        width: 50%;

        &__label {
          width: calc(100% - #{rem(18px)} - #{rem(7.5px)});
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-decoration: none;
          margin-top: 0;

          a {
            cursor: default;
            color: color("gray-meta");
            text-decoration: none;

            @include on-hover() {
              color: color("gray-meta");
            }
          }

          @include bp-down(sm) {
            width: calc(100% - #{rem(28px)} - #{rem(17px)});

            a,
            span {
              color: $white !important;
            }
          }
        }
      }
    }
  }

  &__item {
    min-width: rem(100px);
    max-width: rem(140px);
    flex: 1 auto;
    padding: rem(8.5px) rem(10px) rem(5px);
    text-align: center;
    text-decoration: none;
    color: $link-color;
    border-right: 1px solid color("gray-border");
    cursor: pointer;

    &:last-of-type {
      border-right: 0;
    }

    @include on-hover() {
      background-color: color("gray-bg");
    }

    @include bp-down(md) {
      min-width: rem(90px);
    }

    @include bp-up(lg) {
      min-width: rem(80px);
    }

    @include bp-up(xxl) {
      min-width: rem(95px);
    }

    @include bp-down(sm) {
      display: block;
      width: 100%;
      max-width: 100%;
      padding: rem(10px);
      text-align: left;
      justify-content: center;
      border-right: 0;
      border-bottom: 1px solid color("gray-border");

      &:last-of-type {
        border-bottom: 0;
      }
    }

    &__content {
      display: block;
      position: relative;
    }

    &__badge {
      display: none;
      font-style: normal;
      background-color: color("red");
      border-radius: 100%;
      position: absolute;
      top: 5%;
      right: 20%;
      color: $white;
      font-weight: $font-weight-bold;
      font-size: rem(11px);
      text-align: center;
      padding: rem(1px);
      width: rem(18px);
      height: rem(18px);
      line-height: rem(18px);

      @include bp-down(sm) {
        right: rem(30px);
        top: 15%;
      }

      &.active {
        display: block;
      }
    }

    &__label {
      font-weight: $font-weight-medium;
      font-size: rem(14px);
      line-height: rem(14px);
      position: relative;

      &::after {
        content: '\f107';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(14px);
        position: relative;
        display: inline-block;
        margin-left: rem(3px);
        top: 0;
        pointer-events: none;

        @include bp-down(sm) {
          position: absolute;
          top: 0;
          right: rem(5px);
        }
      }

      @include bp-down(sm) {
        display: inline-block;
        padding-left: rem(45px);
        width: 100%;
        top: 0;
        margin-left: rem(5px);
        margin-top: rem(8px);
        line-height: rem(20px);
      }
    }

    &__icon {
      @extend .icon;
      height: rem(40px);
      display: block;
      margin: 0 auto;
      position: relative;

      &--brand {
        @extend .icon--snus-brand;
        font-size: rem(30px);
        top: rem(4px);

        @include bp-down(sm) {
          left: rem(5px);
          font-size: rem(24px);
        }
      }

      &--strength {
        @extend .icon--snus-strength-2;
        font-size: rem(15px);
        top: rem(13px);

        @include bp-down(sm) {
          font-size: rem(9px);
        }
      }

      &--type {
        @extend .icon--snus-type;
        font-size: rem(24px);
        top: rem(9px);

        @include bp-down(sm) {
          font-size: rem(16px);
        }
      }

      &--format {
        @extend .icon--snus-format-original;
        font-size: rem(26px);
        top: rem(7.5px);

        @include bp-down(sm) {
          font-size: rem(18px);
        }
      }

      &--taste {
        @extend .icon--snus-taste;
        font-size: rem(28px);
        top: rem(6.5px);

        @include bp-down(sm) {
          font-size: rem(20px);
        }
      }

      @include bp-down(sm) {
        position: absolute;
      }
    }

    &__value {
      font-weight: $font-weight-medium;
    }

    .product-filter__dropdown {
      text-align: left;
      cursor: default;
    }

    &--active {
      background: $link-color;
      color: $white;

      @include on-hover() {
        background: $link-color;
        color: $white;
      }

      .product-filter__item__label::after {
        content: '\f106';
      }

      .product-filter__dropdown {
        display: block;
      }

      @include bp-down(sm) {
        display: block;

        .product-filter__dropdown {
          border: 0;
          position: static;
          box-shadow: none;
          margin: rem(10px) 0;
          padding: 0 rem(5px);
          background: $link-color;
          color: $white;

          &__actions,
          &__title {
            display: none;
          }

          &__items {
            display: block;
            float: none;
            margin-top: 0;

            .form-group {
              width: 100%;
              margin-bottom: rem(10px);

              @include bp-down(sm) {
                &:first-child {
                  margin-top: rem(20px);
                }
              }
            }

            label a {
              color: #fff;
            }

            input {
              border-color: #fff;
              width: rem(28px);
              height: rem(28px);
              margin-right: rem(17px);
            }

            input[type=checkbox]:checked::after {
              top: 4px;
              left: 7px;
            }
          }
        }
      }
    }
  }
}
