﻿.block-image-grid {
  @include clearfix();

  &__item {
    float: left;
    position: relative;
    overflow: hidden;
    background-color: gray("900");

    &:nth-child(1) {
      width: 25%;
      height: rem(330px);
    }

    &:nth-child(2) {
      width: 25%;
      height: rem(330px);
    }

    &:nth-child(3),
    &:nth-child(4) {
      width: 25%;
      height: rem(330px);
    }

    @include bp-down(md) {
      &:nth-child(1) {
        width: 33.3333%;
        height: rem(300px);
      }

      &:nth-child(2) {
        width: 66.6666%;
        height: rem(300px);
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 25%;
        height: rem(300px);
      }

      &:nth-child(5) {
        width: 50%;
        height: rem(300px);
      }
    }

    @include bp-down(sm) {
      &:nth-child(1) {
        width: 33.3333%;
        height: rem(150px);
      }

      &:nth-child(2) {
        width: 66.6666%;
        height: rem(150px);
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 25%;
        height: rem(150px);
      }

      &:nth-child(5) {
        width: 50%;
        height: rem(150px);
      }
    }

    @include bp-up(lg) {
      &:nth-child(1) {
        width: 40%;
        height: rem(660px);
      }

      &:nth-child(2) {
        width: 40%;
        height: rem(330px);
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 20%;
        height: rem(330px);
      }

      &:nth-child(5) {
        display: block;
        width: 40%;
        height: rem(330px);
      }
    }

    @include bp-up(xxxl) {
      &:nth-child(1) {
        height: rem(1000px);
      }

      &:nth-child(2) {
        height: rem(500px);
      }

      &:nth-child(3),
      &:nth-child(4) {
        height: rem(500px);
      }

      &:nth-child(5) {
        height: rem(500px);
      }
    }

    &__image {
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: transform 1.25s ease-out, opacity 0.18s ease;
      cursor: zoom-in;
    }

    @include on-hover() {
      .block-image-grid__item__image {
        transform: scale3d(1.05, 1.05, 1);
        opacity: 0.85;
      }
    }
  }
}
