.section {

  &--white {
    background: $white;
  }

  &--spacing {
    padding: rem(80px) 0;

    @include bp-down(xs) {
      padding: rem(40px) 0;
    }
  }

  &--spacing-slim {
    padding: rem(40px) 0;
  }

  &__title {
    @extend .h1;
    margin: 0 auto rem(40px);
    max-width: rem(780px);
    padding-left: rem(20px);
    padding-right: rem(20px);

    &--centered {
      text-align: center;
    }
  }

  &__intro {
    @extend .preamble;
    margin: -#{rem(24px)} auto rem(40px);
    padding-left: rem(20px);
    padding-right: rem(20px);
    max-width: rem(780px);

    &--centered {
      text-align: center;
    }
  }

  &__divider {
    height: rem(1px);
    background: color("blue-meta-alt");
  }
}
