.block-cover-image {
  position: relative;
  overflow: hidden;
  background-color: color("blue-meta-alt");
  display: block;

  img {
    width: 100%;
    height: 100%;
    display: block;
    transition: transform 5.25s ease-out;
    min-height: rem(400px);

    @include bp-down(xs) {
      min-height: 0;
    }
  }

  &__title {
    @extend .h1;
    margin: 0;
    top: rem(80px);
    left: 0;
    right: 0;
    position: absolute;
    text-align: center;
    color: #fff;
    padding: 0 rem(20px);

    @include bp-down(xs) {
      top: rem(40px);
    }
  }

  &__caption {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    padding: rem(60px) rem(25px) rem(25px);
    content: '';
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, transparent 70%);
    z-index: 10;

    @include bp-down(xs) {
      padding: rem(40px) rem(20px) rem(20px);
    }

    span {
      color: $white;
      display: block;

      @include bp-down(xs) {
        font-size: rem(14px);
        line-height: rem(20px);
      }
    }
  }

  @include on-hover() {
    img {
      transform: scale3d(1.05, 1.05, 1) rotate(0.02deg);
    }
  }
}
