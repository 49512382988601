.product-spot {
  overflow: hidden;
  width: 25%;
  padding: rem(25px) rem(25px) rem(155px);
  position: relative;
  transition: all 0.25s linear;

  &--default {
    background: linear-gradient(135deg, color("gray-bg"), color("gray-bg-alt"));
    background-size: cover;
    background-position: center;

    &.product-spot--active {
      background: #fff;
    }
  }

  &.is-spinning {
    opacity: 0.5;
    cursor: wait;

    .product-spot__button {
      cursor: wait;
    }
  }

  &--default,
  &--promo,
  &--featured {
    opacity: 0;
  }

  &--loaded {
    opacity: 1;
  }

  &--amp {
    float: left;
  }

  @include bp-down(md) {
    padding: rem(25px) rem(25px) rem(160px);
  }

  @include bp-down(sm) {
    width: 33.3333333%;
    padding: rem(25px) rem(25px) rem(180px);
  }

  @include bp-down(xs) {
    width: 50%;
    padding: rem(25px) rem(25px) rem(145px);
  }

  @include bp-up(xxl) {
    padding: rem(25px) rem(25px) rem(110px);
  }

  a:not(.hero__button):not(.product-spot--blend__button):not(.product-spot__button) {
    text-decoration: none;
    color: $body-color;
    display: block;
  }

  &__image-wrapper {
    @include bp-up(lg) {
      padding: 0 6%;
    }

    @include bp-down(md) {
      padding: 0 0%;
    }

    @include bp-up(xl) {
      padding: 0 8%;
    }

    @include bp-up(xxl) {
      padding: 0 10%;
    }

    &--can-shadow {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../../../dist/images/can-shadow.png') bottom center no-repeat;
        background-size: 100%;
        transition: transform 0.33s ease, opacity 0.4s linear;
        opacity: 0.5;
      }
    }
  }

  &__flags {
    position: absolute;
    top: rem(25px);
    left: rem(25px);
    z-index: 1;

    @include bp-down(xs) {
      top: rem(15px);
      left: rem(15px);
    }
  }

  &__flag {
    @extend .flag;
    box-shadow: 4px 15px 35px rgba(0, 0, 0, 0.15);
  }

  .product-stamp {
    width: 80px;
    height: 80px;
    background-size: 80px 80px;

    &__tooltip {
      right: rem(10px);

      @include bp-down(xs) {
        right: 0;
        width: rem(130px);
      }
    }
  }

  &__image {
    width: 100%;
    max-width: rem(240px);
    max-height: rem(240px);
    margin: 0 auto;
    display: block;
    transition: transform 0.33s ease, opacity 0.4s linear;
  }

  &__title {
    position: absolute;
    right: rem(25px);
    bottom: rem(70px);
    left: rem(25px);
    margin: 0;

    @include bp-down(xs) {
      left: rem(15px);
      bottom: rem(65px);
    }

    @include bp-up(xxl) {
      right: rem(170px);
      bottom: rem(24px);
    }

    &__brand {
      font-weight: $font-weight-normal;
      font-size: rem(16px);
      line-height: rem(20px);
      display: block;
      text-transform: none;

      @include bp-down(xs) {
        font-size: rem(14px);
        line-height: rem(18px);
      }
    }

    &__type,
    &__strength {
      display: block;
      margin-top: rem(2px);
      font-weight: $font-weight-bold;
      font-size: rem(14px);
      line-height: rem(19px);
      text-transform: uppercase;

      @include bp-down(xs) {
        font-size: rem(12px);
        line-height: rem(16px);
      }
    }

    &__strength {
      margin-top: 0;
    }
  }

  &__cta {
    position: absolute;
    bottom: rem(25px);
    left: rem(25px);
    display: flex;
    align-items: center;

    @include bp-down(xs) {
      left: rem(15px);
      bottom: rem(15px);
    }

    @include bp-up(xxl) {
      left: auto;
      right: rem(25px);
      text-align: right;
    }
  }

  &__button {
    @extend .btn;
    @extend .btn--primary;
    @extend .btn--sm;
    order: -1;
    margin-right: rem(10px);

    @include bp-up(xxl) {
      order: 2;
      margin-right: 0;
      margin-left: rem(10px);
    }

    &:disabled {
      @include bp-down(xs) {
        display: none;
      }
    }

    &--link {
      @extend .btn--link;
      padding: 0;
      font-size: $font-size-sm;
      color: $body-color;
    }
  }

  &__price {
    color: color("red");
    position: relative;
    top: 2px;

    &__primary,
    &__secondary {
      display: block;
    }

    &__primary {
      font-weight: $font-weight-bold;
      font-size: rem(14px);
      line-height: rem(18px);
      color: color("red");

      &--out-of-stock {
        max-width: rem(100px);
      }
    }

    &__secondary {
      font-weight: $font-weight-medium;
      font-size: rem(12px);
      line-height: rem(16px);
      color: color("blue-meta");
    }
  }

  &__bg-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: transform 1.25s ease-out;

    &--shadow::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: rem(140px);
      background: linear-gradient(0deg, rgba(#000, 0.7), rgba(#000, 0));

      @include bp-up(xxl) {
        height: rem(100px);
      }
    }
  }

  @include on-hover() {
    &:not(.product-spot--featured):not(.product-spot--promo):not(.product-spot--content):not(.product-spot--blend):not(.hero--brand-promo) {
      background: linear-gradient(-135deg, $white, color("gray-bg"));
    }

    .product-spot__image {
      transform: scale3d(1.05, 1.05, 1);
      opacity: 0.75;
    }

    .product-spot__image-wrapper--can-shadow::after {
      transform: scale3d(1.05, 1.05, 1);
      opacity: 0.25;
    }

    .product-spot__bg-image {
      transform: scale3d(1.05, 1.05, 1);
    }
  }

  &--featured,
  &--promo {
    width: 50%;

    @include bp-down(sm) {
      width: 100%;
    }
  }

  &--promo {
    background-color: #e9e0cb;
    background-size: cover;
    background-position: center center;

    @include bp-down(md) {
      width: 75%;
    }

    @include bp-down(sm) {
      width: 100%;
    }

    @include bp-up(lg) {
      width: 75%;
    }

    @include bp-up(xl) {
      width: 50%;
    }

    @include on-hover() {
      .product-spot__image {
        opacity: 1;
      }
    }

    .product-spot__image-wrapper {
      padding-top: 0 !important;

      @include bp-down(xs) {
        padding: rem(20px) rem(20px) rem(30px) !important;
      }
    }

    .product-spot__left,
    .product-spot__right {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      float: left;

      @include bp-down(xs) {
        float: none;
        width: 100%;
        height: auto;
      }
    }

    .product-spot__left {
      padding: 0 rem(25px) 0 0;

      @include bp-down(xs) {
        padding: 0;
        position: static;
      }
    }

    .product-spot__right {
      align-content: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0 rem(20px) 0 0;

      @include bp-down(sm) {
        padding: 0;
      }
    }

    .product-spot__flag {
      top: 0;
      left: 0;

      @include bp-down(xs) {
        top: rem(15px);
        left: rem(15px);
      }
    }

    .product-spot__title__brand,
    .product-spot__title__type,
    .product-spot__title__strength {
      font-size: rem(22px);
      line-height: rem(28px);
      margin: 0;

      @include bp-down(xs) {
        font-size: rem(18px);
        line-height: rem(24px);
      }
    }

    .product-spot__title__brand {
      margin-bottom: rem(5px);
    }

    .product-spot__description {
      margin: rem(10px) 0 rem(30px);
      font-family: $font-family-serif;
      font-size: rem(18px);
      line-height: rem(26px);

      @include bp-down(sm) {
        font-size: rem(14px);
        line-height: rem(20px);
        margin: rem(10px) 0 rem(15px);
      }
    }

    .product-spot__title,
    .product-spot__price,
    .product-spot__button {
      position: static;
    }

    .product-spot__price {
      text-align: left;
      margin-left: rem(10px);
    }

    .product-spot__text-link {
      text-align: center;
      text-decoration: underline;
      margin-top: rem(20px);

      @include bp-down(sm) {
        display: none !important;
      }

      @include bp-down(xs) {
        font-size: rem(14px);
        position: absolute;
        bottom: rem(15px);
        right: rem(15px);
        z-index: 10;
      }
    }

    .product-spot__button {
      order: inherit;
    }
  }

  &--featured {
    padding: rem(25px) rem(25px) rem(250px);
    background: #d6e6f0;

    .product-spot__image-wrapper {
      @include bp-up(sm) {
        position: relative;
        top: 40%;
        transform: translateY(-50%);
      }
    }

    .product-spot__image {
      max-width: rem(480px);
      max-height: rem(480px);

      @include bp-up(xl) {
        max-width: rem(540px);
        max-height: rem(540px);
      }
    }

    .product-spot__title {
      bottom: rem(105px);
      left: rem(40px);

      @include bp-up(xxl) {
        bottom: rem(41px);
      }

      @include bp-down(xs) {
        left: rem(20px);
        bottom: rem(85px);
      }
    }

    .product-spot__title__brand {
      @extend .h2;
      margin: 0 0 rem(15px);
      font-weight: $font-weight-light;

      @include bp-down(md) {
        font-size: rem(28px);
        margin-bottom: rem(2.5px);
      }

      @include bp-down(xs) {
        font-size: rem(21px);
        margin-bottom: rem(2.5px);
      }
    }

    .product-spot__title__type,
    .product-spot__title__strength {
      @extend .h3;
      margin: 0;

      @include bp-down(md) {
        font-size: rem(21px);
      }

      @include bp-down(xs) {
        font-size: rem(16px);
        line-height: rem(23px);
      }
    }

    .product-spot__cta {
      left: rem(40px);
      bottom: rem(40px);

      @include bp-up(xxl) {
        left: auto;
        right: rem(50px);
        bottom: rem(50px);
      }

      @include bp-down(xs) {
        left: rem(20px);
        bottom: rem(20px);
      }
    }

    .product-spot__button {
      @extend .btn--lg;
    }

    .product-spot__price {
      margin-left: rem(20px);

      @include bp-up(xxl) {
        margin-left: 0;
        margin-right: rem(20px);
      }
    }

    .product-spot__price__primary,
    .product-spot__price__secondary {
      @extend .h4;
      line-height: rem(24px);
      margin: 0;
    }
  }

  &--content {
    width: 25%;
    background: color("secondary");
    color: $white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 0;

    @include on-hover() {
      background: color("secondary");
    }

    @include bp-down(md) {
      width: 50%;
    }

    @include bp-down(sm) {
      width: 66.666667%;
    }

    @include bp-down(xs) {
      width: 100%;
    }

    &__title {
      margin: 0 0 rem(10px);

      @include bp-up(xxl) {
        font-size: rem(56px);
        line-height: rem(54px);
        margin-bottom: rem(20px);
      }
    }

    &__text {
      font-family: $font-family-serif;
      font-size: rem(18px);
      line-height: rem(26px);
      max-height: rem(160px);
      overflow: hidden;
    }

    &__button,
    a {
      @extend .btn;
      @extend .btn--lg;
      @extend .btn--inverted;
      display: inline-block;
      width: auto;
    }
  }

  &--blend {
    background: $blue-gradient;
    color: $white;

    @include on-hover() {
      background: $blue-gradient;
    }

    @include bp-down(md) {
      width: 50%;
    }

    @include bp-down(sm) {
      width: 66.666667%;
    }

    @include bp-down(xs) {
      width: 100%;
    }

    @include bp-up(lg) {
      width: 50%;
    }

    @include bp-up(xl) {
      width: 25%;
    }

    &__meta {
      position: absolute;
      top: rem(25px);
      right: rem(25px);
      left: rem(25px);
      font-size: rem(14px);
      line-height: rem(22px);
      text-transform: uppercase;
      padding-left: rem(62px);

      span {
        display: block;

        a {
          display: inline !important;
          color: $white !important;
          text-decoration: underline !important;
        }
      }

      img {
        position: absolute;
        top: -#{rem(5px)};
        left: 0;
        width: rem(50px);
        height: rem(50px);
        border-radius: rem(50px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.18);
      }
    }

    &__title {
      margin: rem(65px) 0 rem(15px);
      text-transform: uppercase;
    }

    &__ingredients {
      font-family: $font-family-serif;
      margin-bottom: rem(30px);
      overflow: hidden;

      dt {
        font-size: rem(14px);
        font-weight: bold;
        text-transform: uppercase;
        padding: 0 0 rem(2px);
        margin-bottom: rem(20px);
        border-bottom: 2px dotted $white;
      }

      dd {
        float: left;
        margin-top: -#{rem(50px)};
        font-size: rem(18px);
        font-style: italic;
        margin-left: rem(80px);
        white-space: nowrap;
        overflow: hidden;

        @include bp-down(xs) {
          margin-top: -#{rem(49px)};
          font-size: rem(16px);
        }

        @include bp-only(xl) {
          margin-top: -#{rem(49px)};
          font-size: rem(16px);
        }
      }
    }

    &__price {
      font-weight: $font-weight-bold;
      font-size: rem(52px);
      line-height: rem(46px);
      position: relative;
      display: inline-block;

      &__currency {
        font-size: rem(14px);
        line-height: rem(14px);
        position: absolute;
        top: rem(15px);
        right: -#{rem(30px)};
      }

      &__prefix {
        font-size: rem(14px);
        line-height: rem(14px);
        text-transform: uppercase;
        margin-right: rem(5px);
      }
    }

    .product-spot--blend__button {
      @extend .btn;
      @extend .btn--inverted;
      position: static;
      color: $link-color;
      display: inline-block;
      float: right;
    }
  }

  &--kardus {
    background: url('../../../dist/kardus/kardus-product-spot.jpg?w=600');
    background-size: cover;
    background-position: center;
    color: $white;
    cursor: pointer;

    a {
      color: $white !important;
    }

    .product-spot__price__secondary {
      color: $white;
    }

    .product-spot__button {
      background-color: #3e49d4;
      border-color: #3e49d4;
    }

    .product-spot__image-wrapper {
      visibility: hidden;
    }

    @include on-hover() {
      background: url('../../../dist/kardus/kardus-product-spot.jpg?w=600') center / cover !important;
    }

    &.product-spot--featured {
      background: url('../../../dist/kardus/kardus-product-spot.jpg?w=1200');
      background-size: cover;
      background-position: center;
      color: $white;
      cursor: pointer;
    }

    &.product-spot--promo {
      background-repeat: no-repeat;
      background-color: #0d0f0e;

      @include bp-down(xs) {
        width: 100%;
        height: rem(380px) !important;
      }

      @include bp-down(xxs) {
        height: rem(320px) !important;
      }

      @include on-hover() {
        background: #0d0f0e url('../../../dist/kardus/kardus-product-spot.jpg?w=600') center no-repeat / cover !important;
      }

      .product-spot__title,
      .product-spot__price,
      .product-spot__button {
        position: absolute;
      }

      .product-spot__left {
        visibility: hidden;
        display: none;

        @include bp-up(xxl) {
          display: flex;
        }
      }

      .product-spot__right {
        width: 100%;

        @include bp-up(xxl) {
          width: 50%;
        }

        @include bp-down(sm) {
          height: 100%;
        }
      }
    }
  }

  &--inverted-txt {
    color: $white;

    a {
      color: $white !important;
    }

    .product-spot__price__secondary,
    .product-spot__price__primary {
      color: $white;
    }

    .product-spot__button {
      @extend .btn--inverted-alt;
    }
  }

  &--stacked {
    .product-spot__title,
    .product-spot__cta {
      display: block;
      position: static;
      text-align: center;
    }

    .product-spot__title {
      margin-top: rem(25px);
      margin-bottom: rem(5px);
    }

    .product-spot__button {
      margin-left: 0;
    }
  }
}
