.flag {
  @extend .btn;
  @extend .btn--xs;
  background-color: color("secondary");
  cursor: default;
  font-style: normal;
  pointer-events: none;

  &--lg {
    font-size: rem(14px);
    padding: rem(16px) rem(12px) rem(14px);
  }

  &--shadow {
    box-shadow: 4px 15px 35px rgba(#000, 0.15);
  }

  &--spacing {
    margin-bottom: rem(16px);
  }
}

.flag-slim {
  color: color("blue-third");
  font-size: rem(12px);
  text-transform: uppercase;

  &::after {
    content: ',';
  }

  &:last-child::after {
    content: '';
  }
}

.flag--promotion {
  background-color: color("red");
}
