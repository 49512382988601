.block-side-widget {
  margin-bottom: rem(40px);

  &__image {
    display: block;
    width: 100%;
  }

  &__content {
    padding: rem(25px);
    background-color: $white;

    p {
      font-size: rem(14px);
      line-height: rem(22px);

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      @extend .list;
      @extend .list--bullets;

      li {
        font-size: rem(14px);
        line-height: rem(18px) !important;
      }
    }
  }

  &__title {
    @extend .byline;
    margin: 0 0 rem(10px);
    color: color("secondary");

    + .collapse {
      padding-top: 0;
    }
  }

  &__download-item {
    position: relative;
    padding: rem(7.5px) 0 0 rem(37.5px);
    text-decoration: none;

    i {
      position: absolute;
      top: 0;
      left: 0;
      font-size: rem(28px);
    }
  }

  &--info {
    .block-side-widget__content {
      box-shadow: inset 3px 0 0 color("secondary");
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &--download {
    .block-side-widget__title {
      color: color("primary");
    }

    .block-side-widget__content {
      box-shadow: inset 3px 0 0 color("primary");
    }
  }

  &--links {
    .block-side-widget__title {
      color: $white;
      margin-bottom: rem(15px);
    }

    .block-side-widget__content {
      background: $blue-gradient;

      ul {
        li {
          &::before {
            content: "\f061";
          }
        }
      }

      a {
        color: $white;
        text-decoration: underline;
      }
    }
  }

  &--stores {
    .block-side-widget__content {
      box-shadow: inset 3px 0 0 color("secondary");
    }

    .block-side-widget__stores-list {
      @include list-unstyled();
    }

    .block-side-widget__stores-list__item {
      position: relative;
      padding-left: rem(125px);
      margin-bottom: rem(25px);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .block-side-widget__stores-list__item__image {
      position: absolute;
      top: 0;
      left: 0;
      width: rem(110px);
    }

    .block-side-widget__stores-list__item__content {
      font-size: rem(14px);
      font-weight: $font-weight-normal;
    }

    .block-side-widget__stores-list__item__content b {
      font-size: rem(16px);
      font-weight: $font-weight-bold;
      display: block;
      margin-bottom: rem(5px);
    }
  }

  &--faq {
    box-shadow: $box-shadow;

    .block-side-widget__title {
      padding-bottom: rem(20px);
      margin-bottom: rem(20px);
      border-bottom: 1px solid #ddd;
    }
  }
}
