.brand-blog-page {

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    margin: 3rem 6rem;
    border-top: 1px solid #1F5681;
    border-bottom: 1px solid #1F5681;

    @include bp-down(md) {
      margin: 2rem 1rem;
    }

    &--desktop {
      @include bp-down(md) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include bp-down(md) {
        display: flex;
      }
    }

    &-link {
      color: #1F5681;
      font-weight: 700;
      font-size: 21px;
      line-height: 120%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
  }
}
