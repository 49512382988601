.summary-list {
  &__header {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    padding: rem(15px) 0;
    border-bottom: 1px solid color("blue-secondary");
  }

  &__item {
    text-transform: uppercase;
    font-size: rem(14px);
    line-height: rem(20px);
    padding: rem(15px) 0;
    overflow: hidden;
    border-bottom: 1px solid color("blue-secondary");

    @include bp-down(xxs) {
      font-size: rem(12px);
      line-height: rem(18px);
    }

    &__label {
      float: left;
      color: color("blue-meta-alt");
    }

    &__value {
      float: right;
      text-align: right;

      &--lg {
        font-weight: $font-weight-bold;
        font-size: rem(18px);

        @include bp-down(xxs) {
          font-size: rem(16px);
        }
      }
    }
  }

  &__action {
    margin-top: rem(30px);

    &__error {
      margin-top: rem(20px);
      padding: rem(10px);
      background: color("red");
      color: $white;
      text-align: center;
    }

    &__text {
      margin-top: rem(10px);
      font-size: rem(14px);
      line-height: rem(18px);
      color: color("blue-meta-alt");

      a {
        color: $white;
      }
    }
  }

  &--inverted {
    .summary-list {
      &__header,
      &__item {
        border-color: color("blue-border");
      }

      &__item__label {
        color: $black;
      }

      &__item__value {
        font-family: $font-weight-medium;
      }
    }
  }
}
