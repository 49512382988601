﻿.block-image-columns {
  padding: rem(120px) 0;
  background-color: $white;

  @include bp-down(sm) {
    padding: rem(80px) 0;
  }

  @include bp-down(xs) {
    padding: rem(40px) 0;
  }

  &__hero {
    background-size: cover;
    background-position: center center;
    margin-bottom: rem(40px);

    @include bp-up(xl) {
      width: rem(1000px);
      margin: 0 auto rem(40px);
    }

    &__title {
      @extend .h1;
      margin: 0;
      padding: 20% rem(40px);
      color: $white;
      text-transform: uppercase;
      font-size: rem(72px);
      line-height: rem(70px);
      text-align: center;

      @include bp-down(sm) {
        font-size: rem(52px);
        line-height: rem(50px);
      }

      @include bp-down(xs) {
        font-size: rem(42px);
        line-height: rem(44px);
      }
    }
  }

  &__content {
    display: flex;

    @include bp-up(xl) {
      width: rem(1000px);
      margin: 0 -#{rem(40px)};
    }

    @include bp-down(sm) {
      flex-direction: column;
    }

    &__item {
      padding: 0 rem(40px);

      @include bp-down(sm) {
        padding: 0 rem(20px);
        margin-bottom: rem(30px);

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__title {
        color: color("secondary");
        margin: 0 0 rem(10px);
      }

      &__text {
        @extend .preamble--sm;
        margin: 0;
      }
    }
  }
}
