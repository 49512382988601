// stylelint-disable value-keyword-case
$font-family-sans-serif:      "FFDINWebPro", "Arial", sans-serif !default;
$font-family-serif:           "minion-pro", serif !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-xl:                ($font-size-base * 1.25) !default;
$font-size-lg:                ($font-size-base * 1.125) !default;
$font-size-sm:                ($font-size-base * 0.875) !default;
$font-size-xs:                ($font-size-base * 0.75) !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-bold:            700 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 3.5 !default;
$h2-font-size:                $font-size-base * 2.25 !default;
$h3-font-size:                $font-size-base * 1.625 !default;
$h4-font-size:                $font-size-base !default;

$h1-line-height:              $font-size-base * 3.375 !default;
$h2-line-height:              $font-size-base * 2.5 !default;
$h3-line-height:              $font-size-base * 2 !default;
$h4-line-height:              $font-size-base * 1.125 !default;

$headings-margin-top:         2.5rem !default;
$headings-margin-bottom:      calc($spacer / 2) !default;
$headings-font-family:        $font-family-sans-serif !default;
$headings-font-weight:        $font-weight-bold !default;
$headings-color:              inherit !default;

$lead-font-size:              1.4375rem !default;
$lead-font-weight:            $font-weight-normal !default;
$lead-line-height:            1.4 !default;

$small-font-size:             $font-size-sm !default;

$text-muted:                  $blue-meta !default;

$paragraph-margin-bottom:     1.25rem !default;
$blockquote-margin:           2rem 0 !default;
