.product-list {
  margin-top: rem(40px);

  &.is-loading {
    cursor: wait !important;

    * {
      pointer-events: none;
    }
  }

  &__grid-loader {
    position: absolute;
    width: 100%;
    font-size: rem(30px);
    font-weight: bold;
    margin: rem(200px) 0;
    text-align: center;
  }

  &.layout-complete {
    .product-list__grid {
      visibility: visible;
    }

    .product-list__grid-loader {
      display: none;
    }
  }

  &__grid {
    @include clearfix();
    visibility: hidden;
    background-color: color("gray-bg");

    .product-spot {
      @include bp-down(md) {
        padding: rem(25px);
        height: rem(400px);
      }

      @include bp-up(lg) {
        padding: rem(25px);
        height: rem(370px);
      }

      @include bp-up(xl) {
        padding: rem(25px);
        height: rem(420px);
      }

      @include bp-up(xxl) {
        padding: rem(25px);
        height: rem(400px);
      }

      @include bp-down(xs) {
        padding: rem(25px);
        height: rem(380px);
      }

      @include bp-down(xxs) {
        height: rem(310px);
      }
    }

    .product-spot--featured {
      @include bp-down(md) {
        padding: rem(25px);
        height: rem(800px);
      }

      @include bp-down(xs) {
        padding: rem(25px) rem(25px) rem(160px);
        height: rem(620px);
      }

      @include bp-down(xxs) {
        height: rem(540px);
      }

      @include bp-up(lg) {
        padding: rem(25px);
        height: rem(740px);
      }

      @include bp-up(xl) {
        padding: rem(25px);
        height: rem(840px);
      }

      @include bp-up(xxl) {
        padding: rem(25px);
        height: rem(800px);
      }
    }

    .product-spot--promo {
      @include bp-down(sm) {
        height: rem(320px);
      }

      @include bp-down(xs) {
        padding: rem(15px);
        height: rem(530px);
      }
    }

    .product-spot--content,
    .product-spot--blend {
      @include bp-down(xs) {
        height: auto;
      }
    }

    .product-spot__image-wrapper {
      @include bp-down(md) {
        padding-top: 6%;
      }

      @include bp-down(xs) {
        padding-top: 0%;
      }

      @include bp-up(xxl) {
        padding-top: 6%;
      }
    }
  }

  &__no-results {
    background-color: color("gray-bg");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: rem(80px) rem(20px);
    border-top: 1px solid color("gray-border");

    @include bp-down(xs) {
      padding: rem(40px) rem(20px);
    }
  }

  &__no-results-title {
    @extend .preamble--sm;
    color: color("blue-meta");
    margin-bottom: rem(20px);
    text-align: center;
  }

  &--wrapper {
    background: linear-gradient(90deg, color("gray-bg"), color("gray-bg-alt"));
    padding-bottom: 7rem;

    .product-spot--default {
      background: linear-gradient(90deg, color("gray-bg"), color("gray-bg-alt"));
    }

    .product-list__grid {
      @include make-container($padding: false);
      background: none;
    }
  }
}
