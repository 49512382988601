.timeline {
  position: relative;
  min-height: rem(400px);

  &__cards {
    left: 50%;
    margin-left: rem(-142px);
    position: absolute;
    width: rem(284px);
    z-index: 10;
    background: $white;
    border-radius: rem(4px);
    box-shadow: 0 rem(14px) rem(36px) rgba(#000, 0.08);
    top: 0;
    bottom: 0;
    overflow: hidden;
    transition: height 0.1s ease-in;
  }

  &__card-wrapper {
    display: flex;
    height: 100%;
  }

  &__card {
    width: 100%;
    flex: 1 0 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in;

    &--active {
      opacity: 1;
    }
  }

  &__card-image {
    @include bg-cover();
    height: 140px;
  }

  &__card-content {
    padding: 0 rem(16px) rem(24px);
    font-family: $font-family-sans-serif;
  }

  &__card-header {
    text-align: center;
    padding: rem(16px);
  }

  &__card-timestamp {
    font-size: rem(22px);
    line-height: rem(26px);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  &__card-nav {
    @extend .icon;
    position: absolute;
    z-index: 10;
    top: calc(140px + #{rem(16px)});
    font-size: rem(20px);
    cursor: pointer;

    &--next {
      @extend .icon--arrow-right;
      right: rem(16px);
    }

    &--prev {
      @extend .icon--arrow-left;
      left: rem(16px);
    }

    &--disabled {
      opacity: 0.25;
      pointer-events: none;
    }
  }

  &__carousel {
    position: relative;
    text-align: center;

    @include bp-down(sm) {
      display: none;
    }
  }

  &__carousel-line {
    left: 50%;
    position: relative;
    top: 50%;
    transform: translateX(-50%) translateY(0);
    width: 200rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__circle-text {
    font-size: rem(24px);
    font-weight: $font-weight-medium;
    letter-spacing: rem(1px);
    line-height: 1;
    text-transform: uppercase;
  }
}
