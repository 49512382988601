.brand-blog-list-block {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 4rem;

  @include bp-down(md) {
    flex-direction: column;
    padding: 3rem 1rem;
  }

  a {
    width: 50%;

    @include bp-down(md) {
      width: 100%
    }
  }
}
