$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #f0f0f0 !default;
$gray-300: #eeeff1 !default;
$gray-600: #6e6f6f !default;
$gray-700: #636363 !default;
$gray-800: #4b4a47 !default;
$gray-900: #2b2b2b !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "600": $gray-700,
    "700": $gray-700,
    "800": $gray-700,
    "900": $gray-900
  ),
  $grays
);

$blue:            #085778 !default;
$red:             #b81c28 !default;
$gold:            #d3800d !default;
$yellow:          #fab825 !default;
$green:           #82bd60 !default;
$green-forest:    #00624c !default;

// blue variations
$blue-secondary:  #46738c !default;
$blue-third:      #6ea3ba !default;
$blue-third-alt:  #7eb7ca !default;
$blue-meta:       #788591 !default;
$blue-meta-alt:   #baccd3 !default;
$blue-border:     #dfe7ea !default;
$blue-gray:       #49525a !default;

$primary:           $blue !default;
$secondary:         $gold !default;
$success:           $green !default;
$danger:            $red !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":                 $blue,
    "blue-secondary":       $blue-secondary,
    "blue-third":           $blue-third,
    "blue-third-alt":       $blue-third-alt,
    "blue-meta":            $blue-meta,
    "blue-meta-alt":        $blue-meta-alt,
    "blue-border":          $blue-border,
    "blue-gray":            $blue-gray,
    "red":                  $red,
    "yellow":               $yellow,
    "green":                $green,
    "green-forest":         $green-forest,
    "gold":                 $gold,
    "white":                $white,
    "gray-border-light":    $gray-800,
    "gray-meta":            $gray-700,
    "gray-disabled-dark":   $gray-600,
    "gray-disabled":        $gray-300,
    "gray-bg-alt":          $gray-300,
    "gray-border":          $gray-200,
    "gray-bg":              $gray-100,
    "primary":              $primary,
    "secondary":            $secondary,
    "success":              $success,
    "danger":               $danger
  ),
  $colors
);
