.block-main-content {
  @include clearfix();
  max-width: rem(560px);
  order: 2;
  margin: 0 0 rem(80px) rem(380px);
  min-width: 500px;

  @include bp-down(sm) {
    order: 1;
    margin: 0 auto rem(40px);
    min-width: 0;
    max-width: rem(540px);
  }

  @include bp-up(xxl) {
    margin: 0 auto rem(80px);
    max-width: rem(740px);
  }

  .container & {
    @include bp-up(xxxl) {
      max-width: rem(540px);
    }
  }

  p {
    font-family: $font-family-serif;
    font-size: rem(18px);
    line-height: rem(26px);
  }

  > p:first-of-type {
    @extend .preamble;
  }

  img {
    height: auto;
  }

  &__image--left {
    float: left;
    max-width: rem(320px);
    height: auto;
    margin: 0 rem(30px) rem(10px) 0;

    @include bp-down(xs) {
      float: none;
      text-align: center;
      max-width: 100%;
      margin: rem(20px) 0;
    }
  }

  &__image--wide {
    height: auto;
    margin: rem(40px) -#{rem(80px)};
    width: calc(100% + #{rem(160px)});
    max-width: calc(100% + #{rem(160px)});

    @include bp-down(xs) {
      width: calc(100% + #{rem(40px)});
      max-width: calc(100% + #{rem(40px)});
      margin: rem(40px) -#{rem(20px)};

      figcaption {
        margin-left: rem(20px);
      }
    }

    @include bp-up(xxl) {
      width: rem(1000px);
      max-width: rem(1000px);
      margin: rem(40px) -#{rem(130px)};
    }
  }
}
