.brand-anniversary {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4rem 10rem;
  width: 100vw;

  @include bp-down(md) {
    padding: 4rem 2rem 2rem 2rem;
  }

  &-header {

    h1 {
      margin: 0 0 1rem 0;
      line-height: 110%;
      text-transform: uppercase;

      @include bp-down(md) {
        line-height: 100%;
        font-size: 42px;
      }
    }
  }

  &-text {
    max-width: 50rem;
  }

  &-image {
    margin: 2rem 0;
  }

  &-button {

    @include bp-down(md) {
      font-size: 17px;
    }
  }
}
