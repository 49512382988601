.contact-card {
  max-width: 38.75rem;
  margin: 0 auto;
  background: #fff;
  padding: 1.5rem;

  &__byline {
    @extend .byline;
    font-size: rem(13px);
    line-height: rem(18px);
    color: color("secondary");
  }

  &__name {
    @extend .byline;
    margin: .25rem 0 0;
  }

  &__job-title {
    @extend .byline;
    font-weight: 500;
    line-height: rem(18px);
    letter-spacing: 0;
    text-transform: none;
    color: $gray-700;
    margin: .25rem 0 0.5rem;
  }

  &__contact-info {
    margin-top: rem(12px);
    font-size: .875rem;
    line-height: 1.375rem;
    font-weight: 500;

    p {
      font-size: inherit;
      line-height: inherit;
      margin-bottom: 0.25rem;
    }

    a {
      display: block;
      margin: .25rem 0 0;

      i {
        width: 1.5rem;
        margin-right: .25rem;
        text-align: center;
      }
    }
  }
}

.block-main-content {
  .contact-card {
    margin: 1.75rem 0;
    max-width: 100%;
  }
}
