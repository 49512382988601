.tooltip {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  padding: rem(5px);
  background: gray("900");
  border-radius: 4px;
  color: $white;
  text-transform: none;
  text-align: center;

  .arrow {
    position: absolute;
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    border-top-color: gray("900");
    border-width: 8px;
  }
}

.tooltip--active {
  visibility: visible;
  display: block !important;
}
