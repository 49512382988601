.cart-content {
  &--empty {
    padding: rem(20px);
  }

  &__header {
    font-weight: $font-weight-bold;
    padding: rem(15px) 0;
    border-bottom: 1px solid color("blue-meta-alt");
    text-transform: uppercase;
  }

  &__item {
    @include clearfix();
    display: flex;
    align-items: center;
    min-height: rem(80px);
    position: relative;
    padding: rem(20px) rem(40px) rem(20px) calc(54px + #{rem(20px)});
    border-bottom: 1px solid color("gray-border");
    font-size: rem(14px);
    line-height: rem(20px);

    @include bp-down(xxxs) {
      padding-left: rem(20px);
    }

    &--promotion {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-bottom: rem(10px);
      border-bottom: 0;

      span {
        text-transform: uppercase;
        color: color("blue-meta-alt");
      }

      > div {
        position: relative;
        flex-basis: 0;
        flex-grow: 1;
        margin-right: rem(20px);
      }

      input {
        width: 100%;
      }

      @include bp-down(xs) {
        flex-direction: column;

        > * {
          margin-bottom: rem(10px);
        }

        > div {
          margin-right: 0;
          width: 100%;
        }
      }
    }

    &--promotion-meta {
      color: color("blue-third");
      padding-top: 0;
      padding-left: 0 !important;
      padding-right: rem(90px) !important;

      @include bp-down(xs) {
        padding-right: 0 !important;
      }
    }

    &.is-loading {
      opacity: 0.5;
      pointer-events: none;
    }

    &__discount {
      position: absolute;
      top: rem(20px);
      left: rem(10px);
      background: color("red");
      padding: 2px 4px 0;
      color: #fff;
      z-index: 1;
      font-size: rem(12px);

      @include bp-down(xxxs) {
        position: static;
        display: block;
        width: rem(40px);
        text-align: center;
        margin-bottom: rem(5px);
      }
    }

    &__image {
      position: absolute;
      left: rem(20px);
      width: 42px;

      img {
        display: block;
      }

      @include bp-down(xxxs) {
        display: none;
      }
    }

    &__name {
      width: calc(100% - #{rem(100px)});
      text-transform: uppercase;
      font-weight: $font-weight-bold;

      a {
        text-decoration: none;
        color: $body-color;

        &:hover {
          cursor: pointer;
          color: lighten($body-color, 20%);
        }
      }

      @include bp-down(xxs) {
        width: calc(100% - #{rem(100px)});
      }
    }

    &__name,
    &__label {
      float: left;
    }

    &__price {
      position: absolute;
      top: rem(20px);
      right: rem(20px);
      text-align: right;
      color: color("red");
      font-weight: $font-weight-medium;

      @include bp-down(sm) {
        top: rem(15px);
      }

      &__display {
        color: color("gray-meta");
      }

      &__total {
        font-weight: $font-weight-medium;
        text-transform: uppercase;
      }

      &--loading {
        .cart-content__item__price__total {
          opacity: 0.1;
          transition: opacity 0.22s ease-out;
        }
      }
    }

    &__label {
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    .cart-quantity {
      position: absolute;
      right: rem(20px);
      top: rem(37px);

      @include bp-down(sm) {
        top: rem(35px);
        right: rem(18px);
      }
    }
  }
}

.cart-content--border-top {
  .cart-content__item:first-child {
    border-top: 1px solid color("blue-meta-alt");
  }
}

.cart-content--alt {
  .cart-content__header {
    border-color: color("blue-secondary");
  }

  .cart-content__item {
    padding-left: 50px;
    padding-right: rem(20px);

    @include bp-down(xxxs) {
      padding-left: 0;
    }

    &,
    &:first-child {
      border-color: color("blue-secondary");
    }

    &__discount {
      left: -#{rem(15px)};
      top: rem(23px);
    }

    &__image {
      left: 0;
    }

    .cart-quantity {
      right: 0;
      color: color("blue-meta-alt");
    }

    &__price {
      &__display {
        color: color("blue-third");
      }

      &__total {
        color: $white;
      }
    }
  }
}
