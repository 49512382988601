.form-input,
input,
textarea {
  width: calc(100% - #{rem(135px)});
  background-color: $white;
  border: 1px solid color("blue-meta-alt");
  border-radius: 0;
  padding: 0 rem(12px);
  height: rem(40px);
  line-height: rem(40px);
  font-size: rem(14px);
  font-weight: $font-weight-medium;
  color: color("gray-meta");
  -webkit-appearance: none;

  @include bp-down(xs) {
    font-size: 16px;
  }

  &:focus {
    outline: none;
    border-color: $link-color;
  }

  &:disabled {
    opacity: 0.4;
  }

  &::placeholder {
    color: #bbb;
  }
}

select:disabled {
  opacity: 0.4;
}

textarea,
textarea.form-group__field {
  height: rem(120px);
  line-height: rem(20px);
  padding: rem(12px);
}

input[type="checkbox"],
input[type="radio"] {
  position: relative;
  top: -0.5px;
  margin-right: rem(7.5px);
  appearance: none;
  background-color: $white;
  border: 1px solid color("blue-meta-alt");
  border-radius: 3px;

  &:checked {
    &::after {
      position: absolute;
    }
  }

  &:active,
  &:focus {
    outline: none !important;
  }
}

input[type="checkbox"] {
  width: rem(18px);
  height: rem(18px);

  &:checked {
    background-color: color("primary");
    border-color: color("primary");

    &::after {
      content: "\f00c";
      top: -1px;
      left: 2px;
      line-height: 18px;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: 12px;
      color: $white;
    }
  }
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 50%;

  &:checked {
    &::after {
      content: "";
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: color("blue");
      left: 3px;
      top: 3px;
    }
  }

  &.radio--lg {
    width: 32px;
    height: 32px;

    &:checked::after {
      height: 22px;
      width: 22px;
      left: 4px;
      top: 4px;
    }
  }
}

.form-label {
  font-size: rem(14px);
  line-height: rem(19px);
  font-weight: $font-weight-medium;
  color: color("gray-meta");

  &--lg {
    font-weight: $font-weight-bold;
    font-size: rem(16px);
  }
}

// Icon

.form-icon {
  width: rem(40px);
  height: rem(40px);
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  color: color("primary");
  background-color: color("gray-bg");
  box-shadow: inset -1px 0 0 color("blue-meta-alt");
}

.form-input-icon {
  overflow: hidden;
  border: 1px solid color("blue-meta-alt");

  .form-input {
    border: 0;
    float: left;
    width: calc(100% - #{rem(40px)});
  }
}

.form-select {
  background-color: $white;
  border: 1px solid color("blue-meta-alt");
  border-radius: 0;
  position: relative;

  &::before {
    font-family: "swm-icons";
    content: "\f107";
    color: color("primary");
    font-size: rem(18px);
    display: block;
    position: absolute;
    right: rem(12px);
    top: rem(9px);
    pointer-events: none;
  }

  select {
    width: 100%;
    appearance: none;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    padding: 0 rem(28px) 0 rem(12px);
    height: rem(40px);
    line-height: rem(40px);
    font-size: rem(14px);
    font-weight: $font-weight-medium;
    color: color("gray-meta");

    @include bp-down(xs) {
      font-size: 16px;
    }

    &::-ms-expand {
      display: none;
    }
  }

  &--filled {
    background-color: color("primary");
    color: $white;
    border-color: color("primary");

    &::after {
      color: $white;
    }

    select {
      color: $white;
      background-color: color("primary");
    }
  }

  &--has-icon {
    overflow: hidden;

    input,
    select {
      float: left;
      width: calc(100% - #{rem(40px)});
      border: 0;
    }
  }
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  margin-bottom: rem(10px);
  padding-right: 1px;

  &__label {
    @extend .form-label;
    width: rem(135px);
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
  }

  &__field {
    @extend .form-input;
    width: calc(100% - #{rem(135px)});
  }

  &__field-label {
    display: block;
    font-size: rem(14px);
    margin-right: rem(15px);

    .form-group__label {
      display: inline-block;
      vertical-align: top;
      margin-top: 1px;
    }
  }

  &__select {
    @extend .form-select;
    width: calc(100% - #{rem(135px)});
  }

  &__error,
  .field-validation-error {
    position: static;
    margin-left: rem(135px);
    width: 100%;
    font-size: rem(12px);
    padding: rem(5px) rem(12px);
    line-height: rem(18px);
    color: $white;
    background: color("red");

    &--arrow {
      @include bp-up(xxl) {
        position: absolute;
        z-index: 1;
        width: rem(130px);
        right: -#{rem(140px)};
        padding: rem(10px);
        font-size: rem(14px);
        top: 50%;
        transform: translateY(-50%);

        &::before {
          right: 100%;
          top: 50%;
          border: 7px solid transparent;
          border-right-color: color("red");
          margin-top: -7px;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
      }
    }
  }

  &__meta {
    @include clearfix();
    margin-top: rem(5px);
    margin-left: rem(135px);
    width: calc(100% - #{rem(135px)});
    color: color("gray-meta");
    font-size: rem(14px);
    line-height: rem(18px);

    &__action {
      display: block;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__forgot-password {
    @extend .form-group__label;
    color: $link-color !important;
    width: auto !important;
    position: absolute;
    right: 0;
  }

  &__fields {
    width: calc(100% - #{rem(135px)});
  }

  &--stacked {
    .form-group__label {
      position: absolute;
      left: rem(12px);
      top: rem(12px);
      z-index: 1;
      font-size: $font-size-sm;
      pointer-events: none;
      color: color("blue-meta");
      width: calc(100% - #{rem(24px)});
    }

    .form-group__select,
    .form-group__field {
      width: 100%;
    }

    .form-group__field,
    .form-group__select select {
      padding-top: rem(20px);
      height: rem(60px);
    }

    textarea.form-group__field {
      padding-top: rem(32px);
      height: rem(120px);
    }

    .form-group__select::before {
      top: 20px;
    }

    .form-group__error,
    .field-validation-error {
      margin-left: 0;
    }

    &--floating {
      .form-group__field:enabled {
        + .form-group__label {
          font-size: $font-size-base;
          top: rem(22px);
          transition: all 0.18s linear;
        }

        &:focus,
        &:valid {
          + .form-group__label {
            top: rem(12px);
            font-size: $font-size-sm;
          }
        }
      }
    }
  }

  &--checkbox {
    .form-group__label {
      width: calc(100% - #{rem(30px)});
      color: $body-color;
      margin-top: 2px;
    }

    .form-group__field {
      width: rem(18px);
      margin-right: rem(10px);
    }

    &--aligned {
      .form-group__field {
        margin-left: rem(135px);
      }

      .form-group__label {
        width: calc(100% - #{rem(30px)} - #{rem(135px)});
      }
    }
  }

  &--required {
    > .form-group__label {
      &::after {
        content: '*';
        color: color("red");
      }
    }
  }

  &--captcha {
    .g-recaptcha {
      margin-left: rem(135px);
    }

    @include bp-down(xs) {
      justify-content: center;

      .g-recaptcha {
        margin-left: 0;
      }
    }
  }

  &--error {
    .form-group__field {
      border-color: color("red");
    }
  }

  &--lg {
    .form-group__label {
      @extend .form-label--lg;
    }
  }

  &--ssn {
    .form-group__field {
      width: calc(100% - #{rem(135px)} - #{rem(140px)});

      @include bp-down(xxs) {
        width: calc(100% - #{rem(135px)});
      }
    }

    &__btn {
      width: rem(140px);

      @include bp-down(xxs) {
        width: calc(100% - #{rem(135px)});
        margin-left: rem(135px);
      }
    }
  }

  @include bp-up(xxl) {
    &--postal-code {
      float: left;
      width: rem(220px);
      padding-right: rem(20px) !important;
    }

    &--postal-area {
      float: left;
      width: calc(100% - #{rem(220px)});

      .form-group__label {
        width: rem(60px);
      }

      .form-group__field {
        width: calc(100% - #{rem(60px)});
      }
    }
  }
}

.form-column {
  .feedback-item__question {
    width: 100%;
  }

  .form-group {
    flex-direction: column;

    .form-group__label {
      width: calc(100% - 31px);
    }

    > * {
      width: 100%;
    }
  }

  .feedback-item,
  .form-group {
    margin-bottom: rem(30px) !important;
  }

  .form-group > .form-group__label,
  .feedback-item__question {
    margin-bottom: rem(10px);
    color: $body-color;
    font-weight: $font-weight-bold;
    font-size: rem(16px);
  }
}

.form-inline {
  display: flex;
  justify-content: center;

  @include bp-down(sm) {
    display: block;
  }

  &__label {
    @extend .form-label;
    @extend .form-label--lg;
    margin-right: rem(20px);
    align-self: center;

    @include bp-down(sm) {
      display: block;
      margin-right: 0;
      margin-bottom: rem(10px);
    }
  }

  .form-group {
    width: rem(150px);
    margin-bottom: 0;
    margin-right: -1px;
  }

  &__field,
  .form-group:not(:last-child) {
    .form-group__select,
    .form-group__field {
      border-right-color: transparent;
    }
  }

  &__field {
    @extend .form-input;
    width: rem(150px);
    border-right-color: transparent;

    @include bp-down(sm) {
      display: block;
      width: 100%;
      margin-bottom: rem(10px);
      margin-right: 0;
    }
  }

  .btn {
    @include bp-down(sm) {
      display: block;
    }
  }
}

.form-wrapper {
  margin-top: rem(20px);

  &--hidden {
    display: none;
  }
}

.form-box {
  max-width: rem(460px);
  margin: rem(40px) auto;
  background-color: color("gray-bg");

  @include bp-down(xs) {
    margin: rem(20px);
  }

  .form-group:last-child {
    margin-bottom: 0;
  }

  &__submit {
    @extend .btn;
    @extend .btn--primary;
    @extend .btn--block;
    @extend .btn--lg;
    margin: rem(10px) 0 rem(5px);
  }

  &--bottom-space {
    margin-bottom: rem(80px);

    @include bp-down(sm) {
      margin-bottom: rem(40px);
    }

    @include bp-down(xs) {
      margin-bottom: rem(20px);
    }
  }
}

.form-meta {
  @extend .meta;
  font-size: rem(14px);
  line-height: rem(19px);
  letter-spacing: 0;
  text-align: center;

  @include bp-down(xs) {
    font-size: rem(11px);
    line-height: rem(16px);
  }
}

.EPiServerForms {
  .form-group {
    margin-bottom: rem(10px) !important;

    > .form-group__label {
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
      color: $body-color;
      margin-top: rem(16px);
      margin-bottom: rem(8px);
    }

    .form-group__fields,
    > .form-group__field,
    > .form-group__label {
      display: block;
      width: 100%;
      word-break: normal;
    }

    .form-group__field-label .form-group__label {
      width: auto;
    }
  }

  .form-box__submit {
    margin-top: rem(32px);
  }

  .Form__Description {
    @extend .preamble--sm;
  }

  .hide {
    display: none !important;
  }

  .Form__Warning__Message {
    background-color: color("danger") !important;
    color: $white !important;
  }

  .Form__Status__Message.Form__Success__Message {
    background: #82bd60 !important;
    color: #fff !important;
  }

  .FormSubmitButton {
    cursor: pointer;
  }

  .ValidationFail {
    @extend .form-group--error;
  }

  .Form__Success__Message {
    margin-top: rem(40px) !important;

    h3 {
      margin: 0;
      font-weight: $font-weight-medium;
      text-transform: none;
      font-size: rem(18px);
    }
  }

  .Form__Element__ValidationError {
    background: #b81c28 !important;
    color: #fff !important;
    margin-top: rem(10px);
    margin-left: 0;
    width: auto;
  }

  .Form__NavigationBar {
    margin-top: rem(40px);
    overflow: hidden;

    .Form__NavigationBar__ProgressBar {
      display: block !important;
      width: 100% !important;
      margin-bottom: rem(20px);
      border-color: color("blue-meta-alt") !important;
    }

    .Form__NavigationBar__ProgressBar--Progress {
      background-color: color("success") !important;
      height: rem(20px) !important;
    }

    .btnNext,
    .btnPrev {
      @extend .btn;
      @extend .btn--sm;
      padding: rem(10px) rem(13px) rem(8px) !important;
      max-width: inherit !important;
      border-radius: inherit !important;
      font-size: rem(14px) !important;
      color: #fff !important;
      background-color: color("blue");
      border-color: color("blue") !important;
      background-image: none !important;
      height: auto !important;
      width: auto !important;

      @include bp-down(xxs) {
        display: block;
        width: 100%;
        margin-bottom: rem(10px);
        float: none;
      }

      @include on-hover() {
        background-color: darken(color("primary"), 7%) !important;
        border-color: darken(color("primary"), 7%) !important;
      }
    }

    .btnNext {
      float: right;
      @extend .btn--primary;
    }

    .btnPrev {
      float: left;
      @extend .btn--silent;
    }
  }
}

.page-snuscoursepage {
  .ui-timepicker-wrapper {
    width: 204px;
  }
}

// Datepicker
.pika-single {
  .is-selected button {
    background: color("primary");
  }

  td:hover button {
    background: color("secondary");
  }
}


.block-main-content {
  form.EPiServerForms, form.form-box {
    max-width: 100%;
  }
}

form.EPiServerForms {
  .FormCaptcha .FormCaptcha__Refresh {
    width: 100%;
    margin-bottom: 10px;
    color: black;
    font-size: 12px;
  }

  .FormCaptcha img {
    width: 100% !important;
    height: auto !important;
  }

  .FormCaptcha .FormCaptcha__Input {
    width: 100% !important;

  }

    .Form__Element .Form__Element__Caption {
    font-size: rem(18px);
    font-weight: 700;
    display: block;
  }

  .Form__Title {
    font-size: rem(26px);
    text-transform: uppercase;
  }

  .Form__Element.ValidationRequired {
    > .form-group__label {
      &::after {
        content: '*';
        color: color("red");
      }
    }
  }

  .form-group__select {
    width: 100%;
  }

  .FormParagraphText {
    color: $blue-meta;
    margin-top: 1.5rem;

    a {
      text-decoration: underline;
    }

    p {
      font-size: rem(16px);
    }
  }
}
