.breadcrumb {
  padding: rem(17.5px) 0;

  @include bp-down(md) {
    display: none;
  }

  @include bp-down(sm) {
    padding-left: rem(20px);
  }

  &__item {
    font-size: rem(13px);
    font-weight: $font-weight-medium;
    color: color("blue-meta");
    text-transform: uppercase;
    text-decoration: underline;

    &:not(.breadcrumb__item--current)::after {
      content: '/';
      display: inline-block;
      margin: 0 rem(5px);
    }

    &--current {
      display: none;
      text-decoration: none;

      @include bp-up(xl) {
        display: inline;
      }
    }

    &--button {
      @extend .btn;
      @extend .btn--primary;
      @extend .btn--xs;
      margin-right: rem(12px);
      position: relative;
      top: -#{rem(1px)};
    }
  }
}
