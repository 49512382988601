﻿.block-storytelling {
  padding: rem(120px) rem(40px);
  @include clearfix();

  @include bp-down(sm) {
    padding: rem(80px) rem(40px);
  }

  @include bp-down(xs) {
    padding: rem(40px) rem(20px);
  }

  &__title {
    @extend .h1;
    margin: 0 rem(50px) rem(80px);
    text-align: center;

    @include bp-down(sm) {
      margin: 0 rem(50px) rem(40px);
    }
  }

  &__item {
    margin: 0 rem(40px) rem(40px);
    overflow: hidden;

    @include bp-down(sm) {
      margin: 0 rem(20px) rem(40px);
    }

    @include bp-down(xs) {
      margin: 0 rem(00px) rem(40px);
    }

    @include bp-up(lg) {
      width: rem(920px);
      margin: 0 auto rem(80px);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__image {
      width: 40%;
      float: left;
      margin-bottom: 0;

      @include bp-up(lg) {
        width: rem(460px);
      }

      figcaption {
        margin: rem(50px) rem(40px) 0 0;

        @include bp-down(sm) {
          margin: rem(50px) 0;
        }
      }

      &--overlay {
        img {
          opacity: 0.5;

          @include bp-down(sm) {
            opacity: 1;
          }
        }
      }
    }

    &__content {
      width: 60%;
      float: left;
      margin: rem(40px) 0 0 -#{rem(250px)};
      position: relative;
      @extend .preamble;

      @include bp-up(lg) {
        width: rem(460px);
        margin: rem(60px) 0 0 -#{rem(40px)};
      }

      @include bp-down(sm) {
        font-size: rem(180px);
        line-height: rem(260px);
        margin: 0;
        padding-left: rem(20px);
      }


      @include bp-down(xs) {
        font-size: rem(140px);
        line-height: rem(20px);
        padding-left: rem(20px);
      }
    }

    &:nth-child(odd) {
      .block-storytelling__item__image {
        float: right;

        figcaption {
          text-align: right;
          margin: rem(50px) 0 0 rem(40px);

          @include bp-down(sm) {
            margin: rem(50px) 0;
          }
        }
      }

      .block-storytelling__item__content {
        float: right;
        margin: rem(40px) -#{rem(250px)} 0 0;
        text-align: right;

        @include bp-up(lg) {
          width: rem(460px);
          margin: rem(60px) -#{rem(40px)} 0 0;
        }

        @include bp-down(sm) {
          margin: 0;
          padding-right: rem(20px);
          padding-left: 0;
        }

        @include bp-down(xs) {
          text-align: left;
        }
      }
    }
  }
}
