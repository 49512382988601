.site-footer {
  position: relative;
  background: $blue-gradient;
  padding-top: rem(40px);
  padding-bottom: rem(40px);

  &__content {
    display: flex;
    font-weight: $font-weight-medium;
    font-size: rem(13px);
    color: color("blue-third-alt");

    > div {
      flex-grow: 1;

      @include bp-only(sm) {
        width: 50%;
      }

      @include bp-down(sm) {
        margin-bottom: rem(40px);
      }

      @include bp-down(xs) {
        margin-bottom: rem(40px);
        text-align: center;
      }
    }

    @include bp-down(sm) {
      // padding: rem(40px) rem(20px);
      flex-wrap: wrap;
    }

    @include bp-down(xs) {
      flex-direction: column;
      padding-bottom: 0;
    }
  }

  a {
    color: color("blue-third-alt");
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: lighten(color("blue-third-alt"), 10%);
    }
  }

  &__details {
    @include bp-down(md) {
      display: none;
    }

    @include bp-down(xs) {
      text-align: center;
      display: block;
    }

    &__logo {
      display: block;
      width: rem(178px);
      height: rem(30px);
      margin: -#{rem(10px)} 0 0;
      background-image: url("#{$wdk-image-path}/swedishmatch_logo.png?w=182&burstcache=1");
      background-repeat: no-repeat;

      @include media-hidpi() {
        background-image: url("#{$wdk-image-path}/swedishmatch_logo@2x.png?w=364&burstcache=1");
        background-size: rem(178px) rem(28px);
      }

      @include bp-down(xs) {
        margin: 0 auto;
      }
    }

    &__logo-sp {
      display: block;
      width: rem(220px);
      height: rem(21px);

      @include bp-down(xs) {
        margin: 0 auto;
      }
    }

    &__contact {
      margin: rem(20px) 0;
    }

    &__icons {
      @include bp-down(xs) {
        display: inline-block;
      }

      &__holder {
        float: left;
        width: rem(100px);
      }

      &__item {
        color: $white;
        font-size: rem(28px);
        display: inline-block;
        margin-right: rem(5px);

        &--18 {
          @extend .icon;
          font-size: rem(82px);
          float: left;
          margin: rem(1.5px) rem(12.5px) 0 0;
        }

        &--visa {
          @extend .icon;
          @extend .icon--cc-visa;
        }

        &--mastercard {
          @extend .icon;
          @extend .icon--cc-mastercard;
        }

        &--klarna {
          width: rem(83.3px);
          margin: rem(5px) 0 0 0;
          backface-visibility: hidden;
        }
      }
    }
  }

  &__nav {
    max-width: rem(360px);
    margin: 0 auto;

    &__title {
      font-size: rem(18px);
      color: $white;
      margin: 0 0 rem(14px);
    }

    &__list {
      @include list-unstyled();

      &__item {
        margin: rem(4px) 0;
      }

      &--brands {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: rem(290px);

        @include bp-down(xs) {
          width: auto;
          margin: 0 auto;
        }

        li {
          display: inline-block;
          min-width: 50%;
          vertical-align: top;

          @include bp-down(xs) {
            width: 100%;
          }
        }
      }
    }
  }

  &__lang-switch {
    text-align: right;

    a:hover {
      text-decoration: none !important;

      > span:first-child {
        text-decoration: underline;
      }
    }

    .flag-icon {
      margin-left: rem(2px);
    }

    @include bp-down(sm) {
      order: -1;
      width: 100% !important;
      margin-bottom: rem(30px) !important;
    }

    @include bp-down(xs) {
      text-align: center;
    }

    &__list {
      @include list-unstyled();

      &__item {
        position: relative;
        margin: 0 rem(20px) rem(10px);
        text-align: right;
        opacity: 0;
        transform: translateY(-#{rem(5px)});
        transition: all 0.18s ease;

        @include bp-down(md) {
          margin-right: 0;
        }

        @include bp-down(sm) {
          text-align: left;
          display: inline-block;
          margin: 0 rem(20px) 0 0;
          padding-left: rem(35px);
          opacity: 1;
          transform: translateY(0);
        }

        @include bp-down(xs) {
          &:last-child {
            margin-right: 0;
          }
        }

        &--current {
          opacity: 1;
          transform: translateY(0);

          @include bp-down(sm) {
            a {
              color: $white;
            }
          }

          &::after {
            content: "\f107";
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            font-size: rem(18px);
            display: block;
            pointer-events: none;
            position: absolute;
            top: -#{rem(1px)};
            right: -#{rem(18px)};

            @include bp-down(sm) {
              display: none;
            }
          }
        }
      }

      &:hover {
        li {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &--inverted {
    background: $white;

    a,
    a:hover {
      color: gray("700");
    }

    .site-footer__content {
      color: gray("700");
    }

    .site-footer__nav__title {
      color: $black;
    }

    .site-footer__details__logo {
      width: rem(144px);
      height: rem(23px);
      background-image: url("#{$wdk-image-path}/swedishmatch_logo_corporate--sm.png?w=144&burstcache=1");
      background-size: rem(144px) rem(23px);

      @include bp-up(xl) {
        width: rem(182px);
        height: rem(29px);
        background-image: url("#{$wdk-image-path}/swedishmatch_logo_corporate.png?w=182&burstcache=1");
        background-size: rem(182px) rem(29px);
      }

      @include media-hidpi() {
        background-image: url("#{$wdk-image-path}/swedishmatch_logo_corporate@2x.png?w=364&burstcache=1");
      }
    }
  }
}
