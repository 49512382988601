.brand-list-slider {
  padding: rem(80px) 0;
  position: relative;
  overflow: hidden;
  z-index: 1;

  @include bp-down(xs) {
    padding: rem(40px) 0;
  }

  &__items {
    overflow: hidden;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    box-sizing: content-box;
    transform: translate3d(0px, 0, 0);
  }

  &__item {
    flex-shrink: 0;
    width: 12.5%;
    height: 100%;
    position: relative;

    @include bp-up(lg) {
      width: 25%;
    }

    @include bp-up(xl) {
      width: 20%;
    }

    @include bp-down(md) {
      width: 33.33333%;
    }

    @include bp-down(sm) {
      width: 50%;
    }

    @include bp-down(xs) {
      width: 100%;
    }
  }

  &__image {
    position: relative;
    background: color("gray-bg");
    margin: 0 rem(12px);

    img {
      position: relative;
      z-index: 10;
      display: block;
      opacity: 0.97;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
    }
  }

  &__content {
    margin-top: rem(16px);
    padding: 0 rem(12px);
    text-align: center;
  }

  &__byline {
    @extend .byline;
    margin-bottom: rem(2px);
    font-size: rem(12px);
    line-height: rem(18px);
    color: color("blue-meta");
  }

  &__title {
    @extend .byline;
    font-size: rem(14px);
    line-height: rem(20px);
  }

  &__next,
  &__prev {
    position: absolute;
    display: flex;
    align-items: center;
    outline: none;
    justify-content: center;
    top: 50%;
    width: rem(60px);
    height: rem(60px);
    font-size: rem(40px);
    color: $link-color;
    margin-top: -#{rem(30px)};
    z-index: 10;
    cursor: pointer;

    i {
      padding: rem(1px);
    }

    @include on-hover() {
      color: darken($link-color, 10%);
    }
  }

  &__next {
    right: rem(40px);

    @include bp-down(xs) {
      right: 0;
    }
  }

  &__prev {
    left: rem(40px);

    @include bp-down(xs) {
      left: 0;
    }
  }

  &__disabled-nav {
    color: lighten(color("gray-meta"), 45%);

    @include on-hover() {
      cursor: default;
      color: lighten(color("gray-meta"), 45%) !important;
    }
  }

  .container & {
    padding-left: rem(60px);
    padding-right: rem(60px);

    .brand-list-slider__next {
      right: 0;
    }

    .brand-list-slider__prev {
      left: 0;
    }
  }
}
