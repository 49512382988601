.fast-links {
  display: flex;
  align-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  padding: rem(80px) 0;
  margin: 0 rem(-20px) rem(-40px);

  @include bp-down(sm) {
    padding: rem(40px) 0;
    margin: 0 0 rem(-20px);
  }

  @include bp-down(sm) {
    justify-content: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(33.3333% - #{rem(40px)});
    background-color: $white;
    padding: rem(25px) rem(25px) rem(25px) rem(40px);
    position: relative;
    margin: 0 rem(20px) rem(40px);
    transition: box-shadow 1.2s cubic-bezier(0.19, 1, 0.22, 1), transform 0.21s cubic-bezier(0.19, 1, 0.22, 1);

    @include bp-only(md) {
      padding: rem(20px);
      width: calc(50% - #{rem(40px)});
    }

    @include bp-down(sm) {
      width: 100%;
      max-width: rem(540px);
      margin: 0 0 rem(20px);
      border-bottom: 2px solid color("gray-bg");
    }

    @include bp-down(xs) {
      padding: rem(20px);
      margin-bottom: rem(20px);
    }

    &__title {
      margin: 0 rem(60px) rem(8px) 0;
      color: color("secondary");
      word-break: break-word;

      a {
        color: color("secondary");
        text-decoration: none;
      }

      @include bp-only(md) {
        font-size: rem(23px);
      }
    }

    &__icon {
      position: absolute;
      top: rem(25px);
      right: rem(25px);
      width: rem(50px);
      height: rem(50px);
      text-align: right;

      img,
      svg {
        max-height: rem(32px);
      }

      @include bp-down(md) {
        top: rem(18px);
        right: rem(15px);
      }
    }

    &__list {
      @extend .list;
    }

    &__preamble {
      font-family: $font-family-sans-serif;
    }

    a.btn {
      margin-top: auto;
    }

    @include on-hover() {
      box-shadow: 0 14px 36px rgba(0, 0, 0, 0.08);
      transform: translate3d(0, -#{rem(4px)}, 0);
    }
  }
}
