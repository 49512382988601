.markets {
  padding-bottom: rem(80px);
  text-align: center;

  @include bp-down(xs) {
    padding-bottom: rem(40px);
  }

  img {
    display: block;
    margin: 0 auto space(5);
  }
}