.brand-header {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    height: 520px;
    width: 100%;

    @include bp-down(md) {
      height: 512px;
    }

    &-content {
      padding: 5rem 5rem 5rem 7rem;

      @include bp-down(md) {
        padding: 1rem 1rem;
        font-size: 32px;
      }

      &-header {
        text-transform: uppercase;

        h2 {
          display: flex;
          align-items: center;
          justify-content: center;

          &::before {
            content: "";
            display: block;
            width: 42px;
            height: 30px;
            margin-right: 1rem;
          }

          &::after {
            content: "";
            display: block;
            width: 42px;
            height: 30px;
            margin-left: 1rem;
          }

          p {
            font-size: 47px;
            font-weight: 700;
            line-height: 110%;

            @include bp-down(md) {
              font-size: 32px;
              max-width: 15rem;
            }
          }
        }
      }

      &-text {
        font-size: 19px;
        font-weight: 400;
        max-width: 50rem;
        margin: 0 auto;

        p {
          line-height: 135%;
        }
      }
    }
  }

  &-video {
    width: 100%;
    height: 520px;
    object-fit: cover;

    @include bp-down(md) {
      width: unset;
    }
  }

  &__blog {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    overflow: hidden;

    &:hover {

      img {
        transform: scale(1.25) rotate(2deg);
      }
    }

    &-image {
      overflow: hidden;

      img {
        transition: all .4s ease-in-out;
      }
    }

    &-header {

      h4 {
        padding-left: 1rem;
        margin: 0;

        p {
          font-weight: 700;
          font-size: 36px;
          line-height: 110%;
          color: #D5643C;
          text-transform: uppercase;

          @include bp-down(md) {
            font-size: 28px;
          }
        }
      }
    }

    &-text {
      padding-left: 1rem;
      margin: 0;

      p {
        font-weight: 400;
        font-size: 19px;
        line-height: 150%;
      }
    }
  }

  &__blog-start-page {
    margin: 1rem;
    overflow: hidden;

    @include bp-down(md) {
      padding: 0.5rem;
    }

    &-image {
    }

    &-btn {
      display: none;

      @include bp-down(md) {
        display: inline-block;
        font-weight: 700;
        font-size: 19px;
        line-height: 120%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        align-items: center;
        padding: 12px 40px;
        border: 1px solid #FFFFFF;
        border-radius: 48px;
        color: #ffffff !important;
        margin-bottom: 3rem;
      }
    }

    &-header {
      height: 450px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      text-align: center;
      background-size: cover;
      transition: all ease-in-out .4s;

      @include bp-down(md) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
      }

      &:hover {
        transform: scale(1.25) rotate(2deg);

        h3 {
          transform: scale(0.8) rotate(-2deg) translateY(-35px);
        }
      }

      h3 {
        padding: 0 1rem;
        margin: 0;
        transition: all .4s ease-in-out;

        @include bp-down(md) {
          padding: 0 1rem;
        }

        p {
          font-weight: 700;
          font-size: 47px;
          line-height: 110%;
          color: white;
          text-transform: uppercase;
          padding-bottom: 2rem;

          @include bp-down(md) {
            font-size: 38px;
          }
        }
      }
    }
  }
}
