.cart-quantity {
  display: flex;
  align-items: center;

  &__increase,
  &__decrease {
    font-family: 'Font Awesome 5 Pro';
    font-size: rem(18px);
    font-weight: 700;
    color: color("blue-meta");
    padding: 4px;
    cursor: pointer;

    @include bp-down(sm) {
      font-size: rem(26px);
      color: color("blue-meta");
    }
  }

  &__increase {
    padding-right: 0;
  }

  &__increase::after {
    content: '\f055';
  }

  &__decrease::after {
    content: '\f056';
  }

  &__nbr {
    font-weight: $font-weight-medium;
    color: color("gray-meta");
    line-height: rem(28px);
    margin: rem(2px) rem(4px) 0;
  }
}
