.order-history {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-left: -#{rem(20px)};
  margin-right: -#{rem(20px)};
  position: relative;
  z-index: 10;
  transform: translate3d(0, 0, 0);

  @include bp-down(sm) {
    margin-left: -#{rem(10px)};
    margin-right: -#{rem(10px)};
  }

  @include bp-down(xs) {
    margin-left: 0;
    margin-right: 0;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    width: calc(33.3333% - #{rem(40px)});
    margin: rem(20px);
    padding: rem(25px);
    background-color: $white;
    transition: box-shadow 1.2s cubic-bezier(0.19, 1, 0.22, 1), transform 0.21s cubic-bezier(0.19, 1, 0.22, 1);

    @include bp-down(sm) {
      width: calc(50% - #{rem(20px)});
      margin: rem(10px);
      border: 1px solid color("gray-border");
    }

    @include bp-down(xs) {
      width: 100%;
      margin: 0 0 rem(20px);
      padding: rem(15px);
    }

    @include bp-up(lg) {
      width: calc(33.3333% - #{rem(40px)});
    }

    @include bp-up(xl) {
      width: calc(25% - #{rem(40px)});
    }

    @include on-hover() {
      box-shadow: 0 14px 36px rgba(0, 0, 0, 0.08);
      transform: translateY(-#{rem(4px)});
    }

    &__field {
      width: 50%;
      font-size: rem(14px);
      line-height: rem(19px);
      margin: rem(4px) 0;

      .icon {
        color: color("blue-meta-alt");
        margin-right: rem(5px);
      }

      .icon[data-alt]:hover::after {
        content: attr(data-alt);
        position: absolute;
        z-index: 1;
        padding: rem(5px);
        background: gray("900");
        border-radius: 4px;
        font-weight: $font-weight-normal;
        font-size: rem(12px);
        color: $white;
        text-transform: none;
        text-align: center;
      }

      small {
        color: color("blue-meta");
        font-weight: $font-weight-medium !important;
      }

      a {
        position: static;
      }

      &:nth-child(even) {
        text-align: right;
        font-weight: $font-weight-bold;
      }

      &:first-child:not(a) {
        color: color("secondary");
      }

      &:nth-child(1),
      &:nth-child(2) {
        font-weight: $font-weight-bold;
        font-size: rem(16px);
        margin-bottom: rem(10px);
      }
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: rem(8.5px);

      &__button {
        @extend .btn;
        @extend .btn--silent;
        flex: 1 0 0;
        position: static;
        letter-spacing: 0;
        padding: rem(12px) rem(10px) rem(10px);

        @include bp-only(sm) {
          margin-top: -1px;
        }

        @include bp-up(xxl) {
          margin-left: -1px;
        }

        @include bp-down(sm) {
          padding: rem(16px) rem(16px) rem(14px);
        }
      }
    }

    &__details-wrapper {
      width: 100%;
      position: relative;
      z-index: 30;
    }

    &__details {
      display: none;
      position: absolute;
      z-index: 30;
      top: 0;
      left: -#{rem(25px)};
      right: -#{rem(25px)};
      padding: rem(20px) rem(25px) rem(25px);
      background-color: $white;
      box-shadow: 0 14px 36px rgba(0, 0, 0, 0.05);

      @include bp-down(xs) {
        position: static;
        box-shadow: none;
        padding: rem(20px) 0;
      }

      &--collapsed {
        display: block;
      }

      .order-history__item__field {

        &:nth-child(1),
        &:nth-child(2) {
          font-weight: $font-weight-normal;
          font-size: rem(14px);
          margin-bottom: rem(4px);
        }
      }
    }

    &__print {
      @extend .meta;
      text-align: right;
      display: block;
      font-size: 80%;
    }

    &__field-group {
      width: 100%;
      border-bottom: 1px solid color("gray-border");
      padding-bottom: rem(8.5px);
      margin-bottom: rem(8.5px);
      display: flex;
      flex-wrap: wrap;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    &__field-heading {
      font-weight: $font-weight-bold;
      font-size: rem(14px);
      color: color("secondary");
      text-transform: uppercase;
    }
  }

  &__helper {
    @extend .meta;
    margin: rem(15px) 0 0 rem(25px);
    width: 100%;

    @include bp-down(sm) {
      margin: 0;
      text-align: center;
    }
  }

  &__col {
    flex: 1 0 auto;

    @include bp-down(md) {
      flex: 1 100%;
      padding-bottom: rem(40px);
      max-width: rem(480px);
      margin: 0 auto;
    }

    @include bp-down(xs) {
      padding-bottom: rem(20px);
    }
  }

  &--subscriptions {
    &__helper {
      @extend .meta;
      margin: rem(15px);
      width: 100%;

      @include bp-down(sm) {
        margin: 0;
        text-align: center;
      }
    }

    .order-history__col {
      display: flex;
      flex-wrap: wrap;
      flex: 0 1 50%;
    }

    .my-pages__content__heading {
      width: 100%;
      padding: rem(25px) 0 0 rem(25px);
      margin-bottom: 0;

      @include bp-down(sm) {
        padding: 0;
        margin-bottom: rem(10px);
        text-align: center;
      }
    }

    .order-history__item {
      width: 100%;

      @include bp-up(xxl) {
        width: calc(50% - #{rem(40px)});
      }
    }

    .order-history__item__field-group {
      border: 0;
      margin: 0;
      padding: 0;
    }

    .order-history__item__heading {
      font-weight: $font-weight-bold;
      font-size: rem(16px);
      margin-bottom: rem(10px);
      color: color("secondary");
    }

    .order-history__item__field:nth-child(1),
    .order-history__item__field:nth-child(2) {
      font-size: rem(14px);
      color: $body-color;
      font-weight: $font-weight-normal;
      margin-bottom: 0;
    }

    .form-group {
      padding-right: 0;
    }

    .form-button {
      @extend .btn;
      @extend .btn--primary;
      @extend .btn--block;
      margin-top: rem(25px);

      &--cancel {
        color: color("gray-meta");
        width: 100%;
        text-align: center;
        font-size: rem(13px);
        display: inline-block;
        margin-top: rem(10px);
      }
    }
  }
}
