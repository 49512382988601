.tabs {
  display: flex;
  flex-wrap: wrap;
  background-color: color("gray-bg");
  font-size: rem(14px);
  line-height: rem(20px);

  @include bp-down(xxs) {
    margin: 0 -20px -20px -20px;
  }

  &__title {
    color: color("secondary");
    margin: 0 0 rem(15px);
  }

  &__byline {
    margin: -#{rem(12px)} 0 rem(5px);
  }

  &__label {
    flex-grow: 1;
    background-color: $white;
    border: 1px solid color("gray-border");
    font-weight: $font-weight-medium;
    color: $link-color;
    padding: rem(15px) rem(5px);
    cursor: pointer;
    z-index: 1;
    margin-left: -1px;
    text-align: center;
    position: relative;

    @include bp-down(xxs) {
      font-size: rem(12px);
    }

    @include bp-down(xxxs) {
      font-size: rem(11px);
    }

    &:first-of-type {
      margin-left: 0;
    }

    &__badge {
      display: inline-block;
      font-style: normal;
      background-color: color("blue-meta-alt");
      border-radius: 100%;
      position: relative;
      top: -#{rem(1px)};
      margin-left: rem(2px);
      color: $link-color;
      font-weight: $font-weight-bold;
      font-size: rem(11px);
      text-align: center;
      padding: rem(1px);
      width: rem(18px);
      height: rem(18px);
      line-height: rem(18px);

      @include bp-down(xxs) {
        font-size: rem(10px);
      }
    }
  }

  &__content {
    width: 100%;
    flex-basis: auto;
    margin-top: -1px;
    padding: rem(20px);
    border: 1px solid color("gray-border");
    color: color("blue-meta");
    order: 1;

    @include bp-down(xxs) {
      padding: rem(15px);
    }
  }

  &__content,
  &__radio {
    display: none;
  }

  &__radio:checked + .tabs__label,
  .tabs__label[selected] {
    background: color("gray-bg");
    border-bottom: 1px solid color("gray-bg");
    color: color("blue-meta");
    outline: none;
  }

  &__radio:checked + .tabs__label + .tabs__content,
  .tabs__label[selected] + .tabs__content {
    display: block;
  }
}
