.block-promo {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;
  color: $white;

  @include on-hover() {
    color: $white;
  }

  &__image {
    width: 100%;
    display: block;
  }

  &__content {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: rem(40px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @include bp-down(sm) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include bp-down(xs) {
      padding: rem(20px);
    }


    &__tagline {
      @extend .byline;
      display: none;
      margin-bottom: rem(30px);

      @include bp-up(xl) {
        display: block;
      }
    }

    &__title {
      font-size: rem(36px);
      line-height: rem(40px);
      text-transform: none;
      margin: 0 0 rem(8.5px);
      max-width: 100%;

      @include bp-down(xs) {
        margin: 0 0 rem(10px);
      }

      @include bp-up(xl) {
        font-size: rem(56px);
        line-height: rem(54px);
      }
    }

    &__preamble {
      @extend .preamble;
      margin: 0;
      max-width: rem(720px);

      p {
        margin: 0;
      }

      @include bp-down(md) {
        display: none;
      }

      @include bp-only(lg) {
        font-size: rem(18px);
        line-height: rem(26px);
      }
    }
  }

  &__cta {
    @include clearfix();
    height: rem(60px);
    margin-top: rem(30px);
    display: inline-block;
    box-shadow: 0 10px 15px rgba(black, 0.25);
    transition: all 1s cubic-bezier(0.77, 0, 0.175, 1);

    @include bp-down(sm) {
      margin-top: rem(15px);
      height: rem(44px);
    }

    @include bp-down(xs) {
      height: rem(40px);
    }
  }

  &__cta-price {
    @extend .h2;
    float: left;
    height: 100%;
    background-color: color("gray-bg-alt");
    padding: 0 rem(5px) 0 rem(10px);
    color: color("red");
    margin: 0;
    line-height: rem(65px);
    letter-spacing: - rem(2px);

    @include bp-down(sm) {
      line-height: rem(56px);
    }

    @include bp-down(xs) {
      display: none;
    }

    span {
      font-size: rem(12px);
      position: relative;
      text-transform: uppercase;
      top: - rem(17px);
      left: - rem(5px);
      letter-spacing: 0;
      margin-left: rem(3px);

      @include bp-down(sm) {
        top: - rem(10px);
        left: - rem(2px);
      }
    }
  }

  &__cta-btn {
    @extend .btn;
    @extend .btn--inverted;
    height: 100%;
    line-height: rem(40px) !important;

    @include bp-down(sm) {
      line-height: rem(24px) !important;
    }

    @include bp-down(xs) {
      line-height: rem(20px) !important;
    }
  }

  &__btn {
    @extend .btn;
    @extend .btn--inverted;
    box-shadow: 0 10px 15px rgba(black, 0.25);
  }

  &--content-right {
    justify-content: flex-end;

    .block-promo__content {
      padding-left: 50%;

      @include bp-down(xs) {
        padding: rem(30px) rem(20px);
        justify-content: space-between;
        align-items: center;
        text-align: center;
      }
    }
  }

  &--content-left {
    justify-content: flex-start;

    .block-promo__content {
      padding-right: 50%;

      @include bp-down(xs) {
        padding: rem(30px) rem(20px);
        justify-content: space-between;
        align-items: center;
        text-align: center;
      }
    }
  }

  &--content-center {
    justify-content: center;
    align-items: center;
    text-align: center;

    .block-promo__content {
      align-items: center;

      @include bp-down(xs) {
        padding: rem(30px) rem(20px);
        justify-content: space-between;
        align-items: center;
        text-align: center;
      }
    }
  }

  &--light {
    color: $body-color;

    .block-promo__btn,
    .block-promo__cta-btn {
      background-color: color("primary");
      border-color: color("primary");
      color: $white;

      @include on-hover() {
        color: $white;
        background-color: darken(color("primary"), 7%);
        border-color: darken(color("primary"), 7%);
      }
    }

    .block-promo__cta-price {
      color: $white;
      background-color: darken(color("primary"), 7%);
    }

    .block-promo__cta-btn {
      @include on-hover() {
        background-color: color("primary");
        border-color: color("primary");
        color: rgba($white, 0.75);
      }
    }

    @include on-hover() {
      color: $body-color;
    }
  }
}
