.btn,
button,
input[type=submit],
input[type=button],
input[type=reset] {
  color: $white;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  line-height: rem(20px);
  letter-spacing: rem(0.85px);
  display: inline-block;
  transition: color 0.18s ease-in-out, background-color 0.18s ease-in-out, border-color 0.18s ease-in-out;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: pointer;
  padding: rem(10px) rem(20px) rem(8px);
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  touch-action: manipulation;
  user-select: none;

  &.is-spinning {
    position: relative;
    pointer-events: none;

    i,
    span {
      visibility: hidden;
    }
  }

  &,
  &:focus,
  &.focus,
  &:active,
  &.active {
    outline: 0;
  }

  &:active {
    outline: 0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.25);
    background-image: none;
  }

  &.disabled,
  &:disabled,
  fieldset[disabled] & {
    opacity: 0.17;
    box-shadow: none;
    cursor: default;
    pointer-events: none;
  }
}

.btn--primary {
  color: $white;
  background-color: color("primary");
  border-color: color("primary");

  &:hover {
    color: $white;
    background-color: darken(color("primary"), 7%);
    border-color: darken(color("primary"), 7%);
  }
}

.btn--secondary {
  color: $white;
  background-color: color("blue-third");
  border-color: color("blue-third");

  &:hover {
    color: $white;
    background-color: darken(color("blue-third"), 10%);
    border-color: darken(color("blue-third"), 10%);
  }
}

.btn--silent {
  color: color("blue");
  background-color: transparent;
  border-color: color("blue-meta-alt");

  &:hover {
    color: $white;
    background-color: color("primary");
    border-color: color("primary");
  }
}

.btn--inverted {
  color: $link-color;
  background-color: $white;
  border-color: $white;

  &:hover {
    color: rgba($link-color, 0.75);
    background-color: $white;
    border-color: $white;
  }
}

.btn--inverted-alt {
  color: $white;
  background-color: transparent;
  border-color: $white;
  border-width: 2px;

  &:hover {
    color: $link-color;
    background-color: $white;
    border-color: $white;
  }
}

.btn--link {
  padding: 0;
  border-radius: 0;
  font-size: rem(13px);
  font-weight: $font-weight-medium;
  line-height: rem(13px);
  letter-spacing: 0;
  border: 0;
  border-bottom: 1px solid lighten(color("blue-meta"), 20%);
  color: color("blue-meta");

  &:hover {
    color: darken(color("blue-meta"), 20%);
    background: none;
    border-color: lighten(color("blue-meta"), 20%);
  }

  &,
  &:active,
  &.active,
  &:disabled,
  fieldset[disabled] & {
    box-shadow: none;
    background-color: transparent;
  }

  &:disabled,
  fieldset[disabled] & {
    text-decoration: none;
    color: $link-disabled-color;
  }
}

.btn--link--white {
  color: #fff;
  border-color: #fff;

  &:hover {
    color: darken(#fff, 20%);
  }
}

.btn--icon {
  i {
    margin-right: 3px;
  }

  &.btn--primary {
    i {
      color: color("blue-third");
    }
  }
}

.btn--input {
  text-transform: none;
  letter-spacing: normal;
  background: color("blue-meta-alt");
  color: color("blue");
  padding-left: rem(10px);
  padding-right: rem(10px);
  height: rem(40px);
}

.btn--pricing {
  @include clearfix();
  height: rem(60px);
  display: inline-block;
  transition: all 1s cubic-bezier(0.77, 0, 0.175, 1);

  @include bp-down(sm) {
    margin-top: rem(15px);
    height: rem(52px);
  }

  &__price {
    @extend .h2;
    float: left;
    height: 100%;
    background-color: darken(color("primary"), 7%);
    padding: 0 rem(5px) 0 rem(10px);
    color: $white;
    margin: 0;
    line-height: rem(65px);
    letter-spacing: -#{rem(2px)};

    @include bp-down(sm) {
      line-height: rem(56px);
    }

    span {
      font-size: rem(12px);
      position: relative;
      text-transform: uppercase;
      top: -#{rem(17px)};
      left: -#{rem(5px)};
      letter-spacing: 0;
      margin-left: rem(5px);

      @include bp-down(sm) {
        top: -#{rem(10px)};
        left: -#{rem(2px)};
      }
    }
  }

  &__btn {
    @extend .btn;
    @extend .btn--primary;
    height: 100%;
    line-height: rem(40px);

    @include bp-down(sm) {
      line-height: rem(24px);
    }

    @include bp-down(xs) {
      padding: rem(14px) rem(20px) rem(12px);
    }
  }
}

.btn--lg {
  font-size: rem(16px);
  line-height: rem(16px);
  padding: rem(16px) rem(20px) rem(14px);
}

.btn--sm {
  padding: rem(10px) rem(13px) rem(8px);

  @include bp-down(xxs) {
    padding: rem(8px) rem(14px) rem(6px);
  }
}

.btn--xs {
  font-size: rem(13px);
  padding: rem(12px) rem(8px) rem(9px);
  line-height: 0;
}

.btn--block {
  display: block;
  width: 100%;
}

// Future-proof disabling of clicks on `<a>` elements

a.btn.disaabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

// Specificity overrides

input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
