@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include bp-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-container($padding: true) {
  @include make-container-max-widths();
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @if $padding {
    padding-right: $container-padding;
    padding-left: $container-padding;

    @include bp-down(sm) {
      padding-right: calc($container-padding / 2);
      padding-left: calc($container-padding / 2);
    }
  }
}

.container {
  @include make-container();
}
