.video {
  width: 100%;
  position: relative;
  &__player {
    width: 100%;
  }

  &__duration,
  &__play {
    display: flex;
    position: absolute;
    bottom: rem(15px);
    width: 20%;
    height: 20%;
    background: $primary;
    opacity: 0;
    border-radius: rem(3px);
    align-items: center;
    justify-content: center;
    color: $white;
    transition: opacity ease-in 0.2s;
  }

  &__play {
    left: rem(10px);
    cursor: pointer;
    &::before {
      content: '\f04b';
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
      font-style: normal;
      text-decoration: inherit;
      color: $white;
    }

    &--paused {
      &::before {
        content: '\f04c';
      }
    }
  }

  &__duration {
    right: rem(10px);
    width: 15%;
    height: 15%;
    font-size: rem(12px);
  }

  &--initial .video__play,
  &--paused .video__play,
  &:hover .video__play,
  &--initial .video__duration,
  &--paused .video__duration,
  &:hover .video__duration {
    opacity: 0.95;
  }
}
