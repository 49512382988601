.product-row {
  @include clearfix();
  overflow: hidden;
  position: relative;
  width: 100%;

  .product-spot {
    float: left;
    flex-shrink: 0;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    box-sizing: content-box;
    transform: translate3d(0px, 0, 0);
    background: color("gray-bg");
  }

  &__next,
  &__prev {
    position: absolute;
    top: 50%;
    margin-top: - rem(80px);
    z-index: 10;
    cursor: pointer;
    width: rem(70px);
    height: rem(140px);
    opacity: 0.25;
    transition: all linear 0.15s 0.45s;
    background: gray("900");
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: rem(52px);
      font-style: normal;
      color: $white;
      position: relative;
    }

    i {
      padding: rem(1px);
    }

    @include bp-down(xs) {
      width: rem(40px);
      height: rem(70px);
      font-size: rem(30px);
      text-align: center;
      margin-top: - rem(30px);

      &::before {
        font-size: rem(36px);
      }
    }

    @include on-hover() {
      transition-delay: 0s;
    }
  }

  &__next {
    right: 0;
    border-top-left-radius: 900px;
    border-bottom-left-radius: 900px;

    &::before {
      content: '\f105';
      right: - rem(10px);

      @include bp-down(xs) {
        right: -rem(5px);
      }
    }

    @include bp-up(xl) {
      right: -86px;
    }

    @include on-hover() {
      &:not(.product-row__disabled-nav) {
        right: 0 !important;
      }
    }
  }

  &__prev {
    left: 0;
    border-top-right-radius: 900px;
    border-bottom-right-radius: 900px;

    &::before {
      content: '\f104';
      left: - rem(10px);

      @include bp-down(xs) {
        left: -rem(5px);
      }
    }

    @include bp-up(xl) {
      left: -86px;
    }

    @include on-hover() {
      &:not(.product-row__disabled-nav) {
        left: 0 !important;
      }
    }
  }

  @include on-hover() {

    @include bp-up(xl) {

      .product-row__next:not(.product-row__disabled-nav) {
        right: 0;
        transition-delay: 0s;
      }

      .product-row__prev:not(.product-row__disabled-nav) {
        left: 0;
        transition-delay: 0s;
      }
    }
  }

  &__disabled-nav {
    &.product-row__prev {
      left: -86px !important;
    }

    &.product-row__next {
      right: -86px !important;
    }
  }
}
