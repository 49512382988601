.brand-collapsable-block {

  &-container {
    max-width: 62.5rem;
    padding: 0 2.5rem;
    margin: 0 auto;
  }
}

.brand-collapse {
  &__anchor {
    position: relative;
    cursor: pointer;
    margin: 0;
    font-size: rem(23px);
    font-weight: $font-weight-medium;
    line-height: rem(32px);
    text-transform: none;
    letter-spacing: normal;
    padding-right: 30px;
    padding-bottom: 2rem;

    @include bp-down(xs) {
      font-size: rem(18px);
      line-height: rem(26px);
    }

    &::after {
      font-family: "swm-icons";
      content: "\e930";
      position: absolute;
      top: calc(50% - 13px);
      right: 0;
      transform: rotate(270deg);
      transition: transform 0.22s linear;
    }
  }

  &__content {
    display: none;
    padding-top: rem(10px);
  }

  &--active {
    .brand-collapse__anchor::after {
      transform: rotate(90deg);
    }
  }
}
