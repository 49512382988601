.news-list {
  margin-bottom: rem(80px);

  @include bp-down(md) {
    margin-bottom: rem(40px);
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    max-width: rem(980px);
    margin: 0 auto rem(40px);
    color: $body-color;
    text-decoration: none;
    background-color: $white;
    position: relative;
    transition: box-shadow 1.2s cubic-bezier(0.19, 1, 0.22, 1), transform 0.21s cubic-bezier(0.19, 1, 0.22, 1);
    backface-visibility: hidden;

    &__image-wrapper {
      width: 33.333%;
      min-height: rem(320px);
      position: relative;
      overflow: hidden;
      background-color: darken(color("gray-bg"), 3%);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.14s ease-in;

      &::before {
        font-family: "swm-icons";
        content: "\e937";
        font-size: rem(80px);
        color: darken(color("gray-bg"), 10%);
      }

      @include bp-down(xs) {
        width: 100%;
        min-height: rem(240px);
      }
    }

    &__image {
      @include bg-cover;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
    }

    &__content {
      width: 66.666%;
      padding: rem(40px);
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include bp-down(xs) {
        width: 100%;
      }

      @include bp-down(xs) {
        padding: rem(20px);
      }

      &__date {
        @extend .meta;
        display: block;
        position: absolute;
        top: rem(25px);
        text-transform: uppercase;

        @include bp-down(sm) {
          position: static;
          margin-bottom: rem(25px);
        }

        @include bp-down(xs) {
          font-size: rem(12px);
          margin-bottom: rem(16px);
        }
      }

      &__title {
        @extend .h3;
        margin: 0;
      }

      &__preamble {
        @extend .preamble--sm;
        margin: rem(10px) 0 0;
      }
    }

    @include on-hover() {
      box-shadow: 0 24px 36px rgba(0, 0, 0, 0.08);
      transform: translate3d(0, -#{rem(4px)}, 0);

      .news-list__item__image-wrapper {
        background-color: color("gray-bg");
      }

      .news-list__item__image {
        transform: scale3d(1.1, 1.1, 1);
      }
    }
  }

  &--horizontal {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(40px);
    margin-bottom: 0;
    margin-left: - rem(20px);
    margin-right: - rem(20px);
    width: calc(100% + #{rem(40px)});

    @include bp-down(sm) {
      margin-left: - rem(10px);
      margin-right: - rem(10px);
      width: calc(100% + #{rem(20px)});
    }

    @include bp-down(xs) {
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }

    .news-list__item {
      flex: 1 1 auto;
      max-width: calc(33.333% - #{rem(40px)});
      margin: 0 rem(20px) rem(40px);
      flex-direction: column;
      border: 1px solid color("gray-border");

      @include bp-down(sm) {
        max-width: calc(50% - #{rem(20px)});
      }

      @include bp-down(xs) {
        max-width: 100%;
      }

      &__image-wrapper {
        width: 100%;
        min-height: 0;
        height: rem(160px);
      }

      &__content {
        width: 100%;
        padding: rem(20px);

        &__preamble {
          font-size: $font-size-base;
          line-height: $line-height-base;
        }
      }
    }

    .news-list__item__content__date {
      position: static;
      margin-bottom: rem(4px);
    }
  }
}

.news-list-show-all {
  margin: 0 auto rem(80px);
  text-align: center;
}
