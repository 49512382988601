.cta-sidebar {
  width: rem(420px);
  box-shadow: $box-shadow-lg;

  @include bp-only(lg) {
    width: rem(360px);
  }

  &__image {
    @include bg-cover();
    height: rem(200px);
  }

  &__content {
    padding: rem(16px) rem(20px);

    &:not(:last-of-type) {
      border-bottom: 1px solid gray("200");
      padding-bottom: rem(16px);
      margin-bottom: rem(8px);
    }

    [href] {
      text-decoration: underline;
      font-weight: $font-weight-bold;
    }

    .list {
      font-size: $font-size-sm;
    }
  }
}
