.blog-sidebar {
  padding: rem(40px) rem(24px) 0;
  background: color("gray-bg");
  font-size: $font-size-sm;

  &__section {
    margin-bottom: rem(40px);
  }

  &__header {
    text-transform: uppercase;
    color: color("secondary");
    letter-spacing: 1px;
    font-weight: $font-weight-bold;
    margin-bottom: rem(20px);
  }

  .list li:not(:last-child) {
    margin-bottom: rem(12px);
  }
}
