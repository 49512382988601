.cookie-disclaimer {
  background-color: color("secondary");
  position: fixed;
  z-index: $z-index-cookie-disclaimer;
  bottom: 0;
  padding: rem(15px);
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.4);
  width: 100%;
  display: flex;
  align-items: center;

  @include bp-down(xs) {
    flex-direction: column;
    justify-content: center;
  }

  &__text {
    color: $white;
    font-weight: $font-weight-medium;
    font-size: rem(13px);
    line-height: rem(18px);

    a {
      color: $white;
    }

    p {
      margin: 0 rem(20px) 0 0;

      @include bp-down(xs) {
        margin: 0 0 rem(15px);
        text-align: center;
      }
    }
  }

  &__button {
    @extend .btn;
    @extend .btn--sm;
    @extend .btn--inverted;
    color: color("secondary");
    margin-left: auto;
    min-width: rem(160px);

    @include bp-down(xs) {
      margin: 0;
    }
  }
}
