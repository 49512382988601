@mixin fill-bg() {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    background: inherit;
    width: $container-padding;

    @include bp-up(xxl) {
      width: calc(((100vw - #{map-get($container-max-widths, xxl)}) / 2) + 2.5rem);
    }

    @include bp-down(md) {
      display: none;
    }
  }
}

@mixin fill-bg-left() {
  @include fill-bg();

  &::after {
    right: 100%;
  }
}

@mixin fill-bg-right() {
  @include fill-bg();

  &::after {
    left: 100%;
  }
}
