﻿.block-qa {
  padding: rem(120px) rem(40px);

  @include bp-down(sm) {
    padding: rem(80px) rem(40px);
  }

  @include bp-down(xs) {
    padding: rem(40px) rem(20px);
  }

  &__item {
    display: flex;
    max-width: rem(1000px);
    margin: 0 auto rem(40px);

    @include bp-down(xs) {
      margin-bottom: rem(20px);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      @extend .h3;
      font-weight: $font-weight-medium;
      text-transform: none;
      margin: 0;
      padding-right: rem(40px);
      width: 40%;

      @include bp-down(xs) {
        font-size: rem(18px);
        line-height: rem(24px);
        padding-right: rem(20px);
      }
    }

    &__content {
      @extend .preamble--sm;
      width: 60%;
      margin: rem(3.5px) 0 rem(20px);

      @include bp-down(xs) {
        line-height: rem(20px);
        font-size: rem(14px);
      }
    }
  }
}
