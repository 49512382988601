// .brand-logos-amp {
//     .amp-carousel-slide {
//         display:flex;
//         align-items: center;
//         justify-content: space-around;
//         padding: rem(20px) rem(60px);
//     }
// }

.brand-logos {
  padding: rem(40px) rem(100px);
  position: relative;
  overflow: hidden;
  z-index: 1;
  // background-color: $white;
  // border: 1px solid color("gray-border");
  // border-width: 1px 0;

  @include bp-down(xs) {
    padding: rem(20px) rem(60px);
  }

  &__items {
    overflow: hidden;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    box-sizing: content-box;
    transform: translate3d(0px, 0, 0);

    // &--amp {
    //     padding: rem(20px) rem(60px);
    // }
  }

  &__item {
    flex-shrink: 0;
    width: 12.5%;
    height: 100%;
    position: relative;
    transition: opacity 0.18s linear;

    @include bp-up(lg) {
      width: 20%;
    }

    @include bp-up(xl) {
      width: 16.6666%;
    }

    @include bp-up(xxl) {
      width: 12.5%;
    }

    @include bp-down(md) {
      width: 25%;
    }

    @include bp-down(sm) {
      width: 33.33333%;
    }

    @include bp-down(xs) {
      width: 50%;
    }

    @include on-hover() {
      opacity: 0.5;
    }

    &__image {
      display: block;
      margin: 0 auto;
      padding: rem(25px);
      max-height: rem(90px);
      width: 100%;

      @include bp-down(xs) {
        padding: rem(10px);
      }
    }
  }

  &__next,
  &__prev {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    width: rem(60px);
    height: rem(60px);
    font-size: rem(40px);
    color: $link-color;
    margin-top: -#{rem(30px)};
    z-index: 10;
    cursor: pointer;

    i {
      padding: rem(1px);
    }

    @include on-hover() {
      color: darken($link-color, 10%);
    }
  }

  &__next {
    right: rem(40px);

    @include bp-down(xs) {
      right: 0;
    }
  }

  &__prev {
    left: rem(40px);

    @include bp-down(xs) {
      left: 0;
    }
  }

  &__disabled-nav {
    color: lighten(color("gray-meta"), 45%);

    @include on-hover() {
      cursor: default;
      color: lighten(color("gray-meta"), 45%) !important;
    }
  }

  .container & {
    padding-left: rem(60px);
    padding-right: rem(60px);

    .brand-logos__next {
      right: 0;
    }

    .brand-logos__prev {
      left: 0;
    }
  }
}

// .page-brandpage--amp {
//     .brand-logos {
//         padding: 0;
//     }
// }
