.search-page {
  padding: 5rem 2rem 0 2rem;

  .btn {
    display: inline-block;
    font-weight: 700;
    font-size: 21px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    align-items: center;
    padding: 12px 40px;
    border-radius: 48px;
    color: #ffffff !important;
    background: #d5643c;
    margin-top: 2rem;
    width: 20%;
  }

  p {
    margin-left: 2rem;
  }

  form {
    text-align: center;
  }
}
