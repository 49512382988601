.brand-timeline {
  display: flex;
  padding: 8rem 10rem 5rem 5rem;

  @include bp-down(md) {
    flex-direction: column;
    padding: 3rem 2rem;
  }

  &__circle-wrapper {
    width: 20%;
    margin-top: 3rem;

    @include bp-down(md) {
      display: none;
    }
  }

  &__circle {
    height: 80px;
    width: 80px;
    min-width: 80px;
    border-radius: 50%;
    display: inline-block;
    border: 10px solid #F3DECD;

    @include bp-down(md) {
      display: none;
    }
  }

  &__header {
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 110%;
    text-transform: uppercase;

    h1 {
      margin-top: 4rem;

      @include bp-down(md) {
        text-align: center;
      }
    }
  }

  &__text {
    font-weight: 400;
    font-size: 19px;
    line-height: 135%;
    margin-left: 2rem;
    width: 40%;

    @include bp-down(md) {
      width: 100%;
      text-align: center;
      margin-left: 0;
    }
  }
}

.brand-timeline-left {

  .brand-timeline__circle-wrapper {
    text-align: right;

    @include bp-down(md) {
      text-align: center;
    }
  }
}

.brand-timeline-right {

  @include bp-down(md) {
    flex-direction: column-reverse;
  }

  .brand-timeline__circle-wrapper {
    text-align: left;

    @include bp-down(md) {
      text-align: center;
    }
  }

  .brand-timeline__text {
    text-align: right;

    @include bp-down(md) {
      text-align: center;
    }
  }

  .brand-timeline__circle {
    margin-left: 2rem;
  }
}
