.product-categories {
  display: flex;
  width: 100%;
  margin-top: rem(16px);

  &__item {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    padding: rem(14px);
    font-size: $font-size-sm;
    border: 1px solid #e5e5e5;
    font-weight: $font-weight-bold;
    max-width: rem(200px);

    > * {
      opacity: 0.8;
    }

    i {
      font-size: rem(32px);
      margin-right: rem(10px);
    }

    &:hover,
    &--active {
      border-color: color("primary");

      > * {
        opacity: 1;
      }
    }

    &:not(:last-child) {
      margin-right: rem(16px);
    }
  }
}
