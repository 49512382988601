.brand-image-slider {
  padding: 2rem 0 6rem 0;
  position: relative;
  overflow: hidden;

  @include bp-down(md) {
    padding: 2rem 2rem 4rem 2rem;
  }

  &__text {
    font-weight: 400;
    font-size: 19px;
    line-height: 135%;
    text-align: center;
    max-width: 50rem;
    margin: 0 auto;
    padding-bottom: 2rem;
  }

  &__header {
    font-weight: 700;
    font-size: 61px;
    line-height: 110%;
    text-align: center;
    text-transform: uppercase;
    color: #1F5681;
  }

  &__items {
    overflow: hidden;
  }

  &__holder {
    position: relative;
    width: 50%;
    margin: 0 auto;

    @include bp-down(md) {
      width: 100%;
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100% !important;
    z-index: 1;
    display: flex;
  }

  &__item {
    flex-shrink: 0;
    width: 12.5%;
    height: 100%;
    position: relative;
  }

  &__image {
    position: relative;

    &-tordenskjold {
      border: 12px solid #FFF;
    }

    img {
      position: relative;
      z-index: 10;
      display: block;
      margin: 0 auto;
    }
  }

  &__image-text {
    width: 810px;
    margin: 0 auto;
  }

  &__pagination {
    text-align: center;
    margin-top: 1rem;

    .swiper-pagination-bullet-active {
      background-color: #1F5681 !important;
    }

    &-tordenskjold {
      .swiper-pagination-bullet-active {
        background-color: #009EE1 !important;
      }
    }

    .swiper-pagination-bullet {
      width: 16px !important;
      height: 16px !important;
      background-color: #FFFFFF;
      opacity: 1;
    }

    .swiper-pagination-bullet:only-child {

      @include bp-down(md) {
        display: none;
      }
    }
  }

  &__next,
  &__prev {
    position: absolute;
    display: flex;
    align-items: center;
    outline: none;
    justify-content: center;
    top: 50%;
    width: rem(60px);
    height: rem(60px);
    font-size: rem(40px);
    color: $link-color;
    margin-top: -#{rem(30px)};
    z-index: 10;
    cursor: pointer;

    i {
      padding: rem(1px);
    }

    @include on-hover() {
      color: darken($link-color, 10%);
    }

    @include bp-down(md) {
      display: none;
    }
  }

  &__next {
    right: rem(-40px);

    @include bp-down(xs) {
      right: 0;
    }
  }

  &__prev {
    left: rem(-40px);

    @include bp-down(xs) {
      left: 0;
    }
  }

  &__disabled-nav {
    color: lighten(color("gray-meta"), 45%);

    @include on-hover() {
      cursor: default;
      color: lighten(color("gray-meta"), 45%) !important;
    }
  }

  .container & {
    padding-left: rem(60px);
    padding-right: rem(60px);

    .brand-image-slider__next {
      right: 0;
    }

    .brand-image-slider__prev {
      left: 0;
    }
  }
}
