.u-text-danger {
  color: color("danger") !important;
}

.u-text-success {
  color: color("success") !important;
}

.u-text-muted {
  color: $text-muted !important;
}

.u-text-uppercase {
  text-transform: uppercase !important;
}

.u-fw-light {
  font-weight: $font-weight-light !important;
}

.u-fw-normal {
  font-weight: $font-weight-normal !important;
}

.u-fw-medium {
  font-weight: $font-weight-medium !important;
}

.u-fw-bold {
  font-weight: $font-weight-bold !important;
}
